import PropTypes from "prop-types";
import {Theme, withStyles} from "@material-ui/core/styles";

const styles = (theme: Theme) => ({
  "@global": {
    body: {
      background: theme.palette.primary.dark + ' !important',
      color: theme.palette.primary.contrastText + ' !important',
      //overflowX: 'hidden'
    },
    h3: {
      color: theme.palette.primary.contrastText + ' !important',
    },
    h4: {
      color: theme.palette.primary.contrastText + ' !important',
    },
    h5: {
      color: theme.palette.primary.contrastText + ' !important',
    },
    h6: {
      color: theme.palette.primary.contrastText + ' !important',
    },
    // div: {
    //   background: theme.palette.primary.dark+' !important',
    // },
    p: {
      color: theme.palette.primary.contrastText + ' !important'
    },
    a : {
      color: '#0a16fc'
    },
    '.MuiAppBar-root': {
      [theme.breakpoints.down('xs')]: {
        width: 'unset'
      }
    },
    '.MuiDivider-root': {
      backgroundColor: 'rgb(255 250 250 / 12%)'
    },
    '.MuiDialog-paperScrollPaper': {
      borderRadius: '20px'
    },
    '.MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#3b44fc'
    },
    '.MuiButton-root': {
      borderRadius: '20px !important',
        [theme.breakpoints.down('xs')]: {
          fontSize: '0.775rem'
      }
    },
    '.MuiButton-contained.Mui-disabled':{
      color: 'white !important',
      background: 'gray !important',
      fontSize: '0.7rem',
      textTransform: 'capitalize',
      whiteSpace: 'break-spaces'
    },
    '.MuiButton-outlinedSecondary': {
      border: '1px solid white !important',
      color: 'white !important',
      '&:hover': {
        border: '1px solid #b9b9b9 !important',
        color: '#b9b9b9 !important',
      },
    },
    '.MuiContainer-root': {
      // paddingLeft: '0px',

      // margin: 0,
      // minWidth: '100% !important',
      // overflowX: 'hidden',
      // overflowY: 'hidden',
      // [theme.breakpoints.down('xs')]: {
      //   paddingLeft: '0',
      //   paddingRight: '0',
      // }
      // display: 'grid'
    },
    '.MuiPaper-elevation4': {
      [theme.breakpoints.down('xs')]: {
        boxShadow: 'unset'
      }
    },
    '.MuiCircularProgress-colorPrimary' : {
      color: 'white !important'
    },
    '.MuiFormLabel-root': {
      color: theme.palette.primary.contrastText + ' !important',
      opacity: 0.9,
      paddingBottom: '3px !important'
    },
    '.MuiFormLabel-root.Mui-error': {
      color: '#df4a5e !important',
    },
    '.MuiLink-underlineHover:hover': {
      cursor: 'pointer'
    },
    '.MuiFilledInput-root:hover': {
      backgroundColor: '#3e3e3e'
    },
    '.MuiFilledInput-root': {
      backgroundColor: '#3e3e3e !important'
    },
    '.MuiFilledInput-multiline': {
      padding:' 5px 10px 5px !important'
    },
    '.MuiInput-underline.Mui-disabled:before': {
      borderBottomStyle: 'none'
    },
    '.MuiTabs-root': {
      minHeight: 'initial'
    },
    '.MuiTab-root': {
      minHeight: 'initial'
    },
    '.MuiInputBase-root': {
      // borderBottom: '1px solid #d4d2d2',
      background: '#3e3e3e',
      borderRadius: '5px',
      // padding: '0 5px'
    },
    '.MuiInputBase-input': {
      // borderBottom: '1px solid #d4d2d2',
      padding: '6px 5px 7px !important',
      color: theme.palette.primary.contrastText + ' !important'
    },
    '.MuiInputBase-input.Mui-disabled': {
      opacity: 0.2
    },
    '.MuiInput-underline:after': {
      borderBottom: 'none'
    },
    '.MuiInput-underline:before': {
      borderBottom: 'none !important'
    },
    '.MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none'
    /* border-bottom: 2px solid rgba(0, 0, 0, 0.87); */
    },
    '.MuiIconButton-root': {
      color: theme.palette.primary.contrastText + ' !important'
    },
    '.MuiIconButton-root.Mui-disabled':{
      color: 'gray !important'
    },
    '.MuiTypography-colorPrimary': {
      color: theme.palette.primary.contrastText + ' !important'
    },
    '.MuiTypography-colorTextSecondary': {
      color: theme.palette.primary.light + ' !important'
    },
    '.MuiDialog-paperFullWidth': {
      [theme.breakpoints.down('xs')]: {
        width: '90%',
        margin: '0',
      }
    },
    '.MuiPaper-root': {
      background: theme.palette.primary.dark //+' !important',
      // borderRight: '2px solid white'
    },
    '.MuiMenu-paper': {
      background: '#6d6c6c !important',
      '&::-webkit-scrollbar': {
        width: '8px',
        backgroundColor: ' #f7f8fa'
      },
      '&::-webkit-scrollbar-thumb': {
        height: '20px',
        backgroundColor: '#3b44fc',
        opacity: '.5',
      },
    },
    '.MuiAccordionSummary-content.Mui-expanded': {
      margin:' 12px 0 0 0'
    },
    '.MuiListItem-root': {
      '&:hover': {
        background: 'black'
      }
    },
    '.MuiListItemIcon-root': {
      color: theme.palette.primary.light + ' !important'
    },
    '.MuiListItem-divider' :{
      borderBottom : '1px solid rgb(255 250 250 / 12%)'
    },
    '.MuiMenu-list': {
      backdropFilter: 'blur(10px)',
      backgroundColor: 'rgba(0, 0, 0, 0.75) !important',
      color: 'white'
    },
    '.MuiCard-root': {
      background: theme.palette.primary.dark
    },
    '.MuiAlert-standardError': {
      color: 'rgb(255 255 255) !important',
      backgroundColor: 'rgb(199 82 68) !important'
    },
    '.MuiAlert-standardSuccess': {
      color: 'rgb(30, 70, 32) !important',
      backgroundColor: '#3DFB9B!important'
    },
    '.MuiAlert-standardWarning': {
      color: 'rgb(102, 60, 0) !important',
      backgroundColor: 'rgb(245 148 16)!important',
    },
    // '.MuiSnackbarContent-root': {
    //   background: theme.palette.secondary.main + ' !important',
    // },
    '.MuiSvgIcon-root': {
      color: theme.palette.primary.contrastText
    },
    '.MuiTypography-body1': {
      color: theme.palette.primary.contrastText,
      overflow: 'hidden',
      // whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    '.MuiDivider-light': {
      background: 'gray !important'
    },
    '.MuiAvatar-colorDefault': {
      background: theme.palette.secondary.light + ' !important'
    },
    '.MuiDialog-paperFullScreen': {
      backgroundColor: theme.palette.primary.dark + ' !important'
    },
    '.MuiBadge-colorSecondary': {
      color: 'black',
      backgroundColor: 'rgb(18, 199, 126) !important'
    },
    '.MuiBadge-anchorOriginTopRightRectangle': {
      top: '5px',
      right: '6px',
      transform: 'scale(1) translate(50%, -50%)',
      transformOrigin: '100% 0%'
    },
    '.MuiRadio-colorSecondary': {
      color: '#6694fc !important'
    },
    '.MuiRadio-colorSecondary.Mui-checked': {
      color: '#134fd5 !important'
    },
    '.MuiChip-root': {
      color: 'white'
    },
    '.MuiChip-outlined': {
      border: '1px solid rgb(255 255 255 / 23%)',
      position: 'relative',
      '&:hover': {
        background: '#0a16fc',
        color: '#FFFFFF',
      }
    },
    '.MuiChip-deleteIcon': {
      color: 'rgb(255 255 255 / 26%)',
      position: 'absolute',
      right: 0,
      '&:hover': {
        color: '#FFFFFF !important',
      }
    },
    '.MuiChip-outlinedPrimary': {
      background: '#0a16fc',
      color: '#FFFFFF',
      '&:hover': {
        color: '#FFFFFF !important',
        backgroundColor: '#070fb0 !important'
      }
    }
  }
});

function MyCssBaseline() {
  return null;
}

MyCssBaseline.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MyCssBaseline);
