export const pt = {
  "profile_edit_title": "Conta",
  "profile_edit_first_name": "Nome",
  "profile_edit_last_name": "Sobrenome",
  "profile_edit_email": "Email",
  "profile_edit_membership_plan": "Plano de membros",
  "profile_settings_languages": "Idiomas",
  "profile_settings_sign_out": "Sair",
  "cmn_playlists": "Playlists",
  "profile_account_change_email": "Alterar Email",
  "profile_account_change_password": "Alterar senha",
  "profile_account_change_name": "Alterar nome de perfil & Bio",
  "cmn_anyplay_premium": "Anyplay plus",
  "profile_edit_save": "Salvar",
  "profile_password_current": "Senha atual",
  "profile_password_new": "Nova senha",
  "profile_password_confirmation": "Confirmar nova senha",
  "profile_email_new": "Novo endereço de email",
  "profile_password_send_link": "Enviar link",
  "cmn_cancel": "Cancelar",
  "profile_password_forgot": "Esqueci minha senha",
  "profile_subscription_management": "Gerenciar inscrições",
  "promo_sign_in": "Log in",
  "promo_title_2": "Escute o quanto você quiser",
  "auth_sign_up_for_books": "Cadastre-se para acesso ilimitado",
  "auth_or": "OU",
  "auth_login_to_your_account": "Acessar a sua conta",
  "auth_sign_up_password": "Escolha a sua senha",
  "auth_create_account": "Criar uma conta",
  "add_title_playlist_create": "Criar",
  "add_title_playlist_create_new": "Criar nova playlist",
  "add_title_playlist_enter_title": "Digite o título",
  "manage_playlist_remove": "Remover playlist",
  "manage_playlist_make_public": "Tornar a playlist pública",
  "subscribe_offer_description": "Acesso ilimitado a mais de 200.000 audiobooks com novos lançamentos todo dia.",
  "subscribe_try_it_free": "Teste de graça",
  "title_detail_narrator": "Narrador",
  "title_detail_language": "Idioma",
  "title_detail_playtime": "Duração",
  "title_detail_year": "Ano",
  "title_detail_publisher": "Editora",
  "title_detail_others_have_read": "Outros estão lendo",
  "player_jump_to_chapter": "Pular para o capítulo",
  "player_add_to_playlist": "Adicionar a playlist",
  "subscribe_go_premium": "Quer ouvir mais?",
  "subscribe_go_premium_message": "Desbloqueie todos os livros com Anyplay Plus.",
  "player_preview": "Pre-visualização",
  "browse_ongoing_tab": "Continue lendo",
  "download_audiobook_delete": "Excluir",
  "search_discover_fiction": "Ficção",
  "search_discover_non_fiction": "Não ficção",
  "search_authors": "Autores",
  "search_playlists": "Playlists",
  "search_title_or_author": "Buscar título ou autor",
  "feed_new_for_you": "Novo para você",
  "cmn_for_you": "Para você",
  "cmn_browse": "Navegar",
  "cmn_search": "Buscar",
  "audiobooks_status_finished": "Livros finalizados",
  "player_play": "Reproduzir",
  "subscribe_start": "Começar",
  "profile_add_language_title": "Adicionar um idioma",
  "subscribe_cancel_anytime": "Cancele a qualquer momento",
  "subscribe_v3_full_access": "Desbloqueie 200.000+ livros",
  "subscribe_v3_read_as_much": "Leia o quanto quiser",
  "subscribe_v3_stream_or_download": "Stream ou baixe offline",
  "subscribe_v3_new_books_weekly": "Novos livros adicionados toda semana",
  "subscribe_v3_low_fee": "Valor mensal baixo",
  "subscribe_v3_no_ads": "Sem propaganda",
  "subscribe_v3_trusted_by": "Aprovado por leitores de todo o mundo",
  "player_save_for_later": "Salve para mais tarde",
  "subscribe_v3_subscribe_now": "Inscreva-se agora",
  "search_no_results": "Sem resultados",
  "manage_playlist_make_private": "Tornar playlist privada",
  "cmn_anyplay_free": "Anyplay Free",
  "browse_audiobooks_tab": "Audiobooks",
  "title_detail_unavailable_territory": "Lamentamos, este livro foi recentemente retirado pela editora em seu território",
  "promo_continue_with_google": "Continue com Google",
  "promo_sign_up_with_email": "Cadastrar-se com email",
  "promo_continue_with_facebook": "Continue com Facebook",
  "auth_whats_your_email": "Qual é o seu email?",
  "auth_you_need_to_confirm_email": "Você precisará confirmar este email mais tarde",
  "auth_email_already_registered": "Este email já está conectado a uma conta",
  "auth_do_you_want_to_login_instead": "Você quer fazer login em vez disso?",
  "auth_whats_your_name": "Qual o seu nome?",
  "auth_name_appears_on_your_profile": "Isto aparecerá no seu perfil da Anyplay.",
  "auth_name_placeholder": "John Smith",
  "auth_password": "Senha",
  "auth_register": "Cadastrar",
  "subscribe_v4_books_all_genres": "200.000+ livros de todos os gêneros",
  "subscribe_v4_start_free_trial": "Comece o período de teste",
  "action_share_book": "Compartilhar livro",
  "subscribe_download_go_premium": "Desbloqueie este recurso",
  "auth_short_password_error_message": "Senha muito curta. O mínimo necessário é de 6 caracteres",
  "auth_signup": "Inscrever-se",
  "auth_email_fill_error_message": "Por favor, preencha com o email",
  "player_track": "Faixa",
  "search_more_audiobooks": "Todos os livros",
  "search_more_authors": "Todos os autores",
  "search_more_playlists": "Todas as playlists",
  "subscribe_save_discount_web": "ECONOMIZE",
  "promo_continue_with_apple": "Continue com Apple",
  "profile_password_reset_message_web": "Enviaremos um e-mail para {{email}} com um link para redefinir sua senha. Se você não conseguir acessar esta conta de e-mail, entre em contato com o Suporte ao Cliente."
}
