import React from 'react';
import HeadsetIcon from '@mui/icons-material/Headset';
import SearchIcon from '@mui/icons-material/Search';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PersonIcon from '@mui/icons-material/Person';
import {NavLink} from 'react-router-dom';
import {Box, Typography } from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(() => ({
  bottomNavbarContainer: {
    position: 'fixed',
    backgroundColor: '#1C1C1C',
    bottom: 0,
    zIndex: 500,
    display: 'flex',
    width: '100%',
    left: '3px',
  },
  bottomNavbarItem: {
    color: 'white',
    margin: '0 auto',
    fontSize: '0.8rem',
    textAlign: 'center',
    lineHeight: 0,
    padding: '5px'
  },
  bottomNavbarItemText: {
    fontSize: '0.8rem',
  },
  bottomNavbarItemIcon: {
    height: '0.95rem',
  }
}))
const NavbarBottom:React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={classes.bottomNavbarContainer}>
      <NavLink to={'/'} className={[classes.bottomNavbarItem, 'noTd'].join(' ')}>
        <HeadsetIcon className={classes.bottomNavbarItemIcon}/>
        <Typography className={classes.bottomNavbarItemText}>
          {t('cmn_browse')}
        </Typography>
      </NavLink>
      <NavLink to={'/search'} className={[classes.bottomNavbarItem, 'noTd'].join(' ')}>
        <SearchIcon className={classes.bottomNavbarItemIcon}/>
        <Typography className={classes.bottomNavbarItemText}>
          {t('cmn_search')}
        </Typography>
      </NavLink>
      <NavLink to={'/for_you'} className={[classes.bottomNavbarItem, 'noTd'].join(' ')}>
        <FavoriteIcon className={classes.bottomNavbarItemIcon}/>
        <Typography className={classes.bottomNavbarItemText}>
          {t('cmn_for_you')}
        </Typography>
      </NavLink>
      <NavLink to={'/account/overview'} className={[classes.bottomNavbarItem, 'noTd'].join(' ')}>
        <PersonIcon className={classes.bottomNavbarItemIcon}/>
        <Typography className={classes.bottomNavbarItemText}>
          {t('profile_edit_title')}
        </Typography>
      </NavLink>
    </Box>
  )
}

export default NavbarBottom
