import {anyplayPublicV1} from "../../plugins/axios";
import {ITerritory} from "../../models/territory";

class PublicTerritoryService {
  public static async getTerritory(data:{available: boolean}):Promise<ITerritory[]> {
    const {available} = data
    const myBooksData = await anyplayPublicV1
      .get('territories',
        {
          params: {
            available
          }
        }
      )
    return myBooksData.data.data
  }
  public static async getTerritoryCurrent():Promise<ITerritory> {
    const myBooksData = await anyplayPublicV1
      .get('territories/current_territory')
    // return   {  attributes: {code: 'ZA'}, //ZA MK SE DE US RU
    //   id: '129789',
    //   type: "territory"}
    return myBooksData.data.data
  }
}
export default PublicTerritoryService
