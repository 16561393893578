import {anyplayV3} from "../plugins/axios";
import {UserAudiobook} from "../models/audiobook";

class AudiobookLastPlayedService {
  public static async getLastPlayed(): Promise<UserAudiobook> {
    const resp = await anyplayV3.get(`profiles/last_played`)
    return resp.data.data
  }
}

export default AudiobookLastPlayedService
