import {useContext, useEffect, useState} from "react";
import {useIsLoading} from "../../../utils/hooks/useIsLoading";
import {ProfileContext} from "../../../context/profile/profileContext";
import {IStripeProductDetails, IStripeSubs} from "../../../models/stripe";
import StripeService from "../../../service/stripe";
import {useTranslation} from "react-i18next";
import {currencyFormatter} from "../../../utils/currencyFormatter";
import PublicPaymentsService from "../../../service/public/payments";
import {formatAsDateShort} from "../../../utils/luxonFormatter";
import {DateTime} from "luxon";

const usePaywallV3PremiumText = () => {
  const {loadingHandler} = useIsLoading()
  const { t } = useTranslation();
  const {rcData, isFreePlayback, premiumDetails, isCancelled, setPremiumDetails} = useContext(ProfileContext)

  const [subs, setSubs] = useState<IStripeSubs | null>(null)
  const [product, setProduct] = useState<IStripeProductDetails | null>(null)

  const getSubscription = async () => {
    try {
      loadingHandler(true)
      let data = await StripeService.getSubscription()
      setSubs(data)
    } catch (e) {
      console.log('getSubscription -> e', e)
    }
  }
  const getProduct = async () => {
    try {
      let data = await PublicPaymentsService.getProduct({id: subs?.plan.product as string})
      setProduct(data)
      loadingHandler(false)
    } catch (e) {
      loadingHandler(false)
    }
  }
  useEffect(()=> {
    if(rcData) {
      if("Subscription" in rcData.subscriber.entitlements) {
        if(rcData.subscriber.entitlements.Subscription.product_identifier.substr(0,3) === 'pro') {
          getSubscription()
        }
      }
    }
    // eslint-disable-next-line
  },[rcData])
  useEffect(()=> {
    if(subs) {
      getProduct()
    }
    // eslint-disable-next-line
  },[subs])
  useEffect(()=> {
    if(product && subs) {
      if (rcData) {
        if ("Subscription" in rcData.subscriber.entitlements) {
          let detectProduct = rcData.subscriber.entitlements.Subscription.product_identifier.substr(0, 3)
          let subsType: 'Apple' | 'Play Store' | 'Stripe' | undefined
          if (detectProduct === 'com' || detectProduct === 'AP_') {
            subsType = 'Apple'
          } else if (detectProduct !== 'pro' && detectProduct !== 'rc_') {
            subsType = 'Play Store'
          } else if (detectProduct === 'pro') {
            subsType = 'Stripe'
          }
          if (subsType === 'Stripe') {
            if(subs){
              let payPeriod = ''
              let nextPay = ''
              let subName = ''
              payPeriod = (subs.plan.interval_count !== 1 ? t('Billed each') : t('Billed once in')) + ' ' + subs.plan.interval_count + ' ' +
                (subs.plan.interval === 'month' ?
                  subs.plan.interval_count === 1 ? t('period_unit_month') : t('period_unit_months')
                  : t('period_unit_year'))

              if (subs.plan.interval === 'month') {
                if (subs.plan.interval_count === 1) {
                  subName = 'Anyplay ' + t('Plus') + ' - ' + t('Monthly')
                } else {
                  subName = 'Anyplay ' + t('Plus') + ' - ' + t('Quarterly')
                }
              } else {
                subName = 'Anyplay ' + t('Plus') + ' - ' + t('Annual')
              }

              nextPay = t('Next payment at') + ' ' +
                DateTime.fromSeconds(subs.current_period_end).toLocaleString(DateTime.DATE_SHORT)
              setPremiumDetails({
                ...premiumDetails,
                name: subName,
                subName: '',
                price: currencyFormatter({price: subs.plan.amount / 100, currency: subs.plan.currency}),
                payPeriod,
                nextPay,
                description: 'Stripe'
              })
            }
          }


          if (subsType === 'Apple' || subsType === 'Play Store') {
            setPremiumDetails({
              ...premiumDetails,
              name: 'Anyplay Plus',
              subsType,
              description: `You currently pay with ${subsType}'s in-app payment system, it's easy to switch to subscribe direct with us.`,
              nextPay: t('You have premium') + ' ' +
                ("Subscription" in rcData.subscriber.entitlements ?
                formatAsDateShort(rcData.subscriber.entitlements.Subscription.expires_date )
                : '')
            })
          }
          if (typeof subsType === 'undefined')
            setPremiumDetails({
              ...premiumDetails,
              name: 'Anyplay Plus',
              description: 'Free VIP Access'
            })


        }
        // }
      }
    }
    console.log(' -> isCancelled', isCancelled)
    if(isCancelled) {

      if(rcData) {
        setPremiumDetails({...premiumDetails,
          nextPay: t('You have premium till') +  ' ' +
            ("Subscription" in rcData.subscriber.entitlements ?
            formatAsDateShort(rcData.subscriber.entitlements.Subscription.expires_date )
            : '')
        })
      }

    }
    // eslint-disable-next-line
  },[isCancelled,product, subs])
  useEffect(() => {
    if(isFreePlayback) {
        setPremiumDetails({...premiumDetails,
          name: t('Free VIP Access'),
          description: t('You have free VIP access')})
    }
    // eslint-disable-next-line
  },[isFreePlayback])
  // useEffect(() => {
  //   console.log(' -> rcData', rcData)
  //   console.log(' -> subs', subs)
  //   if(rcData && !subs) {
  //     if(isCancelled) {
  //       setPremiumDetails({...premiumDetails,
  //         nextPay: t('You have premium till') +  ' ' +
  //           moment("Subscription" in rcData.subscriber.entitlements ?
  //             rcData.subscriber.entitlements.Subscription.expires_date : '').format('YYYY-MM-DD')
  //       })
  //     } else {
  //       if("Subscription" in rcData.subscriber.entitlements) {
  //        let detectProduct = rcData.subscriber.entitlements.Subscription.product_identifier.substr(0,3)
  //         let subsType: 'Apple' | 'Play Store' | undefined
  //         if(detectProduct === 'com' ||  detectProduct === 'AP_') {
  //           subsType = 'Apple'
  //         } else if(detectProduct !=='pro') {
  //           subsType = 'Play Store'
  //         }
  //
  //         setPremiumDetails({...premiumDetails,
  //           name: 'Anyplay Plus',
  //           subsType,
  //           description: `You currently pay with ${subsType}'s in-app payment system, it's easy to switch to subscribe direct with us.`,
  //           nextPay: t('You have premium') +  ' ' +
  //             moment("Subscription" in rcData.subscriber.entitlements ?
  //               rcData.subscriber.entitlements.Subscription.expires_date : '').format('YYYY-MM-DD')
  //         })
  //       }
  //       // setPremiumDetails({...premiumDetails,
  //       //   nextPay: t('You have premium') +  ' ' +
  //       //     moment("Subscription" in rcData.subscriber.entitlements ?
  //       //       rcData.subscriber.entitlements.Subscription.expires_date : '').format('YYYY-MM-DD')
  //       // })
  //     }
  //
  //
  //   } else if(rcData && !subs) {
  //     if (isCancelled) {
  //       setPremiumDetails({
  //         ...premiumDetails,
  //         nextPay: t('You have premium till') + ' ' +
  //           moment("Subscription" in rcData.subscriber.entitlements ?
  //             rcData.subscriber.entitlements.Subscription.expires_date : '').format('YYYY-MM-DD')
  //       })
  //     }
  //   }
  //   // eslint-disable-next-line
  // },[isCancelled, rcData, subs])


  return {
    premiumDetails
  }
}
export default usePaywallV3PremiumText
