export const de = {
  "profile_edit_title": "Konto",
  "profile_edit_first_name": "Vorname",
  "profile_edit_last_name": "Nachname",
  "profile_edit_email": "E-Mail-Adresse",
  "profile_edit_membership_plan": "Mitgliedschaftplan",
  "profile_settings_languages": "Sprachen",
  "profile_settings_sign_out": "Ausloggen",
  "cmn_playlists": "Playlists",
  "profile_account_change_email": "E-Mail Adresse ändern",
  "profile_account_change_password": "Passwort ändern",
  "profile_account_change_name": "Profilname & Bio ändern",
  "cmn_anyplay_premium": "Anyplay Plus",
  "profile_edit_save": "Speichern",
  "profile_password_current": "Jetziges Passwort",
  "profile_password_new": "Neues Passwort",
  "profile_password_confirmation": "Bestätige neues Passwort",
  "profile_email_new": "Neue E-Mail-Adresse",
  "profile_password_send_link": "Link senden",
  "cmn_cancel": "Stornieren",
  "profile_password_forgot": "Passwort vergessen?",
  "profile_subscription_management": "Abonnements verwalten",
  "promo_sign_in": "Anmelden",
  "auth_sign_up_for_books": "Anmelden fur unbegrenzter Hörebucher",
  "auth_login_to_your_account": "Anmelden",
  "auth_sign_up_password": "Wähle ein Passwort",
  "auth_create_account": "Konto erstellen",
  "add_title_playlist_create": "Erstellen",
  "add_title_playlist_create_new": "Playlist erstellen",
  "add_title_playlist_enter_title": "Gebe einen Titel ein",
  "manage_playlist_remove": "Wiedergabeliste entfernen",
  "manage_playlist_make_public": "Playlist öffentlich machen",
  "subscribe_try_it_free": "Kostenlos testen",
  "title_detail_narrator": "Vorleser",
  "title_detail_language": "Sprache",
  "title_detail_playtime": "Spielzeit",
  "title_detail_year": "Jahr",
  "title_detail_publisher": "Verlag",
  "title_detail_others_have_read": "Andere haben auch gelesen",
  "player_add_to_playlist": "Zu Playlist hinzufügen",
  "subscribe_go_premium": "Willst du mehr hören?",
  "subscribe_go_premium_message": "Alle Bucher mit Anyplay Plus freischalten",
  "browse_ongoing_tab": "Laufend",
  "download_audiobook_delete": "Löschen",
  "search_discover_fiction": "Fiction",
  "search_authors": "Autoren",
  "search_playlists": "Playlists",
  "search_title_or_author": "Titel oder Autor suchen",
  "feed_new_for_you": "Neu für dich",
  "cmn_for_you": "Dein Bibliothek",
  "cmn_search": "Suche",
  "audiobooks_status_finished": "Fertige Bücher",
  "player_play": "Hören",
  "subscribe_start": "Start",
  "profile_add_language_title": "Sprache hinzufügen",
  "subscribe_cancel_anytime": "Jederzeit kündigen",
  "subscribe_v3_full_access": "Schalte über 200.000+ Bücher frei",
  "subscribe_v3_stream_or_download": "Stream oder offline herunterladen",
  "subscribe_v3_trusted_by": "Von Bücherwürmern vertraut\nauf der ganzen Welt",
  "player_save_for_later": "Für später speichern",
  "subscribe_v3_subscribe_now": "Jetzt anmelden",
  "search_no_results": "Keine Ergebnisse",
  "manage_playlist_make_private": "Playlist privat machen",
  "cmn_anyplay_free": "Anyplay Free",
  "browse_audiobooks_tab": "Hörbücher",
  "title_detail_unavailable_territory": "Es tut uns leid, dieses Buch wurde kürzlich vom Verlag in deinem Gebiet zurückgezogen",
  "promo_continue_with_google": "Weither mit Google",
  "promo_sign_up_with_email": "Kostenfrei anmelden mit E-Mail",
  "promo_continue_with_facebook": "Weiter mit Facebook",
  "auth_whats_your_email": "Wie ist deine E-Mail-Adresse?",
  "auth_you_need_to_confirm_email": "Du muss diese E-Mail später bestätigen.",
  "auth_email_already_registered": "Diese E-Mail ist bereits mit einem Konto verbunden",
  "auth_do_you_want_to_login_instead": "Möchten Sie sich stattdessen anmelden?",
  "auth_whats_your_name": "Wie heißen Sie?",
  "auth_name_appears_on_your_profile": "Dies wird in Ihrem Anyplay-Profil angezeigt.",
  "auth_name_placeholder": "John Smith",
  "auth_password": "Kennwort",
  "auth_register": "Registrieren",
  "subscribe_v4_books_all_genres": "200.000+ Bucher in allen Genres",
  "subscribe_v4_start_free_trial": "Kostenlos testen",
  "action_share_book": "Buch teilen",
  "subscribe_download_go_premium": "Schalte diese Funktion",
  "auth_signup": "Anmelden",
  "auth_email_fill_error_message": "Bitte fülle die E-Mail aus",
  "player_track": "Track",
  "search_more_playlists": "Alle Playlists ansehen",
  "playlist_share": "Teile",
  "player_saved_for_later": "Fur später aufgehoben",
  "cmn_edit": "Redigieren",
  "playlist_edit_title": "Playlist redigieren",
  "title_download": "Download",
  "profile_settings_help_center": "Help Center",
  "profile_login_Information": "Login Information",
  "profile_account_change_avatar": "Profilfoto ändern",
  "profile_account_reset_password": "Passwort zurückzusetzen",
  "profile_account_reset_password_send": "Link zum Zurücksetzen des Passworts wurde geschickt an",
  "profile_account_passords_not_match_alert": "Das neue Passwort und das Bestätigungspasswort stimmen nicht überein",
  "profile_account_display_name": "Anzeigename",
  "profile_account_biography": "Biografie",
  "profile_account_display_name_description": "(wenn du ein Playlist teilet, dieser wird dein öffentlicher Name sein)",
  "profile_account_biography_description": "(Kurztext zur Beschreibung deines öffentlichen Benutzerprofils)",
  "profile_add_email_login": "E-Mail Login hinzufugen",
  "profile_add_email_login_to_unlink": "Um die Verknüpfung deines Kontos aufzuheben, füge bitte zuerst ein E-Mail-Login hinzu.\nDadurch wird sichergestellt, dass du deine Anyplay-Daten nicht verlierst.",
  "profile_signin_with_apple": "Sign in mit Apple",
  "profile_signin_with_facebook": "Sign in mit Facebook",
  "ongoing_placeholder_title": "Du hasst noch keine Bücher begonnen",
  "period_unit_year": "Jahr",
  "subscription_alert_button": "7 Tagen Frei",
  "profile_edit_interface_language": "Interfacesprache einstellen",
  "cmn_select": "Wählen",
  "promo_continue_with_apple": "Weiter mit Apple",
  "profile_password_reset_message_web": "Wir senden {{email}} eine E-Mail mit einem Link, mit dem du dein Passwort zurucksetzen kannst. Wenn du nicht auf dieses E-Mail-Konto zugreifen kannst, bitte wende dich an den Kundendienst.",
  "profile_signin_with_google": "Sign in mit Google",
  "auth_sign_in_remember_me": "Angemeldet bleiben"
}
