import {
  AuthData,
  AuthHeaders,
  AuthInfo,
  IAuthPasswordDrop,
  IAuthResetPassword,
  IAuthResetResponse,
  IGoogleAuth
} from "../models/auth";
import {authCheckService, authService} from "../plugins/axios";

class AuthService {
  public static async signUp(credentials: {email:string, password: string, name?: string}): Promise<AuthData> {
    const {email, password, name} = credentials
    const authData = await authService
      .post(`auth/email`, {
        auth: {
          "new_password": password,
          "last_name": "",
          "email": email,
          'origin_store': 'web',
          "provider": 'email',
          "first_name": name
        }
      })
    let headers = AuthService.getHeaders(authData.headers);
    let data: AuthInfo= authData.data.user.data;
    return {headers, data: {...data}};
  }
  public static async authViaFacebook(credentials: { token: string}):Promise<AuthData> {
    const {token} = credentials
    const authData = await authService
      .post(`auth/facebook`, {
        auth: {
          token,
          "provider": "facebook",
          'origin_store': 'web',
        }
      })
    let headers = AuthService.getHeaders(authData.headers);
    let data: AuthInfo = authData.data.user.data;
    return {headers, data};
  }
  public static async authViaGoogle(credentials: IGoogleAuth):Promise<AuthData>{
    const authData = await authService
      .post(`auth/google`, {
        auth: {
          ...credentials
        }
      })
    let headers = AuthService.getHeaders(authData.headers);
    let data: AuthInfo = authData.data.user.data;
    return {headers, data};
  }
  public static async authViaApple(code: string):Promise<AuthData> {
    const authData = await authService
      .post(`auth/apple_web`, {
        auth: {
          code
        }
      })
    let headers = AuthService.getHeaders(authData.headers);
    let data: AuthInfo = authData.data.user.data;
    return {headers, data};
  }

  public static async signIn(credentials: {email:string, password: string}): Promise<AuthData> {
    const {email, password} = credentials
    const authData = await authService
      .post(`auth/sign_in`, {
        user: {
          password,
          email,
        }
      })
    let headers = AuthService.getHeaders(authData.headers);
    let data: AuthInfo = authData.data.data;
    return {headers, data};
  }
  public static getHeaders(headers: any): AuthHeaders {
    return {
      'access-token': headers['access-token'],
      'token-type': headers['token-type'],
      client: headers.client,
      expiry: headers.expiry,
      uid: headers.uid
    };
  }
  public static async resetPassword (data: {email:string}):Promise<IAuthResetResponse> {
    const {email} = data
    const authData = await authService
      .post(`auth/password`, {
        user: {
          email
        }
      })
    return authData.data
  }

  public static async updatePassword (credentials:IAuthResetPassword ): Promise<AuthData> {
    const authData = await authService
      .patch(`auth/password`, {
        user: {
          ...credentials
        }
      })
    let headers = AuthService.getHeaders(authData.headers);
    let data: AuthInfo = authData.data.data;
    return {headers, data};
  }
  public static async checkAuth(email:string):Promise<{ user_exists:boolean }> {
    const checkData = await authCheckService.get(`auth/check_auth`, {
      params: {
        email
      }
    })
    return checkData.data
  }
  public static async dropPassword (email:string ): Promise<IAuthPasswordDrop> {
    const authData = await authService
      .post(`email_links`, {
        email
      })
    return authData.data
  }
}
export default AuthService
