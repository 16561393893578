import {anyplayPublicV1} from "../../plugins/axios";
import {
  IStripeCoupon,
  IStripePrice,
  IStripePriceDetails,
  IStripeProduct,
  IStripeProductDetails, IStripePromoCode, IStripePromoCodeGet
} from "../../models/stripe";
class PublicPaymentsService {
  public static async getProduct(data:{id:string}):Promise<IStripeProductDetails> {
    const product: {data: IStripeProductDetails } = await anyplayPublicV1
      .get(`payments/product_detail`, {
        params: {
          product_id: data.id
        }
      })
    return product.data
  }
  public static async getProducts():Promise<IStripeProductDetails[]> {
    const prices: {data: IStripeProduct } = await anyplayPublicV1
      .get(`payments/products_list`,{
        params: {
          limit: 90
        }
      })
    return prices.data.data
  }
  public static async getPrice(data:{currency:string}):Promise<IStripePriceDetails[]> {
    let {currency} = data
    const currency_f = currency === 'clf' ? 'clp' : currency === 'uyi' ? 'uyu' :currency === 'che' ? 'eur' : currency === 'bhd' ? 'usd' : currency
    const prices: {data: IStripePrice } = await anyplayPublicV1
      .get(`payments/prices`, {
        params: {
          currency: currency_f,
          limit: 90
        },

      })
    return prices.data.data
  }
  public static async getPriceDetails(data:{id:string}):Promise<IStripePriceDetails> {
    const product: {data: IStripePriceDetails } = await anyplayPublicV1
      .get(`payments/price`, {
        params: {
          price_id: data.id
        }
      })
    return product.data
  }
  public static async checkCoupon (id:string):Promise<IStripeCoupon> {
    const check = await anyplayPublicV1
      .get(`payments/coupon`, {
        params: {
          coupon_id:id
        }
      })
    return check.data
  }
  public static async checkPromoCode (code:string):Promise<IStripePromoCodeGet> {
    const check = await anyplayPublicV1
      .get(`payments/promotion_codes`, {
        params: {
          code
        }
      })
    return check.data
  }
  public static async getPromoCode (code:string):Promise<IStripePromoCode> {
    const check = await anyplayPublicV1
      .get(`payments/promotion_code`, {
        params: {
          code_id: code
        }
      })
    return check.data
  }
}
export default PublicPaymentsService
