import React, {useContext} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {PlayerContext} from "../../../context/player/playerContext";

const useStyles = makeStyles(() => ({
  customCurrentTrack: {
    textAlign: 'center',
    marginBottom: '10px'
  },
  headerText: {
    fontSize: '0.8rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: 'gray !important'
  },
}))

const CustomCurrentTrack:React.FC<{activeChapterIndex:number}>
  = ({activeChapterIndex}) => {
  const { content } = useContext(PlayerContext)

  const classes = useStyles();
  return (
    <div className={[classes.customCurrentTrack, classes.headerText].join(' ')}>
      { content.chapters[activeChapterIndex] ? content.chapters[activeChapterIndex].name : ''}
    </div>
  )
}
export default CustomCurrentTrack
