import gtag from "../gtags";
//import * as gtag from '../gtag'

export const useGTMWrapper = () => {
  const completeRegistration = (registrationMethod: string) => {

    gtag('event', 'sign_up', {
      'method': registrationMethod
    })
    console.log('TagManager -> completeRegistration', registrationMethod)
    // TagManager.dataLayer({
    //   dataLayer: {
    //     registrationMethod: registrationMethod,
    //     status: 'unknown', registration_date: new Date()
    //   },
    //   dataLayerName:'CompleteRegistration'
    // })
  }
  const login = (provider:string) => {
    console.log('TagManager -> login', provider)
    gtag('event', 'login', {
      'method': provider
    })
    // ReactGA.event({
    //   category: 'Login',
    //   action: 'Sign in Component',
    //   label: 'Click'
    // });
    // gtag.event({
    //   value: provider,
    //   action: 'click',
    //   category: 'link button',
    //   label: 'login',
    // });
    // console.log('TagManager -> login', provider)
    //TagManager.gtm({events: {'Login':'Login'}})
    // TagManager.dataLayer({
    //   dataLayer:{
    //     provider,
    //     date: new Date()
    //   },
    //   dataLayerName:'Login'
    // })


    // trackEvent({
    //   event: "Login",
    //   data: {
    //     // name: "CTA - To External",
    //     // category: "CTA",
    //     // action: "To: https://phantom.land/",
    //     // label: "Click",
    //     provider,
    //     date: new Date()
    //   },
    // });
    // sendDataToGTM({event: 'Login', value: {provider, date: new Date()}})
  }
  const beginCheckout = () => {
    console.log('TagManager -> begin_checkout')
    gtag('event', 'begin_checkout')
    // @ts-ignore
    //TagManager.dataLayer({dataLayerName:'begin_checkout'})
    //TagManager.gtm({events: {'begin_checkout':'begin_checkout'}})
  }
  const purchase = () => {
    console.log('TagManager -> purchase')
    gtag('event', 'purchase')
    // @ts-ignore
   // TagManager.dataLayer({dataLayerName:'purchase'})
  }
  return {
    completeRegistration,
    login,
    beginCheckout,
    purchase
  }
}
