import React from "react"
import {Dialog, useMediaQuery, useTheme, DialogContent, Grid} from "@material-ui/core";
import AnyplayPlayerAudioInfoRow from "./AnyplayPlayerAudioInfoRow";
import DialogTitle from "./DialogTitle";

const AnyplayPlayerAudioInfo:React.FC<{isOpenPlayerAudioInfo: boolean, streamInfo:any[],onClosePlayerAudioInfo(): void}>
  = ({isOpenPlayerAudioInfo, streamInfo, onClosePlayerAudioInfo}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog
      maxWidth={'sm'}
      fullScreen={matches}
      open={isOpenPlayerAudioInfo}>
      <DialogTitle
        id="about-stream-title"
        onClose={onClosePlayerAudioInfo}>
        {'About this stream'}
      </DialogTitle>
      <DialogContent>
        <Grid container>
          {
            streamInfo.map((item, index)=>(
              <AnyplayPlayerAudioInfoRow text={item.text} header={item.header} key={index}/>
            ))
          }
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
export default AnyplayPlayerAudioInfo
