import {AuthHeaders} from "../models/auth";

const getAuthData = (): AuthHeaders | null => {
  let authHeader: string | undefined;
  authHeader = getCookieValue('authAnyplayProfile');

  if (authHeader) {
    if (authHeader !== '') {
      return JSON.parse(authHeader) as AuthHeaders;
    }
    return null;
  }
  return null;
};
// const getAuthPreview = (): AuthHeaders | null => {
//   let authHeader: string | undefined;
//   authHeader = getCookieValue('authAnyplayPreview');
//
//   if (authHeader) {
//     if (authHeader !== '') {
//       return JSON.parse(authHeader) as AuthHeaders;
//     }
//     return null;
//   }
//   return null;
// }
function getCookieValue(a:string) {
  const b = document.cookie.match('(^|[^;]+)\\s*' + a + '\\s*=\\s*([^;]+)');
  return b ? b.pop() : '';
}
const expiryCalc = (expiry:string):string => {
  const nowSec = Math.floor(Date.now() / 1000)
  return (parseInt(expiry) - nowSec).toString()
}

const setAuthData = (authData: AuthHeaders, type?:'session'): void => {
  let maxAge = expiryCalc(authData.expiry)
  if(typeof type !== 'undefined'){
    maxAge = ''
  }
  document.cookie = 'authAnyplayProfile='+JSON.stringify(authData)+`;max-age=${maxAge};path=/`;
};
// const setAuthPreview = (authData: AuthHeaders): void => {
//   const maxAge = expiryCalc(authData.expiry)
//   document.cookie = 'authAnyplayPreview='+JSON.stringify(authData)+`;max-age=${maxAge};path=/`;
// };
const removeAuthData = (): void => {
  document.cookie = 'authAnyplayProfile=;Max-Age=0;path=/';
};
// const removeAuthPreview = (): void => {
//   document.cookie = 'authAnyplayPreview=;Max-Age=0;path=/';
// };
export { getAuthData, setAuthData, removeAuthData};
