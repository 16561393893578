import React, {useState} from "react"
import {
  Button,
  Container,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Typography, useMediaQuery,
  useTheme
} from "@material-ui/core";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {makeStyles} from "@material-ui/core/styles";
import LimitNavbar from "../utils/LimitNavbar";

const useStyles = makeStyles(() => ({
  errorMsg: {
    fontSize: '0.7rem',
    color: '#df4a5e !important'
  },
  methodContainer: {
    padding: 0,
    position: 'absolute',
    zIndex: 1101,
    top: 0,
    background: 'black',
  }
}))
const SignUpMethodsPassword:React.FC<{password: string, onSetPassword(data: {password:string}):void,onRollbackPassword():void, onSubmit():void}>
  = ({password,onSetPassword, onRollbackPassword,onSubmit}) => {
  const { t } = useTranslation();
  const classes = useStyles()
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const { register, errors, handleSubmit } = useForm({mode:'onBlur'})

  const [showPassword, setShowPassword] = useState<boolean>(false)

  return (
    <Container
      maxWidth={false}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      className={matches ? classes.methodContainer : ''}
      style={{padding: 0}}>
      <LimitNavbar onRollbackCall={onRollbackPassword}>
      <Grid item xs={12} style={{paddingBottom:'0'}}>
        <InputLabel
          shrink
          id="password"
          error={!!errors.password}>
          {t( 'auth_sign_up_password')}
        </InputLabel>
        <Input
          inputRef={register({ required: true, minLength: 6 })}
          error={!!errors?.password}
          type={showPassword ? 'text' : 'password'}
          id="password"
          name="password"
          value={password}
          onChange={(event)=>onSetPassword({password: event.target.value})}
          fullWidth
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={()=>setShowPassword(!showPassword)}
                onMouseDown={(event)=>event.preventDefault()}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        {errors.password &&
        <Typography
          variant={'subtitle2'}
          className={classes.errorMsg}>
          {t('The password must be more than 6 characters long')}
        </Typography>
        }
      </Grid>
        <Grid item xs={12}>
          <Button
            variant={'contained'}
            color={'secondary'}
            type={'submit'}
            style={{marginTop: '40px'}}
            fullWidth>
            {t('auth_next')}
          </Button>
        </Grid>
      </LimitNavbar>
    </Container>
  )
}
export default SignUpMethodsPassword
