import axios from "axios";
import {AuthData, AuthInfo} from "../models/auth";
import AuthService from "./auth";
import {IPasswordChange} from "../models/user";
import {anyplayV2} from "../plugins/axios";
import {IUserLanguageISO} from "../models/language";

class UserService {
  public static async updateUserInfo(user: {first_name: string, last_name: string}): Promise<AuthInfo> {
    const userData = await axios
      .patch(`profiles/me`, {
        "users": {...user}
      })
    return userData.data.data;
  }
  public static async updateUserEmail(user: {email: string, password: string}): Promise<AuthData> {
    const userData = await axios
      .patch(`profiles/change_email`, {
        "user": {...user}
      })
    let headers = AuthService.getHeaders(userData.headers);
    let data: AuthInfo = userData.data.data;
    return {headers, data};
  }
  public static async updateUserPassword(passwordData: IPasswordChange): Promise<AuthData> {
    const userData = await axios
      .patch(`profiles/change_password`, {
        "user": {...passwordData}
      })
    let headers = AuthService.getHeaders(userData.headers);
    let data: AuthInfo = userData.data.data;
    return {headers, data};
  }
  public static async updateUserAvatar(avatarData: { profileAvatar: FormData }):Promise<any> {
    const userData = await axios
      .patch(`profiles/me`, avatarData.profileAvatar,
        {
          headers: {
            'Content-Type': 'multipart/form-data;charset=UTF-8'
          }
        }
      )
    return userData.data.data
  }
  public static async getUserLanguages():Promise<IUserLanguageISO[]> {
    const userData = await anyplayV2
      .get(`profiles/language_codes`)
    return userData.data.data
  }
  public static async postUserLanguage(data:{iso_code_id:string}):Promise<IUserLanguageISO[]> {
    const {iso_code_id} = data
    const userData = await anyplayV2
      .post(`profiles/add_language_codes`,{iso_code_id})
    return userData.data.data
  }
  public static async patchUserLanguage(data:{iso_code_id:string, position: number}):Promise<IUserLanguageISO[]> {
    const {iso_code_id, position} = data
    const userData = await anyplayV2
      .patch(`profiles/change_language_priority`,{},{params:{iso_code_id,position}})
    return userData.data.data
  }
  public static async deleteUserLanguage(data:{iso_code_id:string}):Promise<IUserLanguageISO[]> {
    const {iso_code_id} = data
    const userData = await anyplayV2
      .delete(`profiles/remove_language_code`,{params: {iso_code_id}})
    return userData.data.data
  }
}
export default UserService
