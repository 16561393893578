import {anyplayV2} from "../plugins/axios";
import {IEpisodeAnyplay} from "../models/podcasts";

class ServicePodcastsEpisode {
  public static async getEpisodeInfo (data: {listen_id: string}):Promise<IEpisodeAnyplay> {
    const { listen_id } = data
    const playlist = await anyplayV2
      .get('podcast_episodes/listen_id',{
        params: {
          listen_id
        }
      })
    return playlist.data.data
  }
  // MEMO enum state: {inbox: 1, queue: 2, finished: 3}
  public static async getEpisodes (data: {page: number, state: number,}):Promise<IEpisodeAnyplay[]> {
    const { page, state } = data
    const playlist = await anyplayV2
      .get('profiles/my_podcast_episodes',{
        params: {
          page,
          "per_page": "30",
          state,
          "order": "position",
          "direction": "asc"
        }
      })
    return playlist.data.data
  }
  public static async getEpisodeBookmark (data: {episode_id: string}):Promise<any[]> {
    const { episode_id } = data
    const playlist = await anyplayV2
      .get(`profiles/podcast_episodes/${episode_id}/podcast_episodes_bookmarks`)
    return playlist.data.data
  }

  public static async postEpisode (data: {episode_id: string}):Promise<"success"> {
    const { episode_id } = data
    const playlist = await anyplayV2
      .post('profiles/add_episode',{
        "podcast_episode": {
          episode_id
        }
      })
    return playlist.data
  }

  public static async postEpisodeWithoutFollowing (data: {
    pub_date_ms: number, image_url: string, title: string,
    audio_length_sec:number, listen_id: string, description: string, audio: string
  }):Promise<"success"> {
    const { pub_date_ms, image_url, title, audio_length_sec, listen_id, description, audio } = data
    const playlist = await anyplayV2
      .post('podcast_episodes',{
        "podcast_episode": {
          pub_date_ms,
          image_url,
          title,
          audio_length_sec,
          listen_id,
          description,
          audio
        }
      })
    return playlist.data
  }
  public static async postEpisodeBookmark (data: {episode_id: string, timestamp: number}):Promise<any> {
    const { episode_id, timestamp } = data
    const playlist = await anyplayV2
      .post(`profiles/podcast_episodes/${episode_id}/podcast_episodes_bookmarks`,{
        "podcast_episode_bookmarks": {
          "last_bookmark": true,
          timestamp
        }
      })
    return playlist.data
  }
  public static async updateEpisodeState (data: {podcast_episode_id: number, state: number,position?: number}):Promise<"success"> {
    const { podcast_episode_id, state, position } = data
    const playlist = await anyplayV2
      .patch('profiles/update_episode_state',{}, {
        params: {
          podcast_episode_id,
          state,
          position
        }
      })
    return playlist.data
  }
  public static async deleteEpisode (podcast_episode_id: number):Promise<""> {

    const playlist = await anyplayV2
      .delete('profiles/remove_podcast_episode', {
        params: {
          podcast_episode_id,
        }
      })
    return playlist.data
  }
}
export default ServicePodcastsEpisode
