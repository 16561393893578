import React, {useReducer} from "react";
import {scrollReducer} from "./scrollReducer";
import {initState, ScrollContext} from "./scrollContext";
import {SET_SCROLL} from "../types";

export const ScrollState:React.FC = ({children}) => {
  const [state,dispatch]= useReducer(scrollReducer, initState);

  const setScroll = (payload:number) => {
    dispatch({type:SET_SCROLL, payload})
  }
  return (
    <ScrollContext.Provider value={{
      scroll: state.scroll,
      setScroll,
    }}>
      {children}
    </ScrollContext.Provider>
  )
}
