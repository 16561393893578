import React from "react"
import {Typography, Grid, Box, Link} from "@material-ui/core"
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {ReactComponent as AppleBadge} from "../../assets/appstore-badge.svg";
import {isAndroid, isDesktop, isIOS} from "react-device-detect";
import ShowConditionally from "../utils/ShowConditionally";

const useStyles = makeStyles((theme)=>({

  drawerHeaderLocal: {
    display: 'flex',
    alignItems: 'center',
    padding: '20px',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    // height: '100vh',
    backgroundColor: '#232323',
    [theme.breakpoints.down('xs')]: {
      height: '100vh',
    },
  },

  cardImg: {
    // height: 300,
    width: 250,
  },
  modalContent: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  badge: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: '10px'
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))
const DownloadApp:React.FC<{}> = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
      <div className={classes.drawerHeaderLocal} >
        <Grid container >
          <Grid item xs={12} sm={5} className={'flexCenter'}>
            <Box display={{ xs: 'none', sm: 'block'}}>
              {/*<CardMedia*/}
              {/*  className={classes.cardImg}*/}
              {/*  image={require(`../../assets/GenreScreenCircle-web.png`)}*/}
              {/*  title="Anyplay category"*/}
              {/*/>*/}
              <img className={classes.cardImg} src={require("../../assets/GenreScreenCircle-web.png").default} alt="Anyplay category"/>
            </Box>
          </Grid>
          <Grid item sm={7} className={classes.modalContent}>
            <Typography variant="h4">{t('Anytime')} {t('Anywhere')}</Typography>
            <Typography variant="h4">{t('For Anyone')}</Typography>
            <Typography >{t('We bring the best audio storytelling to every corner of the world')}</Typography>
            <br/>
            <ShowConditionally isShow={isIOS || isDesktop}>
              <Link className={classes.badge}
                    underline={"none"}
                    target="_blank"
                    href='https://apps.apple.com/al/app/anyplay-audiobooks-podcasts/id1445440724'>
                <AppleBadge/>
              </Link>
            </ShowConditionally>
            <ShowConditionally isShow={isAndroid || isDesktop}>
              <Link underline={"none"}
                    target="_blank"
                    href='https://play.google.com/store/apps/details?id=audio.anyplay.next'>
                <img style={{width:' 200px'}} src={require("../../assets/google-play-badge.png").default} alt="googlePlay"/>
              </Link>
            </ShowConditionally>
          </Grid>
        </Grid>
      </div>
  )
}
export default DownloadApp
