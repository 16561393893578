import React from "react";
import {Badge} from "@material-ui/core";

const AnyplayBadge:React.FC<{isShow:boolean,badgeContent?:string}>
  = ({isShow,badgeContent,children}) => {
  return (
    <Badge
      overlap="circular"
      color="error"
      variant={badgeContent ? undefined : 'dot'}
      badgeContent={badgeContent ? badgeContent : ''}
      invisible={!isShow}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}>
      {children}
    </Badge>
  )
}
export default AnyplayBadge
