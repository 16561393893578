import {getLocalStorageData, removeLocalStorageData, setLocalStorageData} from "./_root";
import {IPlaybackContent} from "../../context/player/IPlayerState";

const getPlayerContent = (): IPlaybackContent | null => {
  return getLocalStorageData('anyplayPlayerContent')
};

const setPlayerContent = (data:IPlaybackContent): void => {
  setLocalStorageData('anyplayPlayerContent', data)
};

const removePlayerContent = (): void => {
  removeLocalStorageData('anyplayPlayerContent')
};


export { getPlayerContent, setPlayerContent, removePlayerContent};
