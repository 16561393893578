import {Grid, IconButton} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {NavLink} from "react-router-dom";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  backBtn: {
    padding: 0,
    zIndex:100
  },
  logoWrapper: {
    position: 'absolute',
    marginTop: '10px',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    top:'-5px',
    textAlign: 'center',
    zIndex: 0
  },
  logo: {
    height: 30,
    marginBottom: '5px'
  },
}))

const SignUpMethodsHeader:React.FC<{onRollback?():void}>
  = ({onRollback}) => {
  const classes = useStyles()
  return (
    <Grid item xs={12} className={'flexCenter'} >
      {
        typeof onRollback !== 'undefined' ?
          <IconButton onClick={onRollback} className={classes.backBtn}>
            <ArrowBackIcon/>
          </IconButton>
          : null
      }
      <NavLink to={'/'} className={classes.logoWrapper}>
        <img
          className={classes.logo}
          src={require(`../../assets/WhiteFaceTagline.webp`).default}
          alt="icon"
          data-src={require('../../assets/WhiteFaceTagline.webp').default}
          loading={'lazy'}
        />
      </NavLink>
    </Grid>
  )
}
export default SignUpMethodsHeader
