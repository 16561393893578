import React, {useContext, useEffect, useState} from 'react';
import './App.css';
import {
  CssBaseline,
  makeStyles,
  Dialog,
  AppBar, useTheme, useMediaQuery, Toolbar, IconButton, Typography
} from "@material-ui/core";
import {Route, withRouter, RouteComponentProps} from 'react-router-dom';
import MyCssBaseline from './components/utils/MyCssBaseline';
import routes from './routes';
import {useTranslation} from "react-i18next";
import {ProfileContext} from "./context/profile/profileContext";
import {getAuthData, removeAuthData} from "./utils/auth";
import {
  getProfileData, getProfileRC,
  getProfileStripe,
  removeProfileData, setProfileIsCancelled,
  setProfileStripe
} from "./utils/localstorage/profile";
import {AuthHeaders} from "./models/auth";
import setup from "./plugins/axios";
import PublicTerritoryService from "./service/public/territory";
import NotAvailable from "./components/fullScreen/NotAvailable";
import {ITerritory} from "./models/territory";
import {useIsLoading} from "./utils/hooks/useIsLoading";
import {setTerritoryData} from "./utils/localstorage/territory";
// import {useMixPanel} from 'react-mixpanel-provider-component';
import StagingPassword from "./components/fullScreen/StagingPassword";
import {getIsPassStg, setIsPassStg} from "./utils/localstorage/passStg";
import ReactPixel from "./plugins/some";
import cable from "./plugins/actionCable";
import {IStripeSocket} from "./models/stripe";
import RCService from "./service/revenuecat";
import NavbarV2 from "./components/navbar/NavbarV2";
import AnyplayApp from './components/test/AnyplayApp';
import OpenPurchase from "./components/utils/OpenPurchase";
import PriceCatcher from "./components/utils/PriceCatcher";
import CloseIcon from "@material-ui/icons/Close";
import DownloadApp from "./components/fullScreen/DownloadApp";
import usePaywallV3PremiumText from "./components/fullScreen/paywallv3/PaywallV3PremiumText";
import {AlertSnack} from "./components/AlertSnack";
import useUserDevice from "./utils/hooks/useUserDevice";
import {DateTime} from "luxon";
import {PlayerContext} from "./context/player/playerContext";
import AudiobookLastPlayedService from './service/audiobookLastPlayed';
import {IPlaybackContent} from "./context/player/IPlayerState";
import {ReactJkMusicPlayerAudioListProps} from "react-jinke-music-player";
import webChapter from "./utils/webChapter";
import {IAudiobook} from "./models/audiobook";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}))

const App:React.FC<any &RouteComponentProps> = ({history}) => {
  const {userTerritory, profile, rcData, setRC, setIsBillingIssue, setIsBillingIssueOutGrace,
    setStripe, setProfile, setUserTerritory, setIsCancelled, setFreePlayBack} = useContext(ProfileContext)
  const {setContent, setIsPaused} = useContext(PlayerContext)
  // const { mixpanel } = useMixPanel()
  const {postUserDevice} = useUserDevice()
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const {isLoading, loadingHandler} = useIsLoading()
  const { premiumDetails } = usePaywallV3PremiumText()

  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [isAvailable, setIsAvailable] = useState<boolean>(true)
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(true)
  const [isFullHeight, setIsFullHeight] = useState<boolean>(false)
  const [territory, setTerritory] = useState<ITerritory[]>([])
  const classes = useStyles();

  useEffect(() => {
    beforeEach(history.location.pathname)
    // eslint-disable-next-line
  },[history.location.pathname])
  // MEMO: interceptors setup here
  setup(history)
  const beforeEach = (pathname: string) => {
    ReactPixel.default.pageView()
    let checkData:AuthHeaders | null = getAuthData()
    //let checkPreview:AuthHeaders | null = getAuthPreview()
    let profileData = getProfileData()
    if(!checkData || !profileData) {
      removeAuthData()
      removeProfileData()
      profileData = null
      checkData = null
    }
    // let previewProfile = getProfilePreview()
    if(profileData && profile === null) {
      setProfile(profileData)
    }
    let route = routes.find(route => route.path.replace(':id', pathname.split('/')[2]) === pathname)
    if(typeof route === 'undefined'){
      history.push('/not_found')
      document.title = t('Not found')
    } else {
      document.title = t(route.title)
      if(pathname !== '/sign_up' && pathname !== '/sign_in' ) {
        if(!profileData && route.isLoginRequire) {
          history.push('/sign_in')
        }
      }
      else {
        if(checkData) {
          history.push('/')
        }
      }
    }
  }
  // const getToken = async () => {
  //   const respData: AuthData = await AuthService.signUp(previewData)
  //
  //   if (respData.headers && respData.data) {
  //     if(respData.data.attributes.free_playback) {
  //       setAuthPreview(respData.headers);
  //       setProfilePreview(respData.data);
  //       setPreview(respData.data);
  //     } else {
  //       console.log('Access not allowed')
  //       // alert.show('Access not allowed', 'danger')
  //     }
  //   }
  // }
  const getIsAvailable = async () => {
    try {
      loadingHandler(true)
      let data = await PublicTerritoryService.getTerritory({available: false})
      setTerritory(data)
      loadingHandler(false)
    } catch (e) {
      loadingHandler(false)
      console.log('getIsAvailable -> e', e)
    }
  }
  const getUserTerritory = async () => {
    try {
      let data = await PublicTerritoryService.getTerritoryCurrent()
      setUserTerritory(data.attributes.code)
      setTerritoryData({code:data.attributes.code} )

    } catch (e) {
      console.log('Request failed: ->  e',  e)
    }
  }
  const checkIsAvailable = () => {
    let checkTerritory = territory.filter(ter =>ter.attributes.code === userTerritory)

    if(checkTerritory.length !== 0) {
      setIsAvailable(false)
    }
  }

  const handlePrevPlayed = async () =>{
    // const contentData = getPlayerContent()
    // if(contentData) {
    //   if(typeof contentData.contentId !== 'undefined') {
    //     try {
    //       const data = await AudiobookBookmarkService.getBookmarkLast({audiobookId: contentData.contentId})
    //       if (data === null || typeof data === 'undefined') {
    //         contentData.bookmarks = null
    //       } else {
    //         contentData.bookmarks = data
    //       }
    //     } catch (e) {
    //       console.log('handlePrevPlayed -> e', e)
    //     }
    //     setContent(contentData)
    //     setIsPaused(true)
    //   }
    // }
    const resp = await AudiobookLastPlayedService.getLastPlayed()
    if(resp) {
      let contentData:IPlaybackContent = {contentId: '', contentType: 'audiobook', bookmarks: null, localBookmark: null, chapters: []}
      contentData.bookmarks = resp.attributes.last_bookmark.data
      contentData.contentId = resp.id

      let audioChapters:ReactJkMusicPlayerAudioListProps[] = []
      const getDurationsArr = (arr: number[]) => arr.map((sum => (value:number) => sum += value)(0));
      let durationsArr: number[] = resp.attributes.edition.data.attributes.chapters.data.map(elem =>elem.attributes.duration === null ? 0 : elem.attributes.duration);
      durationsArr = getDurationsArr(durationsArr)
      resp.attributes.edition.data.attributes.chapters.data.map((audio,index) => {
        let audioChapter = webChapter({
          index,
          audiobook: resp as unknown as IAudiobook,
          musicSrc:resp.attributes.cache_status === 'cached' || audio.attributes.play_source === 'preview'  ? audio.attributes.url_data?.url as string  : '',
          chapter_number: audio.attributes.chapter_number,
          part_number: audio.attributes.chapter_number,
          durationsArr,
          play_source: audio.attributes.play_source,
          fallback: audio.attributes.fallback
        })
        audioChapters.push(audioChapter)
        return audioChapters
      })
      contentData.chapters = audioChapters
      setContent(contentData)
      setIsPaused(true)
    }
  }

  useEffect(() => {
    if(territory.length === 0 && !isLoading) {
      getIsAvailable()
    }
    // eslint-disable-next-line
  },[history.location.pathname, territory])

  useEffect(() => {
    if(territory.length !== 0 && userTerritory !== '') {
      checkIsAvailable()
    }
    // eslint-disable-next-line
  },[territory,userTerritory])

  useEffect(() => {
    getUserTerritory()
    // eslint-disable-next-line
  },[])
  useEffect(()=>{
    if(profile !== null) {
      postUserDevice()
      cable.subscriptions.create({
          channel: 'UserSubscriptionsChannel'
        },
        {
          connected() {
            // @ts-ignore
            this.perform('follow_user_subscriptions', {id: profile.id})
          },
          async received(resp: string) {
            console.log('received follow_user_subscriptions -> resp', resp)
            const data: IStripeSocket = JSON.parse(resp)

            let tmp = getProfileStripe()
            if (tmp) {
              if (data.object.canceled_at !== null) {
                setProfileIsCancelled(true)
                tmp.attributes.subscription_id = null
                setStripe(tmp)
                const _tRCData = getProfileRC()
                if (_tRCData) {
                  let tmpRc = {..._tRCData}
                  if ("Subscription" in tmpRc.subscriber.entitlements) {
                    tmpRc.subscriber.entitlements.Subscription.expires_date = new Date(data.object.cancel_at as unknown as number * 1000).toDateString()
                    // tmpRc.subscriber.subscriptions.subscriptions.unsubscribe_detected_at = new Date(data.object.canceled_at * 1000).toDateString()
                  }
                  setRC(tmpRc)
                }
                setIsCancelled(true)
              } else {
                tmp.attributes.subscription_id = data.object.id
                try {
                  let data = await RCService.getSubscription()
                  setRC(data)
                } catch (e) {
                  console.log('getRC -> e', e)
                }
                setIsCancelled(false)
              }
              setProfileStripe(tmp)
            }
            // setClientId(resp.client)
          }
        })
      handlePrevPlayed()
    }
    // eslint-disable-next-line
  },[profile])
  useEffect(() => {
    const _tRCData = getProfileRC()
    const _tSData = getProfileStripe()
    if(_tRCData) {
      if ("Subscription" in _tRCData.subscriber.entitlements) {
        let id = _tRCData.subscriber.entitlements.Subscription.product_identifier
        // @ts-ignore
        setIsCancelled(_tRCData.subscriber.subscriptions[id].unsubscribe_detected_at !== null)
        // @ts-ignore
        setIsBillingIssue(_tRCData.subscriber.subscriptions[id].billing_issues_detected_at !== null)
        // @ts-ignore
        if(_tRCData.subscriber.subscriptions[id].billing_issues_detected_at !== null) {
          setIsBillingIssueOutGrace(
            // @ts-ignore
            DateTime.fromISO(_tRCData.subscriber.subscriptions[id].billing_issues_detected_at).diffNow(['days']).toObject().days >7)
        }
      }
      if(_tSData) {
        if ("Subscription" in _tRCData.subscriber.entitlements) {
          let id = _tRCData.subscriber.entitlements.Subscription.product_identifier
          if(id.substr(0,4) === 'prod'){
            if(_tSData.attributes.subscription_id === null) {
              setIsCancelled(true)
            }
          }
        }
      }
    }
    // eslint-disable-next-line
  },[])
  useEffect(() => {
    if(rcData) {
      if(rcData.subscriber.entitlements.hasOwnProperty('Subscription')) {
        // @ts-ignore
        if(rcData.subscriber.entitlements.Subscription.product_identifier.substr(0,8) === 'rc_promo') {
          setFreePlayBack()
        }
      }
    }
    // eslint-disable-next-line
  },[rcData])
  // useEffect(() => {
  //   console.log(' -> profile', profile)
  //   console.log(' -> isUserHasPremium', isUserHasPremium)
  //   console.log(' -> isLoadingPremiumDone', isLoadingPremiumDone)
  //   if(profile) {
  //     if (!isUserHasPremium && isLoadingPremiumDone) {
  //       history.push('/payment')
  //     }
  //   }
  //   // eslint-disable-next-line
  // },[profile,rcData])
  useEffect(() => {
     if(process.env.REACT_APP_API_V1 === 'https://anyplay-stg.se/api/v1/') {
        let isPass = getIsPassStg()
         if(!isPass) {
           setIsPasswordValid(false)
         }
     } else {
       setIsPassStg(true)
       setIsPasswordValid(true)
     }
  },[])
  // useEffect(() => {
  //   let profile = getProfileData();
  //   if(profile) {
  //       mixpanel.init(`${process.env.REACT_APP_MIXPANEL}`, {
  //         loaded: function(mixpanel: any) {
  //           mixpanel.identify(profile?.id as string)
  //         }
  //       });
  //   }
  //   // eslint-disable-next-line
  // },[profile])

  useEffect(() => {
    window.scrollTo(0, 0)
    if(history.location.pathname === '/sign_in'
      || history.location.pathname === '/sign_up'
      || history.location.pathname === '/reset_password'
      || history.location.pathname === '/'
      || history.location.pathname === '/payment/success'
      || history.location.pathname === '/download'
      || history.location.pathname.substring(0,8) === '/account'
      || history.location.pathname.substring(0,6) === '/offer'
      || history.location.pathname.substring(0,8) === '/winback'
    ) {
      setIsFullHeight(true)
    } else {
      if(isFullHeight) {
        setIsFullHeight(false)
      }
    }
    // eslint-disable-next-line
  },[history.location.pathname])


  return (
    // <UseGTMHookProvider>
      <>
        <CssBaseline />
        <MyCssBaseline />
          {
             history.location.pathname !== '/sign_up'
            && history.location.pathname !== '/sign_in'
             && history.location.pathname !== '/payment'
             && history.location.pathname !== '/payment/success'
             && history.location.pathname.substring(0,15) !== '/reset_password'
             && history.location.pathname !== '/drop_password'
             && history.location.pathname.substring(0,6) !== '/offer'
             && history.location.pathname.substring(0,8) !== '/winback' ?
               <NavbarV2/>
               : null
          }
        <AlertSnack/>
        { matches ? null : <Toolbar /> }
        <AnyplayApp history={history}>
          <React.Suspense fallback={<></>}>
            {
              routes.map((route, index) => (
                <Route
                  key={index}
                  exact={route.mode === 'exact'}
                  strict={route.mode === 'strict'}
                  sensitive={route.mode === 'sensitive'}
                  path={route.path}
                  render={routeProps => <route.component {...routeProps} title={route.title} />}
                />
              ))
            }
          </React.Suspense>
        </AnyplayApp>
        <OpenPurchase/>

        <PriceCatcher/>
        <Dialog
          fullScreen={matches}
          open={isOpen} onClose={()=>setIsOpen(false)} maxWidth={'lg'}>
          <AppBar className={'relative'} >
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={()=>setIsOpen(false)}
                aria-label="close"
                className={classes.closeButton}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6">
                {t('Join Anyplay')}
              </Typography>
            </Toolbar>
          </AppBar>
          <DownloadApp/>
        </Dialog>

        <Dialog fullScreen open={!isPasswordValid} >
          <AppBar className={'relative'} />
          <StagingPassword onValidPassword={()=>setIsPasswordValid(true)}/>
        </Dialog>
        <Dialog fullScreen open={!isAvailable} >
          <AppBar className={'relative'} />
          <NotAvailable/>
        </Dialog>
      </>
    // </UseGTMHookProvider>
  )
}

export default withRouter(App);
