import React from "react";
import {Divider, Grid, List, ListItem, Typography} from "@material-ui/core";
import {NavLink} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(()=>({
  customButton:{
    '&:hover': {
      cursor: 'pointer'
    }
  },
}))

const PreviewEndWithLoginActionRows:React.FC<{textBtnPay:string,textBtnClose: string, onCloseModal(): void}>
  = ({textBtnPay,textBtnClose, onCloseModal}) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} >
      <List disablePadding>
        <Divider/>
        <ListItem divider>
          <NavLink
            to={'/payment'}
            className={['noTd', 'w100'].join(' ')}
            onClick={()=>onCloseModal()}>
            <Typography align={'center'}>
              {textBtnPay}
            </Typography>
          </NavLink>
        </ListItem>
        <ListItem>
          <Typography
            align={'center'}
            className={['w100', classes.customButton].join(' ')}
            onClick={()=>onCloseModal()}>
            {textBtnClose}
          </Typography>
        </ListItem>
      </List>
    </Grid>
  )
}

export default PreviewEndWithLoginActionRows
