import React, {useContext, useEffect} from "react"
import SignUpMethods from "../components/signUp/SignUpMethods";
import {ProfileContext} from "../context/profile/profileContext";
import {RouteComponentProps, withRouter} from "react-router-dom";
import LimitNavbar from "../components/utils/LimitNavbar";

const SignUpV2View:React.FC<any&RouteComponentProps> = ({history}) => {
  const {profile, isUserHasPremium, isLoadingPremiumDone} = useContext(ProfileContext)

  useEffect(() => {
    if(profile) {
      if(isLoadingPremiumDone) {
        if(!isUserHasPremium ){
          history.push('/payment')
        } else {
          history.push('/')
        }
      }
    }
    // eslint-disable-next-line
  },[profile, isUserHasPremium, isLoadingPremiumDone])

  return (
    <LimitNavbar>
      <SignUpMethods />
    </LimitNavbar>
  )
}
export default withRouter(SignUpV2View)
