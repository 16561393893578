import {getTerritoryData} from "./localstorage/territory";
import {IStripePriceDetails} from "../models/stripe";
import {countries, Country} from "countries-list";
import {getProfileStripe} from "./localstorage/profile";

const orderingPrice = (data:IStripePriceDetails[]):IStripePriceDetails[] => {
  // sort by price - lower should be first
  data.sort((a, b) => a.unit_amount - b.unit_amount);
  const curr = getLocalCurrency()
  let tmp = data.find(price => price.currency === curr)
  if(typeof tmp === 'undefined') {
    data = data.filter(price => price.currency === 'usd')
  } else {
    data = data.filter(price => price.currency === curr)
  }
  return data
}
 const getLocalCurrency = ():any => {
  const stripeProfile = getProfileStripe()
   if(stripeProfile) {
     if(stripeProfile.attributes.currency) {
       return stripeProfile.attributes.currency
     } else {
       return getLocalCurrencyByTerritory()
     }
   } else {
     return getLocalCurrencyByTerritory()
   }
}
const getLocalCurrencyByTerritory = () => {
  const ter = getTerritoryData()

  let countryName = ''
  if(ter) {
    countryName = ter.code
    // @ts-ignore
    const currency:Country = countries[countryName]
    if(currency) {
      // MEMO: some currency return multiple values separated by ,
      return currency.currency.toLowerCase().split(',')[0]
    }
  } return 'usd'
}
const getBasePrice = (data:IStripePriceDetails[]): number => {
  let stripePrice = data.reduce(function(res, obj) {
    return (obj.unit_amount < res.unit_amount) ? obj : res;
  })
  return stripePrice.unit_amount/100
}
export {orderingPrice, getBasePrice, getLocalCurrency}
