import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Grid, Typography} from "@material-ui/core";

const useStyles = makeStyles((theme)=>({

  drawerHeaderLocal: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    height: '100vh',
    backgroundColor: 'black'
  },

  cardImg: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%'
  },
  customHeader: {
    padding: '2rem',
    fontWeight: 600,
    color: 'white',
    position: 'absolute',
    zIndex: 100,
    top: '2rem',
    [theme.breakpoints.down('xs')]: {
      top: 0,
      fontSize: '2.4rem',
    },
    [theme.breakpoints.down('sm')]: {
     width: '80%'
    },
    [theme.breakpoints.down('lg')]: {
      width: '60%'
    },
    [theme.breakpoints.down('xl')]: {
      width: '70%'
    },
  }
}))
const NotAvailable = () => {
  const classes = useStyles();
  return (
    <div className={classes.drawerHeaderLocal}>
      <Grid container>
        <Grid item xs={12} sm={7} md={7}>
          <Typography
            className={classes.customHeader}
            variant={'h3'}>We're sorry, {<br/>}Anyplay is not yet available in your region.</Typography>
          <Box display={{ xs: 'none', sm: 'block'}}>
            <img
              className={classes.cardImg}
              src={require("../../assets/not_available_bg.png").default}
              alt="Anyplay not available"/>
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

export default NotAvailable
