import React, {useContext} from "react";
import {Box, Button} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import MaximisedAudiobookDetails from "./MaximisedAudiobookDetails";
import {makeStyles} from "@material-ui/core/styles";
import IconCDN from "./IconCDN";
import {ProfileContext} from "../../../context/profile/profileContext";
import ShowConditionally from "../ShowConditionally";

const useStyles = makeStyles(()=>({
  maximisedContainer: {
    width: '100%',
    position: 'fixed',
    top: '30px',
    zIndex: 1300,
  },
  minimiseBtn: {
    color: 'white',
    position: 'absolute',
    padding: '0',
    top: 0,
  },
}))
const Maximised:React.FC<{onSetIsMaximised(isMax: boolean):void, onSetIsOpenPlayerAudioInfo(isOpenAudioInfo: boolean):void}>
  = ({onSetIsMaximised, onSetIsOpenPlayerAudioInfo}) => {
  const {profile} = useContext(ProfileContext)
  const classes = useStyles();
  return (
    <Box className={classes.maximisedContainer}>
      <Button
        className={classes.minimiseBtn}
        onClick={()=>onSetIsMaximised(false)}>
        <KeyboardArrowDownIcon/>
      </Button>

      <MaximisedAudiobookDetails/>
      <ShowConditionally isShow={profile !== null && typeof profile.attributes.admin !== 'undefined' }>
        <IconCDN onSetIsOpenPlayerAudioInfo={onSetIsOpenPlayerAudioInfo}/>
      </ShowConditionally>
    </Box>
  )
}
export default Maximised
