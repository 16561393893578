import {getLocalStorageData, removeLocalStorageData, setLocalStorageData} from "./_root";
import {ReactJkMusicPlayerAudioListProps} from "react-jinke-music-player";

const getPlayerChapters = (): ReactJkMusicPlayerAudioListProps[] | null => {
  return getLocalStorageData('anyplayPlayerChapters')
};


const setPlayerChapters= (data:ReactJkMusicPlayerAudioListProps[]): void => {
  setLocalStorageData('anyplayPlayerChapters', data)
};

const removePlayerChapters = (): void => {
  removeLocalStorageData('anyplayPlayerChapters')
};


export { getPlayerChapters, setPlayerChapters, removePlayerChapters};
