import {ILocalStorage} from "../../models/localstorage";
import {getProfileData} from "./profile";
type IStorage = 'localStorage' | 'sessionStorage'
let storageType: IStorage = 'localStorage'

const getCurrentId = ():string => {
  let profile = getProfileData()
  if(profile) {
    return profile.id
  }
 return ''
}

const keyItem = (key: ILocalStorage):string => {
 if(key === 'anyplayProfile' || key === 'anyplayIsPassStg'
   || key === 'anyplayTerritory'  || key ===  'anyplayFeedV3Public' || key.includes(":", 0)) {
   return key
 }
  const id = getCurrentId()
  return id+':'+key
}
const getLocalStorageData = (key: ILocalStorage): any | null => {
  let localStorageData: any
  let sessionStorageData: any
  const keyUpdated = keyItem(key)
  localStorageData = window.localStorage.getItem(keyUpdated)
  sessionStorageData = window.sessionStorage.getItem(keyUpdated)
  if (localStorageData || sessionStorageData) {
    if (localStorageData !== '' || sessionStorageData !== '') {
      return JSON.parse(localStorageData || sessionStorageData);
    }
    return null;
  }
  return null;
};

const setLocalStorageData = (key: ILocalStorage, value: any, type?:'sessionStorage'): void => {
  const keyUpdated = keyItem(key)
  if(typeof type !== 'undefined'){
    storageType = type
  }
  window[storageType].setItem(keyUpdated,JSON.stringify(value))
};

const removeLocalStorageData = (key:ILocalStorage): void => {
  const keyUpdated = keyItem(key)
  window.localStorage.removeItem(keyUpdated);
};


export { getLocalStorageData, setLocalStorageData, removeLocalStorageData, getCurrentId, keyItem};
