import React from "react"
import {
  Button,
  Container,
  Grid,
  InputLabel,
  TextField,
  Typography, useMediaQuery,
  useTheme
} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {makeStyles} from "@material-ui/core/styles";
import LimitNavbar from "../utils/LimitNavbar";
const useStyles = makeStyles(()=>({
  errorMsg: {
    fontSize: '0.7rem',
    color: '#df4a5e !important'
  },
  customText: {
    fontSize: '0.7rem',
    color:' gray !important',
    padding: '5px 0 0 0',
  },
  methodContainer: {
    // marginTop: '10px',
    padding: 0,
    position: 'absolute',
    zIndex: 1101,
    top: 0,
    background: 'black',
  }
}))
const SignUpMethodsEmail:React.FC<{email:string, isDefault?:boolean
  onSetEmail(data: {email:string}):void, onRollbackEmail():void, onSubmit():void}>
  = ({email,isDefault=true, onSetEmail, onRollbackEmail, onSubmit}) => {
  const { t } = useTranslation();
  const classes = useStyles()
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const { register, errors, handleSubmit  } = useForm({mode:'onBlur'})
  return (
      <Container
        maxWidth={false}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        className={matches ? classes.methodContainer : ''}>
        <LimitNavbar onRollbackCall={onRollbackEmail}>
          <Grid item xs={12} style={{paddingBottom:'0'}}>
            <InputLabel
              shrink
              id="email"
              error={!!errors.email}>
              {t(  'auth_whats_your_email')}
            </InputLabel>
            <TextField
              inputRef={register({
                required: true,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
              })}
              error={!!errors?.email}
              id="email"
              name="email"
              onChange={(event)=>onSetEmail({email: event.target.value.trim()})}
              value={email}
              fullWidth
            />
            {!errors.email &&
              <Typography
                className={classes.customText}
                variant={'subtitle2'}>
                {
                  isDefault ?
                  t( 'auth_you_need_to_confirm_email')
                    : t('We ll send you an email with a link that will log you in')
                }
              </Typography>
            }
            {errors.email &&
              <Typography
                variant={'subtitle2'}
                className={classes.errorMsg}>
                {t('The email must be valid')}
              </Typography>
            }
          </Grid>
          <Grid item xs={12}>
            <Button
              variant={'contained'}
              color={'secondary'}
              type={'submit'}
              style={{marginTop: '40px'}}
              fullWidth>
              {
                isDefault ?
                t('auth_next') :
                  t('Get link')
              }
            </Button>
          </Grid>
        </LimitNavbar>
      </Container>
  )
}
export default SignUpMethodsEmail
