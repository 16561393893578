// import ReactPixel from 'react-facebook-pixel';
// const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: true, // enable logs
};


//
const ReactPixel =  require('react-facebook-pixel');
ReactPixel.default.init('224961965880836', {}, options);
// const ReactPixelModule = ReactPixel.init('224961965880836', advancedMatching, options);
export default ReactPixel
