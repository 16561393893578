import axios from "axios";
import {
  IAudiobook, IAudiobookCache, IAudiobookConsumption, IAudiobookLicense, IAudiobookPostConsumption,
  IAudiobookSearch,
} from "../models/audiobook";
import {anyplayV2, anyplayV3, anyplayV4} from "../plugins/axios";
import {DateTime} from "luxon";

class AudiobookService {
  public static async getAudiobook(audiobookId: string): Promise<IAudiobook> {
    const audiobookData = await anyplayV2
      .get(`audiobooks/${audiobookId}`)
    return audiobookData.data.data
  }

  public static async getRelated(audiobookId: string): Promise<IAudiobookSearch[]> {
    const audiobookData = await axios
      .get(`audiobooks/${audiobookId}/related_titles`, {
        params: {
          page: 1
        }
      })
    return audiobookData.data.data
  }
  // TODO: deprecated?
  public static async getCached(audiobookId: string): Promise<IAudiobookCache> {
    const audiobookData = await anyplayV2
      .get(`audiobooks/${audiobookId}/editions/current_edition`,{
        params: {
          page: 1,
          per_page: 10
        }
      })
    return audiobookData.data.data
  }
  public static async getPlaybackContent(data:{audiobookId: string}): Promise<IAudiobookCache> {
    const {audiobookId} = data
    const audiobookData = await anyplayV3.post(`audiobooks/${audiobookId}/playback`)
    return audiobookData.data.data
  }

  public static async getCurrentConsumption(audiobookId: string): Promise<IAudiobookConsumption> {
    const audiobookData = await anyplayV3
      .get(`audiobooks/${audiobookId}/license_consumptions/current_consumption`,)
    return audiobookData.data.data
  }

  public static async postLicenses(data:{findawayId: string, preview:boolean}): Promise<IAudiobookLicense> {
    const {findawayId,preview} = data
    const audiobookData = await anyplayV3.post(`licenses`, {
      "licenses": {
        "book_id": findawayId,
        preview
      }
    })
    return audiobookData.data.data
  }

  public static async patchLicenses(licensesId: string): Promise<IAudiobookLicense> {
    const licensesData = await anyplayV2.patch(`licenses/${licensesId}/update_license`, {})
    return licensesData.data.data
  }
   public static async updatePlaybackLicenses(data:{audiobookId: string, binary_array: IAudiobookPostConsumption[]}): Promise<IAudiobookConsumption> {
    const { audiobookId, binary_array } = data
    const licensesData = await anyplayV4.patch(`audiobooks/${audiobookId}/license_consumptions/current_consumption`, {
      license_consumptions: {
        binary_array
      },
      date: DateTime.now().toISODate()
    })
    return licensesData.data.data
  }

}
export default AudiobookService
