import React from "react"
import {Grid, Typography} from "@material-ui/core"
import { NavLink } from "react-router-dom"
import {makeStyles} from "@material-ui/core/styles";
const useStyles = makeStyles(()=>({
  customButton:{
    padding: '12px 0px',
    borderTop: '1px solid rgb(255 250 250 / 12%)',
    '&:hover': {
      backgroundColor: 'black',
      cursor: 'pointer'
    }
  },
  customButtonLeft:{
    borderRight: '0.5px solid rgb(255 250 250 / 12%)'
  },
  customButtonRight:{
    borderRight: '0.5px solid rgb(255 250 250 / 12%)'
  },
}))
const PreviewEndWithLoginActionCols:React.FC<{textBtnPay:string,textBtnClose: string, onCloseModal(): void}>
  = ({textBtnPay,textBtnClose, onCloseModal}) => {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={6} className={[classes.customButton, classes.customButtonLeft].join(' ')}>
        <NavLink to={'/payment'} className={'noTd'} onClick={()=>onCloseModal()}>
          <Typography align={'center'}>
            {textBtnPay}
          </Typography>
        </NavLink>
      </Grid>
      <Grid item xs={6} className={[classes.customButton, classes.customButtonRight].join(' ')}>
        <Typography align={'center'} className={'w100'} onClick={()=>onCloseModal()}>
          {textBtnClose}
        </Typography>
      </Grid>
    </>
  )
}
export default PreviewEndWithLoginActionCols
