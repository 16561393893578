import React from "react";
import Replay10Icon from '@mui/icons-material/Replay10';
import Forward10Icon from '@mui/icons-material/Forward10';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

export const Replay10IconNode = (
  <Replay10Icon/>
)
export const Forward10IconNode = (
  <Forward10Icon/>
)
export const PlayArrowIconNode = (
  <PlayArrowIcon style={{width: '100%', height: '100%'}}/>
)
