import {anyplayV2} from "../plugins/axios";

class SubscribeService {
  public static async getSubscribe(): Promise<{subscribed:boolean}> {
    const userData = await anyplayV2
      .get(`profiles/newsletters/subscribed`)
    return userData.data;
  }
  public static async subscribe(): Promise<"success"> {
    const userData = await anyplayV2
      .post(`profiles/newsletters/subscribe`)
    return userData.data;
  }
  public static async unsubscribe(): Promise<any> {
    const userData = await anyplayV2
      .delete(`profiles/newsletters/unsubscribe`)
    return userData.data.data;
  }

}
export default SubscribeService
