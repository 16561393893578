const URLtoFromData = async (url:string, formDataName: string) => {

  var bytes = url.split(',')[0].indexOf('base64') >= 0 ?
    atob(url.split(',')[1]) :
    (window).unescape(url.split(',')[1]);
  var mime = url.split(',')[0].split(':')[1].split(';')[0];
  var max = bytes.length;
  var ia = new Uint8Array(max);
  for (var i = 0; i < max; i++) {
    ia[i] = bytes.charCodeAt(i);
  }
  const avatar: FormData = new FormData();
  //let tmpUrl = URL.createObjectURL(croppedImage);
  // @ts-ignore
  const fileFromBlob = new File([ia], "cropped.jpg", {type: mime, lastModified: new Date()})
  avatar.append(formDataName, fileFromBlob);

  // MEMO: @tmpUrl avoid the loading img from backend in success case
  const tmpUrl = URL.createObjectURL(fileFromBlob);
  return {avatar, tmpUrl};

}

export default URLtoFromData
