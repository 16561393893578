import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import {en} from "../translations/en";
import {ru} from "../translations/ru";
import {sv} from "../translations/sv";
import {de} from "../translations/de";
import {es} from "../translations/es";
import {fr} from "../translations/fr";
import {pt} from "../translations/pt";

const DETECTION_OPTIONS = {
  order: ['localStorage','navigator'],
  caches: ['localStorage']
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    i18nFormat: {
      localeData: 'en'// window.localStorage.i18nextLng // || 'en'

    },
    // we init with resources
    resources: {
      de: {
        translations: de
      },
      en: {
        translations: en
      },
      es: {
        translations: es
      },
      fr: {
        translations: fr
      },
      pt: {
        translations: pt
      },
      ru: {
        translations: ru
      },
      sv: {
        translations: sv
      }
    },
    detection: DETECTION_OPTIONS,
    // detection: detectionOptions,
    // lng: "ru",
    fallbackLng: "en",
    debug: false,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",
    react: {
      transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i',], // don't convert to <1></1> if simple react elements
    },
    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
