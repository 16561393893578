import {IAudiobook, IPlaybackSource} from "../models/audiobook";
import getDurationString from "./getDurationString";
import {ReactJkMusicPlayerAudioListProps} from "react-jinke-music-player";
import audiobookTitleFixer from "./audiobookTitleFixer";
import i18n from "i18next";

const webChapter = (data:{
  index:number, musicSrc:string,
  chapter_number:number | null, part_number:number | null,
  audiobook:IAudiobook, durationsArr: number[], fallback: IPlaybackSource
  play_source: IPlaybackSource}):ReactJkMusicPlayerAudioListProps =>{
  const {index,musicSrc,chapter_number,part_number,audiobook,durationsArr,fallback,play_source} = data
  let durationStr = getDurationString({duration: typeof durationsArr[index-1] !== 'undefined' ? durationsArr[index-1] : '0', isMinutesOnly:false})
  return {
    name: play_source === 'preview' ? i18n.t('player_preview') : `${i18n.t('player_track')} ${index+1}`,
    musicSrc,
    cover: audiobook.attributes.cover_image_url ? audiobook.attributes.cover_image_url : '',
    singer: durationStr,
    timestamp: index === 0 ? 0 : typeof durationsArr[index-1] === 'undefined' ? 0 : durationsArr[index-1],
    chapter_number: chapter_number ? chapter_number : 0,
    part_number: part_number ? part_number : 0,
    title: audiobookTitleFixer(audiobook.attributes.title),
    fallback,
    play_source,
    author: audiobook.attributes.audiobook_authors.data.map(audiobook_author=>audiobook_author.attributes.title).join(' '),
  }
}
export default webChapter
