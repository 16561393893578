import React, {useReducer} from "react";
import {gradientReducer} from "./gradientReducer";
import {GradientContext, initState} from "./gradientContext";
import {SET_GRADIENT} from "../types";

export const GradientState:React.FC = ({children}) => {
  const [state,dispatch] = useReducer(gradientReducer, initState);

  const setGradientTo = (payload: string) => {
    dispatch({type:SET_GRADIENT, payload})
  }

  return (
    <GradientContext.Provider value={
      {
         gradientTo: state.gradientTo,
        setGradientTo
      }
    }>
      {children}
  </GradientContext.Provider>
  )
}
