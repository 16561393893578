import {anyplayV3} from "../plugins/axios";
import {IUserDevice, IUserDevicePost} from "../models/user";

class UserDevicesService {
  public static async postDevice(data:IUserDevicePost):Promise<IUserDevice[]> {
    const { name, os, description } = data
    const device: { data: { data: IUserDevice[] } } = await anyplayV3
      .post(`profiles/user_devices`, {
        user_device: {
          name,
          os,
          description
        }
      })
    return device.data.data
  }
}
export default UserDevicesService
