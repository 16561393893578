import React from "react";
import {
  Button, Checkbox,
  Container, FormControlLabel,
  Grid,
  InputLabel, Link,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import LimitNavbar from "../utils/LimitNavbar";
import {useTranslation, Trans} from "react-i18next";
import {useForm} from "react-hook-form";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(()=>({
  link: {
    color: "white !important",
    textDecoration: 'underline'
  },
  errorMsg: {
    fontSize: '0.7rem',
    color: '#df4a5e !important'
  },
  customText: {
    fontSize: '0.7rem',
    color:' gray !important',
    padding: '5px 0 0 0',
  },
  methodContainer: {
    // marginTop: '10px',
    padding: 0,
    position: 'absolute',
    zIndex: 1101,
    top: 0,
    background: 'black',
  }
}))
const SignUpMethodsName:React.FC<{name:string, isSub: boolean,
  onSetName(data: {name:string}):void, onRollbackName():void, onSubmit():void, onSubs(event: React.ChangeEvent<HTMLInputElement>):void}>
  = ({name,isSub, onSetName, onRollbackName, onSubmit, onSubs}) => {
  const { t } = useTranslation();
  const classes = useStyles()
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const { register, errors, handleSubmit  } = useForm({mode:'onBlur'})

  return (
    <Container
      maxWidth={false}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      className={matches ? classes.methodContainer : ''}>
      <LimitNavbar onRollbackCall={onRollbackName}>
        <Grid item xs={12} style={{paddingBottom:'0'}}>
          <InputLabel
            shrink
            id="name"
            error={!!errors.name}>
            {t('auth_whats_your_name')}
          </InputLabel>
          <TextField
            inputRef={register({
              required: true,
            })}
            error={!!errors?.name}
            id="name"
            name="name"
            placeholder={t('auth_name_placeholder')}
            onChange={(event)=>onSetName({name: event.target.value.trim()})}
            value={name}
            fullWidth
          />
          {!errors.name &&
          <Typography
            className={classes.customText}
            variant={'subtitle2'}>
            {t('auth_name_appears_on_your_profile')}
          </Typography>
          }
          {errors.name &&
          <Typography
            variant={'subtitle2'}
            className={classes.errorMsg}>
            {t('The name must not be empty')}
          </Typography>
          }
        </Grid>
        <Grid item xs={12} style={{padding:'0 12px'}}>
          <FormControlLabel
            control={<Checkbox
              style={{
                transform: "scale(0.7)",
              }}
              onChange={onSubs}
              checked={isSub}
              color="secondary"
              name="subscribe"
            />}
            label={
              <Typography style={{ color: 'gray', fontSize: '0.7rem'}}>
                Let me get newsletters from Anyplay
              </Typography>
            }

          />

        </Grid>
        <Grid item xs={12}>
          <Button
            variant={'contained'}
            color={'secondary'}
            type={'submit'}
            style={{marginTop: '40px'}}
            fullWidth>
            { t('auth_create_account') }
          </Button>
        </Grid>
        <Grid item xs={12} className={'textCenter'}>
          <Trans i18nKey="auth_terms_web" >
            By creating an account, you agree to Anyplay’s <Link href={'https://www.anyplay.fm/end-user-agreement'} target={'_blank'} className={classes.link}>Terms and Conditions of Use</Link>. To learn how Anyplay collects, uses and protects your personal data, read Anyplay’s <Link href={'https://www.anyplay.fm/privacy'} target={'_blank'} className={classes.link}>Privacy Policy</Link>.
          </Trans>
        </Grid>
      </LimitNavbar>
    </Container>
  )
}
export default SignUpMethodsName
