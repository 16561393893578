export const SET_PROFILE = 'SET_PROFILE'
export const SET_TERRITORY = 'SET_TERRITORY'
export const SET_STRIPE = 'SET_STRIPE'
export const SET_RC = 'SET_RC'
export const SET_PREMIUM_DETAILS = 'SET_PREMIUM_DETAILS'
export const SET_IS_PREMIUM = 'SET_IS_PREMIUM'
export const SET_IS_FREE_PLAYBACK = 'SET_IS_FREE_PLAYBACK'
export const SET_IS_CANCEL = 'SET_IS_CANCEL'
export const SET_IS_LOADING_PREMIUM_DONE = 'SET_IS_LOADING_PREMIUM_DONE'
export const SET_IS_TRIAL_USED = 'SET_IS_TRIAL_USED'
export const SET_IS_BILLING_ISSUE = 'SET_IS_BILLING_ISSUE'
export const SET_IS_BILLING_ISSUE_GRACE = 'SET_IS_BILLING_ISSUE_GRACE'

export const HIDE_ALERT = 'HIDE_ALERT'
export const SHOW_ALERT = 'SHOW_ALERT'

export const SET_CHAPTERS = 'SET_CHAPTERS'
export const SET_BOOKMARKS = 'SET_BOOKMARKS'
export const SET_CONTENT = 'SET_CONTENT'
export const SET_PROVIDER_ID = 'SET_PROVIDER_ID'
export const SET_IS_BOOKMARK = 'SET_IS_BOOKMARK'
export const SET_IS_PAUSED = 'SET_IS_PAUSED'
export const SET_IS_AUTOPLAY = 'SET_IS_AUTOPLAY'
export const SET_IS_PREVIEW = 'SET_IS_PREVIEW'
export const SET_IS_NOTHING_TO_PLAY = 'SET_IS_NOTHING_TO_PLAY'
export const SET_IS_BOOKMARK_OPEN = 'SET_IS_BOOKMARK_OPEN'
export const SET_IS_INTERRUPTED = 'SET_IS_INTERRUPTED'
export const SET_IS_MANUAL_PLAY = 'SET_IS_MANUAL_PLAY'
export const SET_LICENSE = 'SET_LICENSE'
export const SET_PLAYER_SETTINGS = 'SET_PLAYER_SETTINGS'

export const SET_PRICES = 'SET_PRICES'
export const SET_PRICE_FULL = 'SET_PRICE_FULL'
export const SET_PRICE_BASE = 'SET_PRICE_BASE'
export const SET_PRODUCTS = 'SET_PRODUCTS'
export const SET_IS_TRIAL = 'SET_IS_TRIAL'
export const SET_TRIAL_DAYS = 'SET_TRIAL_DAYS'

export const SET_SCROLL = 'SET_SCROLL'

export const SET_GRADIENT = 'SET_GRADIENT'
