import {getLocalStorageData, removeLocalStorageData, setLocalStorageData} from "./_root";
import {ILocalBookmark} from "../../models/audiobook";

const getPlayerBookmarks = (): ILocalBookmark[] | null => {
  return getLocalStorageData('anyplayPlayerBookmarks')
};


const setPlayerBookmarks = (data:ILocalBookmark): void => {
  const bookmarks = getPlayerBookmarks()
  let anyplayPlayerBookmarks = []
  if(bookmarks) {
    anyplayPlayerBookmarks.push(...bookmarks, data)
  } else {
    anyplayPlayerBookmarks.push(data)
  }
  setLocalStorageData('anyplayPlayerBookmarks', anyplayPlayerBookmarks)
};

const removePlayerBookmarks = (): void => {
  removeLocalStorageData('anyplayPlayerBookmarks')
};


export { getPlayerBookmarks, setPlayerBookmarks, removePlayerBookmarks};
