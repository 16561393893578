import React from "react"
  import {NavLink} from "react-router-dom";
import {createStyles, ListItem, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {IMenuRoute} from "../../models/route";
import {useTranslation} from "react-i18next";
import AnyplayNotificationIcon from "./AnyplayNotificationIcon";

const useStyles = makeStyles(() =>
  createStyles({
    inactive: {
      color: 'gray !important;'
    },
    noTd: {
      textDecoration: 'none'
    },
    noWrap:{
      whiteSpace: 'nowrap',
      paddingLeft: 0,
      borderBottom: '1px solid gray'
    },
    boldMenu: {
      fontWeight: 600
    }
  })
)

const RootMenu:React.FC<{route: IMenuRoute, activeTab: string}>
  = ({route, activeTab}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
      <NavLink
        to={route.disabled ? '' : route.path}
        className={['w100', classes.noTd].join(' ')}
        key={route.title}>
        <ListItem button className={classes.noWrap} disabled={route.disabled}>
          <Typography className={[activeTab === route.path ? '' : classes.inactive, classes.boldMenu].join(' ')}>
            {t(route.title)}
          </Typography>
          <AnyplayNotificationIcon isShow={route.isNotificationActive}/>
        </ListItem>
      </NavLink>
  )
}
export default RootMenu
