import {IPriceState} from "./IPriceState";
import {SET_IS_TRIAL, SET_PRICE_BASE, SET_PRICE_FULL, SET_PRICES, SET_PRODUCTS, SET_TRIAL_DAYS} from "../types";

const handlers = {
  [SET_PRICES]: (state:IPriceState, payload:{payload: any, type: 'SET_PRICES'}) => ({...state, prices: payload.payload}),
  [SET_PRICE_FULL]: (state:IPriceState, payload:{payload: any, type: 'SET_PRICE_FULL'}) => ({...state, fullPrice: payload.payload}),
  [SET_PRICE_BASE]: (state:IPriceState, payload:{payload: any, type: 'SET_PRICE_BASE'}) => ({...state, basePrice: payload.payload}),
  [SET_PRODUCTS]: (state:IPriceState, payload:{payload: any, type: 'SET_PRODUCTS'}) => ({...state, products: payload.payload}),
  [SET_IS_TRIAL]: (state:IPriceState, payload:{payload: any, type: 'SET_IS_TRIAL'}) => ({...state, isTrial: payload.payload}),
  [SET_TRIAL_DAYS]: (state:IPriceState, payload:{payload: any, type: 'SET_TRIAL_DAYS'}) => ({...state, trialDays: payload.payload}),
  DEFAULT: (state:any) => state
}

export const priceReducer = (state: IPriceState, action: { type: any, payload?: any }) => {
  // @ts-ignore
  const handle = handlers[action.type] || handlers.DEFAULT
  return handle(state, action)
}
