import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, Grid,
  Typography
} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";

const useStyle = makeStyles((theme)=> ({
  cardBg: {
    background:'#1C1C1C'
  },
  cardRadiusTop :{
    borderRadius: '20px 20px 0 0',
    paddingBottom: 0
  },
  cardRadiusBottom :{
    borderRadius: '0 0 20px 20px'
  },
  actions :{
    padding: 0
  },
  actionsLeft :{
    borderTop: '1px solid gray',
    borderRight: '1px solid gray',
    borderRadius: '0 0 0 20px',
    '&:hover': {
      background: theme.palette.secondary.main,
    },
  },
  actionsRight :{
    borderTop: '1px solid gray',
    borderLeft: '1px solid gray',
    borderRadius: '0 0 20px 0',
    '&:hover': {
      background: theme.palette.secondary.main,
    }
  },
  actionsBtn: {
    padding: '15px',
    fontWeight: 600,
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.primary.contrastText,
    }
  }
}))
const ModalOnAuth:React.FC<{open:boolean, modalHeader:string, modalContent:string, modalSubmitBtnText: string,
  onManualClose():void, onManualSubmit(): void}>
  = ({open, modalHeader, modalContent, modalSubmitBtnText , onManualClose, onManualSubmit}) => {
  const { t } = useTranslation();
  const classes = useStyle();
  return (
    <Dialog
      open={open}
      maxWidth={'xs'}
      onClose={onManualClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className={[classes.cardBg, classes.cardRadiusTop].join(' ')}>
        <Typography variant={'h6'} align={'center'}>
          {t(modalHeader)}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.cardBg}>
        <DialogContentText id="alert-dialog-description" variant={'body1'} align={'center'}>
          {t(modalContent)}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={[classes.cardBg, classes.actions, classes.cardRadiusBottom].join(' ')}>
        <Grid container>
          <Grid item xs={6} className={classes.actionsLeft}>
            <Button
              onClick={onManualClose}
              className={classes.actionsBtn}
              color="secondary"
              fullWidth>
              {t('cmn_cancel')}
            </Button>
          </Grid>
          <Grid item xs={6} className={classes.actionsRight}>
            <Button
              onClick={onManualSubmit}
              className={classes.actionsBtn}
              color="secondary"
              fullWidth>
              {t(modalSubmitBtnText)}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
export default ModalOnAuth
