import {createContext} from 'react'
import {alertType} from "./AlertState";

export const initAlertState: any = {
  visible: false,
  alert: {text:'',type:''},
  hide: () => {},
  show: (message: string, variant: alertType) => {}
}
// @ts-ignore
export const AlertContext = createContext(initAlertState)
