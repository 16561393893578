import {IScrollState} from "./scrollContext";
import {SET_SCROLL} from "../types";
import {IProfileState} from "../profile/IProfileState";


const handlers = {
  [SET_SCROLL]: (state:IProfileState, payload:{payload: number, type: 'SET_SCROLL'}) => ({...state, scroll: payload.payload}),
  DEFAULT: (state:IScrollState) => state
}
export const scrollReducer = (state: IScrollState, action: { type: any, payload?: any }) => {
  // @ts-ignore
  const handle = handlers[action.type] || handlers.DEFAULT
  return handle(state, action)
}
