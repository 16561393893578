import {anyplayV2} from "../plugins/axios";
import {IRCSubs} from "../models/revenuecat";

class RCService {
  public static async getSubscription():Promise<IRCSubs> {
    const prices: {data: any } = await anyplayV2
      .get(`profiles/rc/subscriber`)
    return prices.data
  }
}
export default RCService
