import {IPriceState} from "./IPriceState";
import {createContext} from "react";

export const initState: IPriceState = {
  basePrice: 0,
  fullPrice: null,
  prices: [],
  products: [],
  isTrial: false,
  trialDays: null,
  setStripePrices: () => {},
  setStripePriceBase: ()=>{},
  setStripePriceFull: ()=>{},
  setStripeProducts: ()=>{},
  setIsTrial: () => {},
  setTrialDays: () => {}
}

export const PriceContext = createContext(initState)
