export const es = {
  "profile_edit_title": "Cuenta",
  "profile_edit_first_name": "Nombre",
  "profile_edit_last_name": "Apellido",
  "profile_edit_email": "correo electrónico\n",
  "profile_edit_membership_plan": "Plan de suscripción",
  "profile_settings_languages": "Idiomas",
  "profile_settings_sign_out": "Desconectar",
  "cmn_playlists": "Listas de reproducción",
  "profile_account_change_email": "Cambiar correo electrónico",
  "profile_account_change_password": "Cambia la contraseña\n",
  "profile_account_change_name": "Editar nombre ",
  "cmn_anyplay_premium": "Anyplay Plus",
  "profile_edit_save": "Guardar",
  "profile_password_current": "Contraseña actual",
  "profile_password_new": "Nueva contraseña",
  "profile_password_confirmation": "Confirmar nueva contraseña",
  "profile_email_new": "Nuevo correo electrónico",
  "profile_password_send_link": "Enviar enlace",
  "cmn_cancel": "Cancelar",
  "profile_password_forgot": "¿Se te olvidó tu contraseña?",
  "profile_subscription_management": "Administrar Suscripciones",
  "promo_sign_in": "Registrarse\n",
  "promo_title_2": "Disfrúta escuchar en lugar de mirar la pantalla",
  "auth_sign_up_for_books": "Regístrete para obtener audiolibros ilimitados",
  "auth_or": "O",
  "auth_login_to_your_account": "Ingrese a su cuenta\n",
  "auth_sign_up_password": "Elige tu contraseña\n",
  "auth_create_account": "Crear cuenta",
  "add_title_playlist_create": "Crear",
  "add_title_playlist_create_new": "Crear nueva lista de reproducción",
  "add_title_playlist_enter_title": "Escriba un título",
  "manage_playlist_remove": "Eliminar lista de reproducción",
  "manage_playlist_make_public": "Hacer pública la lista de reproducción",
  "subscribe_offer_description": "Acceso ilimitado a 200.000 audiolibros con nuevos libros todos los días.",
  "subscribe_try_it_free": "Pruébalo gratis",
  "title_detail_narrator": "Narradora",
  "title_detail_language": "Idioma",
  "title_detail_playtime": "Reproducido",
  "title_detail_year": "Año\n",
  "title_detail_publisher": "Editora",
  "title_detail_others_have_read": "Otros han leído",
  "player_jump_to_chapter": "Ir al capítulo",
  "player_add_to_playlist": "Añadir a mi lista",
  "subscribe_go_premium": "¿Quieres escuchar más?",
  "subscribe_go_premium_message": "Desbloquea todos los libros con Anyplay Plus.",
  "player_preview": "Vista previa",
  "browse_ongoing_tab": "Seguir Leyendo",
  "download_audiobook_delete": "Eliminar",
  "search_discover_fiction": "Ficción",
  "search_discover_non_fiction": "No ficción",
  "search_authors": "Autores",
  "search_playlists": "Playlists",
  "search_title_or_author": "Buscar título o autor",
  "feed_new_for_you": "Nuevo para tí",
  "cmn_for_you": "Para tí",
  "cmn_browse": "Navegar",
  "cmn_search": "Buscar",
  "audiobooks_status_finished": "Libros que he leído",
  "player_play": "Reproducir",
  "subscribe_start": "Empezar",
  "profile_add_language_title": "Añadir un idioma",
  "subscribe_cancel_anytime": "Cancela cuando quieras",
  "subscribe_v3_full_access": "Descubre 200.000+ libros",
  "subscribe_v3_read_as_much": "Lee sin límites",
  "subscribe_v3_stream_or_download": "Escucha y descarga libros sin conexión",
  "subscribe_v3_new_books_weekly": "Nuevos libros cada semana",
  "subscribe_v3_low_fee": "Tarifa baja mensual",
  "subscribe_v3_no_ads": "Sin anuncios",
  "subscribe_v3_trusted_by": "Probado por los amantes de libros de todo el mundo",
  "player_save_for_later": "Guardar ",
  "subscribe_v3_subscribe_now": "Suscríbete ahora",
  "search_no_results": "Sin resultados",
  "manage_playlist_make_private": "Hacer privado",
  "cmn_anyplay_free": "Anyplay Free",
  "browse_audiobooks_tab": "Audiolibros",
  "title_detail_unavailable_territory": "Lo sentimos, este libro ha sido retirado recientemente por el editorial de su territorio.",
  "promo_continue_with_google": "Seguir con Google",
  "promo_sign_up_with_email": "Regístrete gratis con correo electrónico",
  "promo_continue_with_facebook": "Seguir con Facebook",
  "auth_whats_your_email": "¿Cuál es tu correo electrónico?",
  "auth_you_need_to_confirm_email": "Deberás confirmar este correo electrónico más tarde.",
  "auth_email_already_registered": "Este correo electrónico ya está conectado a una cuenta",
  "auth_do_you_want_to_login_instead": "¿Quiere iniciar una sesión?",
  "auth_whats_your_name": "¿Cuál es tu nombre?",
  "auth_name_appears_on_your_profile": "Esto aparece en tu perfil de Anyplay.",
  "auth_name_placeholder": "John Smith",
  "auth_password": "contraseña",
  "auth_register": "Registrar",
  "subscribe_v4_books_all_genres": "200.000+ libros de todos los géneros",
  "subscribe_v4_start_free_trial": "Empieza la prueba gratuita",
  "action_share_book": "Compartir libro",
  "subscribe_download_go_premium": "Desbloquea esta función",
  "auth_short_password_error_message": "La contraseña es demasiado corta\nEl mínimo es de 6 caracteres.",
  "auth_signup": "Inscribirse",
  "auth_email_fill_error_message": "Por favor completa el correo electrónico",
  "player_track": "Seguir",
  "search_more_audiobooks": "Ver todos los libros",
  "search_more_authors": "Ver todas las autoras",
  "search_more_playlists": "Ver todas las listas",
  "playlist_share": "Compartir",
  "player_saved_for_later": "Guardado para después",
  "title_detail_rating": "Calificación",
  "cmn_edit": "Editar",
  "review_rating_title": "¿Qué le pareció este libro?",
  "review_comment_title": "Descríbelo con tus propias palabras",
  "review_title": "Calificaciones y comentarios",
  "playlist_edit_title": "Editar Playlist",
  "title_more_from_author": "Más de este autor",
  "title_mark_finished": "Marcar libro como terminado",
  "title_rate_and_comment": "Calificar y comentar",
  "title_download": "Descargar",
  "profile_settings_help_center": "Centro de ayuda",
  "language_preferred_order": "Orden de idioma preferido",
  "profile_settings_terms_of_service": "Términos de servicio",
  "profile_login_Information": "información de acceso",
  "profile_account_change_avatar": "Cambiar foto de perfil",
  "profile_account_reset_password": "Restablecer la contraseña",
  "profile_account_reset_password_send": "Se ha enviado un enlace para restablecer la contraseña a",
  "profile_account_passords_not_match_alert": "La nueva contraseña y la contraseña de confirmación no coinciden",
  "profile_account_display_name": "Nombre para mostrar",
  "profile_account_biography": "Biografía",
  "profile_account_display_name_description": "(si comparte listas de reproducción, este será su nombre público)",
  "profile_account_biography_description": "(texto breve para describir su perfil de usuario público)",
  "profile_add_email_login": "Agregar inicio de sesión de correo electrónico",
  "profile_add_email_login_to_unlink": "Para desvincular su cuenta, agregue primero un correo electrónico de acceso.\nEsto garantizará que no pierda sus datos de Anyplay.",
  "profile_signin_with_apple": "Iniciar sesión con Apple",
  "profile_signin_with_facebook": "Iniciar sesión usando Facebook",
  "ongoing_placeholder_title": "No has empezado ningún libro",
  "ongoing_placeholder_subtitle": "Toca \"reproducir\" en cualquier libro y aparecerá aquí.",
  "paywall_read_more_with_unlimited_access": "Leer más con acceso ilimitado",
  "paywall_start_your_free_trial": "Comience su prueba gratuita de 7 días",
  "period_unit_month": "mes",
  "period_unit_year": "año",
  "period_unit_months": "meses",
  "subscription_alert_button": "7 días gratis",
  "subscription_alert_skip_trial": "Omitir prueba",
  "auth_next": "Siguiente",
  "profile_consumption_total_label": "Total",
  "profile_consumption_this_month_label": "Este mes",
  "profile_consumption_title": "Tiempo de escucha",
  "playlists_see_all": "Ver más",
  "subscribe_billing_issue_android": "Actualizar la información de pago en Google Play Store",
  "subscribe_billing_issue_ios": "Actualizar la información de pago en la App Store",
  "subscribe_billing_issue_header": "Problema de facturación detectado",
  "finished_placeholder_title": "No has terminado ningún libro",
  "saved_placeholder_title": "No has guardado ningún libro",
  "profile_edit_interface_language": "Establecer el idioma de la interfaz",
  "subscribe_save_discount_web": "Ahorra",
  "auth_terms_web": "Al crear una cuenta, acepta los <1>Términos y condiciones de uso de Anyplay</1>. Para saber cómo Anyplay recopila, usa y protege sus datos personales, lea la <3>Política de privacidad de Anyplay</3>.",
  "cmn_anyplay_free_description": "Reproduce todos los podcasts y audiolibros limitados",
  "cmn_select": "Seleccionar",
  "prices_read_without_limits": "Leer sin límites",
  "prices_choose_your_plus": "Elige tu Plus",
  "prices_other_plans": "Otros planes",
  "download_app_use_our_player": "o usa nuestro <1>reproductor web</1>",
  "promo_continue_with_apple": "Seguir con Apple",
  "profile_password_reset_message_web": "Enviaremos un correo electrónico a {{email}} con un enlace para restablecer su contraseña. Si no puede acceder a esta cuenta de correo electrónico, comuníquese con Atención al cliente.",
  "profile_signin_with_google": "Iniciar sesión con Google",
  "auth_sign_in_remember_me": "Recuérdame",
  "profile_settings_version_web": "Versión:",
  "auth_sign_in_no_account": "¿No tienes una cuenta?",
  "auth_signup_already_user": "¿Ya tienes una cuenta?",
  "banner_preview_title": "Esta es una vista previa de Anyplay",
  "profile_consumption_total_web": "Ha leído {{books}} libros y escuchado {{hours}} horas desde que comenzó a usar Anyplay",
  "profile_consumption_this_month_web": "Has empezado {{books}} libros y has escuchado {{hours}} horas este mes.",
  "title_mark_ongoing": "Volver a los libros empezados"
}
