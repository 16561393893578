import {createContext} from "react";
import {IProfileState} from "./IProfileState";

export const initState: IProfileState = {
  profile: null,
  userTerritory: null,
  stripeData: null,
  rcData: null,
  isUserHasPremium: false,
  isFreePlayback: false,
  isCancelled: false,
  isTrialUsed: false,
  isBillingIssue: false,
  isBillingIssueOutGrace: false,
  isLoadingPremiumDone: false,
  premiumDetails: {
    name: '',
    subName: '',
    price: '',
    nextPay: '',
    payPeriod: '',
    description: ''
  },
  setProfile: () => {},
  setUserTerritory: ()=> {},
  setStripe: () => {},
  setRC: ()=> {},
  setFreePlayBack: ()=> {},
  setIsUserHasPremium: ()=> {},
  setIsCancelled: ()=> {},
  setPremiumDetails: ()=> {},
  setIsBillingIssue: ()=> {},
  setIsBillingIssueOutGrace: ()=> {},
}

export const ProfileContext = createContext(initState)

