import {getLocalStorageData, removeLocalStorageData, setLocalStorageData} from "./_root";

const getPreviewDone = (): 'Done' | null => {
  return getLocalStorageData('anyplayPreviewDone');
};
const setPreviewDone = (data: 'Done'): void => {
  setLocalStorageData('anyplayPreviewDone', data);
};
const removePreviewDone = (): void => {
  removeLocalStorageData('anyplayPreviewDone');
};
export { getPreviewDone, setPreviewDone, removePreviewDone}
