import React, {useContext} from "react";
import {IconButton, ListItemIcon, ListItemText, Menu, MenuItem} from "@material-ui/core";
import AvTimerIcon from "@material-ui/icons/AvTimer";
import Fade from "@material-ui/core/Fade";
import CheckIcon from "@material-ui/icons/Check";
import {makeStyles} from "@material-ui/core/styles";
import {PlayerContext} from "../../../context/player/playerContext";

const useStyles = makeStyles(()=>({
  iconContainer: {
    minWidth: '30px!important'
  },
}))

const speedRateValues:number[] = [0.75, 1, 1.25, 1.5, 1.75, 2]

const SpeedRateMenu = () => {
  const { playerSettingsLocal, setPlayerSettingsLocal } = useContext(PlayerContext)

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<EventTarget & HTMLButtonElement | null>(null);
  const [anchorElType, setAnchorElType] = React.useState<string | null>(null);

  const handleClick = (event:React.MouseEvent<HTMLButtonElement, MouseEvent>, type: 'playlist' | 'rate') => {
    setAnchorEl(event.currentTarget);
    setAnchorElType(type)
  };
  const handleClose = (speedRate: number | {}) => {
    if (typeof speedRate === 'number') {
      setPlayerSettingsLocal({...playerSettingsLocal, speedRate})
    }
    setAnchorEl(null);
    setAnchorElType(null)
  };

  return (
    <>
      <IconButton onClick={(event)=>handleClick(event,'rate')}>
        <AvTimerIcon/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorElType === 'rate')}
        TransitionComponent={Fade}
        onClose={(event)=>handleClose(event)}
      >
        {speedRateValues.map((speedRateValue, index)=>(
          <MenuItem
            key={index+'_speedRateValue'}
            value={speedRateValue}
            onClick={()=>handleClose(speedRateValue)}>
            <ListItemIcon className={classes.iconContainer}>
              {speedRateValue === playerSettingsLocal.speedRate ? <CheckIcon/> : null}
            </ListItemIcon>
            <ListItemText>
              {speedRateValue}x
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
export default SpeedRateMenu
