import React from "react";

const ShowConditionally:React.FC<{isShow:boolean}> = ({isShow,children})=> {
  return (
    <>
      {
        isShow ?
          children
          : null
      }
    </>
  )
}
export default ShowConditionally
