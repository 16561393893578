import crypto from 'crypto';

const apiHeaderTime = Math.floor(Date.now()/1000);
const apiKey = 'BTJXWAD7UBQVJVXG3YZS'
const apiSecret = 'wxm^E2A5Nk4wwHjbkrtfJUZhNELMexW5YrS7w8XC'

const data4Hash = apiKey + apiSecret + apiHeaderTime;

const sha1 = crypto.createHash('sha1');

sha1.update(data4Hash);
const signature = sha1.digest('hex');

const podcastindexHeaders = {
  "X-Auth-Date": ""+apiHeaderTime,
  "X-Auth-Key": apiKey,
  "Authorization": signature,
  "User-Agent": "SuperPodcastPlayer/1.8"
}

export default podcastindexHeaders
