import {getLocalStorageData, removeLocalStorageData, setLocalStorageData} from "./_root";
import {IFeedV2, IFeedV3} from "../../models/feed";

const getFeedData = (): IFeedV3[] | null => {
  return getLocalStorageData('anyplayFeedV3')
};
const getFeedDataPublic = (): IFeedV2[] | null => {
  return getLocalStorageData('anyplayFeedV3Public')
};
const getIsAllFeed = (): boolean | null => {
  return getLocalStorageData('anyplayIsAllFeed')
}

const setFeedData = (data: IFeedV3[]): void => {
  setLocalStorageData('anyplayFeedV3', data)
};
const setFeedDataPublic = (data: IFeedV2[]): void => {
  setLocalStorageData('anyplayFeedV3Public', data)
};
const setIsAllFeed = (data:boolean): void => {
  setLocalStorageData('anyplayIsAllFeed', data)
};

const removeFeedData = (): void => {
  removeLocalStorageData('anyplayFeedV3')
};
const removeFeedDataPublic = (): void => {
  removeLocalStorageData('anyplayFeedV3Public')
};
const removeIsAllFeed = (): void => {
  removeLocalStorageData('anyplayIsAllFeed')
};

export {
  getFeedData,getIsAllFeed, getFeedDataPublic,
  setFeedData,setIsAllFeed, setFeedDataPublic,
  removeFeedData,removeIsAllFeed, removeFeedDataPublic
};
