import React, {useContext} from "react";
import {
  AppBar,
  Typography,
  Toolbar,
  Box,
  Button,
  Avatar,
  Menu,
  MenuItem, IconButton, Drawer, Divider, useMediaQuery, useTheme,
} from "@material-ui/core";
import {Link, NavLink, RouteComponentProps, withRouter} from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {ProfileContext} from "../../context/profile/profileContext";
import {useTranslation} from "react-i18next";
import {useMenuItems} from "../utils/MenuItems";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {removeProfileData, removeProfileRC, removeProfileStripe} from "../../utils/localstorage/profile";
import {removeFeedData} from "../../utils/localstorage/feed";
import {removeAuthData} from "../../utils/auth";
import {removePlayerBookmarks} from "../../utils/localstorage/playerBookmarks";
import {useGoogleLogout} from "react-google-login";
import HideOnScroll from "../utils/HideOnScroll";
import AnyplayBadge from "../utils/AnyplayBadge";
import AnyplayNotificationIcon from "../utils/AnyplayNotificationIcon";
import {PlayerContext} from "../../context/player/playerContext";
import usePageGradient from "../../utils/hooks/usePageGradient";

const useStyles = makeStyles((theme:Theme) =>
  createStyles({
    appBar: {
      [theme.breakpoints.up('xs')]: {
        paddingLeft: '5px',
        paddingRight: '5px'
      }
    },
    logoWrapper: {
      display: 'flex',
      margin: 'auto 0',
      [theme.breakpoints.down('xs')]: {
        padding: '0 5px'
      },
    },
    logo: {
      height: 40,
      display: 'inline',
      verticalAlign: 'super'
    },
    m10: {
      margin: 10,
    },
    navlink: {
      fontSize: '1.1rem',
      fontWeight: 600,
      color: 'white',
      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'none',
        paddingBottom: 10,
        boxShadow: 'inset 0 -5px 0 0 #fc0a7e'
      }
    },
    divider: {
      padding: '5px 0px 5px 0px',
      margin:'0 5px',
      borderLeft: '1px solid white',
      minHeight: '30px'
    },
    nameNavBtn: {
      '&:hover': {
        color: '#0a16fc'
      }
    },
    customAvatar: {
      [theme.breakpoints.down('sm')]: {
        width: '30px',
        height: '30px',
      },
    },
    nameNav: {
      fontSize: '1.2rem',
      fontWeight: 600,
      textTransform: 'capitalize'
    },
    customButton: {
      background: '#e1dfdf',
      padding: '5px 25px',
      borderRadius: '20px',
      textAlign: 'center',
      color: 'black !important',
      fontWeight: 600,
      textTransform: 'initial',
      fontSize: '1.1rem',
      '&:hover': {
        color: 'white !important',
        background: '#0a16fc'
      }
    },
    customButtonOutline: {
      padding: '5px 25px',
      borderRadius: '20px',
      textAlign: 'center',
      color: 'white !important',
      fontWeight: 600,
      textTransform: 'initial',
      fontSize: '1.1rem',
      '&:hover': {
        color: 'white !important',
        background: '#0a16fc'
      }
    },
    nameLink: {
      fontSize: '1.2rem !important',
      // @ts-ignore
      fontWeight: '700 !important',
      color: 'white'
    },
    nameLinkLight: {
      fontSize: '1.2rem !important',
      // @ts-ignore
      color: 'white'
    },
    drawer: {
      width: `100%`,
      flexShrink: 0,
    },
    drawerPaper: {
      width: 'inherit',
      background: 'black'
    },
    hide: {
      display: 'none',
    },
    drawerAccount: {
      background: '#002B33 !important'
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
  }))
const NavbarV2: React.FC<any&RouteComponentProps> = ({history}) =>{
  const {profile, isUserHasPremium, isBillingIssue,
    setProfile, setRC, setIsUserHasPremium,setPremiumDetails } = useContext(ProfileContext)
  const {setIsInterrupted, setContent} = useContext(PlayerContext)

  const { t } = useTranslation();
  const theme = useTheme();
  const {accountRoutes} = useMenuItems()
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const { signOut } = useGoogleLogout({clientId:`${process.env.REACT_APP_GOOGLE_PK}`})
  const {gradientNavbarString} = usePageGradient()


  const [open, setOpen] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openProfile, setOpenProfile] = React.useState<boolean>(false);
  const [drawerMode, setDrawerMode] = React.useState<'home' | 'account'>('home')

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenProfile(true)
    setAnchorEl(event.currentTarget);
  };
  const handleMenuDrawer = () => {
    setDrawerMode('account')
    setOpen(true)
  }
  const handleClose = () => {
    setOpenProfile(false)
    setAnchorEl(null);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleDrawerOpen = () => {
    setDrawerMode('home')
    setOpen(true);
  };
  const removePlayback = ()=> {
    setIsInterrupted(true)
    setContent({bookmarks: null, localBookmark: null, chapters: [],contentId: '', contentType: 'audiobook'})
  }
  const onLogOut = () => {
    handleClose()
    removeProfileRC()
    removeProfileStripe()
    removeFeedData()
    removeAuthData()
    removeProfileData()
    removePlayerBookmarks()
    removePlayback()
    signOut()
    setProfile(null)
    setIsUserHasPremium(null)
    setPremiumDetails({description: t('cmn_anyplay_free_description'), name: t('cmn_anyplay_free'), nextPay: '', payPeriod: '', price: '', subName: '', subsType: undefined})
    setRC(null)
    history.push('/')
    setOpen(false)
  }

  return (

    <HideOnScroll>
      <AppBar
        position={matchesSm ? 'relative' : 'fixed'}
        style={matches ? {background:gradientNavbarString}: undefined}
        id={'anyplayNav'}
        className={classes.appBar}
      >
        <Toolbar disableGutters>
          {
            profile ?
              <NavLink to={'/'} className={classes.logoWrapper}>
                <img
                  className={classes.logo}
                  src={require(`../../assets/WhiteFaceTagline.webp`).default}
                  alt="icon"
                  data-src={require('../../assets/WhiteFaceTagline.webp').default}
                  loading={'lazy'}
                />
              </NavLink>
              :
              <a href={'https://www.anyplay.fm/'} className={classes.logoWrapper}>
                <img
                  className={classes.logo}
                  src={require(`../../assets/WhiteFaceTagline.webp`).default}
                  alt="icon"
                  data-src={require('../../assets/WhiteFaceTagline.webp').default}
                  loading={'lazy'}
                />
              </a>
          }
          <Typography className={'marginCenter'}/>
          <Box display={{ xs: 'none', md: 'flex'}} alignItems={'center'}>
            { isUserHasPremium ? '' :
              <NavLink to={'/prices'} className={'noTd'}>
              <span
                className={[classes.m10, classes.navlink].join(' ')}>
                {t('cmn_anyplay_premium')}
              </span>
              </NavLink>
            }
            <a
              href="https://anyplay.crunch.help/"
              target={'_blank'}
              rel={'noreferrer noopener'}
              className={'noTd'}>
              <span
                className={[classes.m10, classes.navlink].join(' ')}>
                {t('profile_settings_help_center')}
              </span>
            </a>
            {/*</Link>*/}
            {/*</NavLink>*/}
           <NavLink
             to={'/download'}
             className={'noTd'}>
             <span
               className={[classes.m10, classes.navlink].join(' ')}>
               {t('title_download')}
             </span>
           </NavLink>

            <span className={classes.divider}/>

            { profile ?
              <>
                <Button
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  className={classes.nameNavBtn}
                  color="inherit"
                >
                  <AnyplayBadge
                    badgeContent={'!'}
                    isShow={isBillingIssue}
                  >
                  { profile?.attributes.avatar_image_url ?
                    <Avatar src={profile?.attributes.avatar_image_url} className={classes.customAvatar}/>
                    : <Avatar className={classes.customAvatar}> {
                      profile?.attributes.first_name ?
                        profile?.attributes.first_name.charAt(0).toUpperCase() :
                        profile?.attributes.email ?
                          profile?.attributes.email.charAt(0).toUpperCase()
                          : 'A'
                    } </Avatar>
                  }
                  </AnyplayBadge>
                  <Box pl={1} className={classes.nameNav}>
                    {
                      profile?.attributes.first_name ?
                        profile?.attributes.first_name
                        : profile?.attributes.email ?
                        profile?.attributes.email.split('@')[0] : 'A' }
                  </Box>
                  <ExpandMoreIcon/>
                </Button>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={openProfile}
                  onClose={handleClose}
                >
                  <Link to={'/account/overview'}
                        className={'noTd'}
                        style={{color: 'white'}}
                        onClick={handleClose}>
                    <MenuItem className={classes.nameLink}>{t('profile_edit_title')}
                      <AnyplayNotificationIcon isShow={isBillingIssue}/>
                    </MenuItem>
                  </Link>
                  <MenuItem onClick={onLogOut} className={classes.nameLink}>{t('profile_settings_sign_out')}</MenuItem>
                </Menu>
              </>
              : <>
                <Link to={'/sign_up'} className={[classes.m10, 'noTd'].join(' ')}>
                  <Button
                    className={classes.customButtonOutline}
                    variant={'outlined'}>
                    {t('auth_signup')}
                  </Button>
                </Link>
                <Link to={'/sign_in'} className={[classes.m10, 'noTd'].join(' ')}>
                  <Button
                    className={classes.customButton}
                    variant={'contained'}>
                    {t('promo_sign_in')}
                  </Button>
                </Link>
              </>
            }
          </Box>
          <Box display={{ xs: 'block', md: 'none'}}>
            { profile ?
              <>
                <Button
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenuDrawer}
                  className={classes.nameNavBtn}
                  color="inherit"
                >
                  <AnyplayBadge
                    badgeContent={'!'}
                    isShow={isBillingIssue}
                  >
                  { profile?.attributes.avatar_image_url ?
                    <Avatar src={profile?.attributes.avatar_image_url} className={classes.customAvatar}/>
                    : <Avatar className={classes.customAvatar}> {
                      profile?.attributes.first_name ?
                        profile?.attributes.first_name.charAt(0).toUpperCase() :
                        profile?.attributes.email ?
                          profile?.attributes.email.charAt(0).toUpperCase()
                          : 'A'
                    } </Avatar>
                  }
                  </AnyplayBadge>
                </Button>
              </>
              : null
            }
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}

              className={clsx(open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={open}
          classes={{
            paper: [classes.drawerPaper, drawerMode === 'account' ? classes.drawerAccount: ''].join(' ')
          }}
        >
          <div className={classes.drawerHeader}>
            <Link to={'/'} onClick={()=>setOpen(false)} className={classes.logoWrapper}/>
            <IconButton onClick={handleDrawerClose}>
              {<CloseIcon />}
            </IconButton>
          </div>

          <List>
            {drawerMode === 'account' ?
              accountRoutes.map((route, index) => (
                <NavLink
                  to={route.path}
                  className={'noTd'}
                  onClick={() => setOpen(false)}
                  key={index}>
                  <ListItem button className={classes.nameLink}>
                    {t(route.title)}
                    <AnyplayNotificationIcon isShow={route.isNotificationActive}/>
                  </ListItem>
                </NavLink>
              ))
              :
              <>
                {isUserHasPremium ? '' :
                  <NavLink to={'/prices'} className={'noTd'} onClick={() => setOpen(false)}>
                    <ListItem button className={classes.nameLink}>
                      {t('cmn_anyplay_premium')}
                    </ListItem>
                  </NavLink>
                }
                <ListItem
                  button
                  onClick={()=> {
                    setOpen(false)
                  }}>
                  <NavLink
                    to={'/download'}
                    className={'noTd'}>
                    <span
                      className={classes.navlink}>
                      {t('title_download')}
                    </span>
                  </NavLink>
                </ListItem>
                <a
                  href="https://anyplay.crunch.help/"
                  target={'_blank'}
                  rel={'noreferrer noopener'}
                  className={'noTd'}
                  onClick={()=>setOpen(false)}>
                  <ListItem button className={classes.nameLink}>
                    {t('profile_settings_help_center')}
                  </ListItem>
                </a>
                <Divider light style={{width: '15px', marginLeft: '16px'}}/>
                {profile ?
                  <>
                    <NavLink to={'/account/overview'} className={['w100', 'noTd'].join(' ')}
                             onClick={() => setOpen(false)}>
                      <ListItem button className={classes.nameLinkLight}>
                        {t('profile_edit_title')}
                      </ListItem>
                    </NavLink>
                  </>
                  :
                  null
                }
                {profile ?
                  <>
                    <NavLink to={'/'} className={['w100', 'noTd'].join(' ')}
                             onClick={() => setOpen(false)}>
                      <ListItem button className={classes.nameLinkLight}>
                        {t('cmn_browse')}
                      </ListItem>
                    </NavLink>
                    <ListItem button onClick={onLogOut} className={classes.nameLinkLight}>
                      {t('profile_settings_sign_out')}
                    </ListItem>
                  </>
                  :
                  <>
                    <NavLink to={'/sign_up'} className={['w100', 'noTd'].join(' ')} onClick={()=>setOpen(false)}>
                      <ListItem button className={classes.nameLinkLight}>
                        {t('auth_signup')}
                      </ListItem>
                    </NavLink>
                    <NavLink to={'/sign_in'} className={['w100', 'noTd'].join(' ')} onClick={()=>setOpen(false)}>
                      <ListItem button className={classes.nameLinkLight}>
                        {t('promo_sign_in')}
                      </ListItem>
                    </NavLink>
                  </>
                }
              </>
            }
          </List>
        </Drawer>
      </AppBar>
    </HideOnScroll>
  )
}
export default withRouter(NavbarV2)
