import React from "react";
import { IRoute } from "./models/route";

const FeedView = React.lazy(()=>import("./view/Feed"))
const SignInView = React.lazy(()=>import("./view/SignIn"))
const AccountView = React.lazy(()=>import("./view/Account"))
const AudiobookView = React.lazy(()=>import("./view/Audiobook"))
const NotFoundView = React.lazy(()=>import("./view/404"))
const ResetPasswordView = React.lazy(()=>import("./view/ResetPassword"))
const PricesView = React.lazy(()=>import("./view/Prices"))
const CampaignView = React.lazy(()=>import("./view/Campaign"))
const ValidateView = React.lazy(()=>import("./view/ValidateView"))
const AuthorView = React.lazy(()=>import("./view/Author"))
const SearchMeiliDetails = React.lazy(()=>import("./components/searchMeiliDetails/SearchMeiliDetails"))
const CuratorView = React.lazy(()=>import("./view/Curator"))
const SignUpV2View = React.lazy(()=>import("./view/SignUpV2"))
const PaymentView = React.lazy(()=>import("./view/Payment"))
const DropView = React.lazy(()=>import("./view/DropView"))
const AppleAuth = React.lazy(()=>import("./view/AppleAuth"))
const DownloadView = React.lazy(()=>import("./view/Download"))
const LandingView = React.lazy(()=>import("./view/Landing"))
const OfferView = React.lazy(()=>import("./view/Offer"))
const OfferFailView = React.lazy(()=>import("./view/OfferFail"))
const CategoryView = React.lazy(()=>import("./view/Category"))
const PlaylistView = React.lazy(()=>import("./view/Playlist"))
const SectionView = React.lazy(()=>import("./view/Section"))
const PlaylistGroupView = React.lazy(()=>import("./view/PlaylistGroup"))

const routes:IRoute[] = [
  // {
  //   path: '/',
  //   isLoginRequire: false,
  //   title: 'Anyplay Audiobooks & Series - Unlimited Listening Across the World',
  //   isUsedInMenu: false,
  //   mode: 'exact',
  //   component: HomeView
  // },
  {
    path: '/',
    isLoginRequire: false,
    title: 'Anyplay Audiobooks & Series - Unlimited Listening Across the World',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    mode: 'exact',
    component: FeedView
  },
  {
    path: '/audiobooks',
    isLoginRequire: false,
    title: 'Anyplay Audiobooks & Series - Unlimited Listening Across the World',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    mode: 'exact',
    component: FeedView
  },
  {
    path: '/playlist/:id',
    isLoginRequire: false,
    title: 'Anyplay Audiobooks & Series - Unlimited Listening Across the World',
    isUsedInMenu: false,
    isIgnorePadding: true,
    isUseGradient: false,
    mode: 'strict',
    component: PlaylistView
  },
  {
    path: '/playlists/:id',
    isLoginRequire: false,
    title: 'Anyplay Audiobooks & Series - Unlimited Listening Across the World',
    isUsedInMenu: false,
    isIgnorePadding: true,
    isUseGradient: false,
    mode: 'strict',
    component: PlaylistView
  },
  {
    path: '/sections/:id',
    isLoginRequire: false,
    title: 'Anyplay Audiobooks & Series - Unlimited Listening Across the World',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    mode: 'strict',
    component: SectionView
  },
  {
    path: '/group-detail/:id',
    isLoginRequire: false,
    title: 'Anyplay Audiobooks & Series - Unlimited Listening Across the World',
    isUsedInMenu: false,
    isIgnorePadding: true,
    isUseGradient: false,
    mode: 'strict',
    component: PlaylistGroupView
  },
  {
    path: '/group-details/:id',
    isLoginRequire: false,
    title: 'Anyplay Audiobooks & Series - Unlimited Listening Across the World',
    isUsedInMenu: false,
    isIgnorePadding: true,
    isUseGradient: false,
    mode: 'strict',
    component: PlaylistGroupView
  },
  {
    path: '/audiobook/:id',
    isLoginRequire: false,
    title: 'Audiobook details',
    isUsedInMenu: false,
    isIgnorePadding: true,
    isUseGradient: true,
    mode: 'strict',
    component: AudiobookView
  },
  {
    path: '/audiobooks/:id',
    isLoginRequire: false,
    title: 'Audiobook details',
    isUsedInMenu: false,
    isIgnorePadding: true,
    isUseGradient: true,
    mode: 'strict',
    component: AudiobookView
  },
  {
    path: '/author/:id',
    isLoginRequire: false,
    title: 'Author details',
    isUsedInMenu: false,
    isIgnorePadding: true,
    isUseGradient: false,
    mode: 'strict',
    component: AuthorView
  },
  {
    path: '/authors/:id',
    isLoginRequire: false,
    title: 'Author details',
    isUsedInMenu: false,
    isIgnorePadding: true,
    isUseGradient: false,
    mode: 'strict',
    component: AuthorView
  },
  {
    path: '/category/:id',
    isLoginRequire: false,
    title: 'Category details',
    isUsedInMenu: false,
    isIgnorePadding: true,
    isUseGradient: false,
    mode: 'strict',
    component: CategoryView
  },
  {
    path: '/curators/:id',
    isLoginRequire: false,
    title: 'Curator details',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    mode: 'strict',
    component: CuratorView
  },
  {
    path: '/podcasts',
    isLoginRequire: false,
    title: 'Anyplay Audiobooks & Series - Unlimited Listening Across the World',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    mode: 'exact',
    component: FeedView
  },
  {
    path: '/podcasts/:id',
    isLoginRequire: false,
    title: 'Anyplay Audiobooks & Series - Unlimited Listening Across the World',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    mode: 'exact',
    component: FeedView
  },
  {
    path: '/episodes/:id',
    isLoginRequire: false,
    title: 'Anyplay Audiobooks & Series - Unlimited Listening Across the World',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    mode: 'exact',
    component: FeedView
  },
  {
    path: '/search_old',
    isLoginRequire: false,
    title: 'Anyplay Audiobooks & Series - Unlimited Listening Across the World',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    mode: 'exact',
    component: FeedView
  },
  {
    path: '/search',
    isLoginRequire: false,
    title: 'Anyplay Audiobooks & Series - Unlimited Listening Across the World',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    mode: 'exact',
    component: FeedView
  },
  {
    path: '/feed_sections/:id',
    isLoginRequire: false,
    title: 'Anyplay Audiobooks & Series - Unlimited Listening Across the World',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    mode: 'exact',
    component: FeedView
  },
  {
    path: '/search/audiobooks',
    isLoginRequire: false,
    title: 'Anyplay Audiobooks & Series - Unlimited Listening Across the World',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    mode: 'exact',
    component: SearchMeiliDetails
  },
  {
    path: '/search/narrator',
    isLoginRequire: false,
    title: 'Anyplay Audiobooks & Series - Unlimited Listening Across the World',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    mode: 'exact',
    component: SearchMeiliDetails
  },
  {
    path: '/search/year',
    isLoginRequire: false,
    title: 'Anyplay Audiobooks & Series - Unlimited Listening Across the World',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    mode: 'exact',
    component: SearchMeiliDetails
  },
  {
    path: '/search/publisher',
    isLoginRequire: false,
    title: 'Anyplay Audiobooks & Series - Unlimited Listening Across the World',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    mode: 'exact',
    component: SearchMeiliDetails
  },
  {
    path: '/search/language',
    isLoginRequire: false,
    title: 'Anyplay Audiobooks & Series - Unlimited Listening Across the World',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    mode: 'exact',
    component: SearchMeiliDetails
  },
  {
    path: '/search/playlists',
    isLoginRequire: false,
    title: 'Anyplay Audiobooks & Series - Unlimited Listening Across the World',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    mode: 'exact',
    component: SearchMeiliDetails
  },
  {
    path: '/search/authors',
    isLoginRequire: false,
    title: 'Anyplay Audiobooks & Series - Unlimited Listening Across the World',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    mode: 'exact',
    component: SearchMeiliDetails
  },
  {
    path: '/search/podcasts',
    isLoginRequire: false,
    title: 'Anyplay Audiobooks & Series - Unlimited Listening Across the World',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    mode: 'exact',
    component: SearchMeiliDetails
  },
  {
    path: '/for_you',
    isLoginRequire: false,
    title: 'Anyplay Audiobooks & Series - Unlimited Listening Across the World',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    mode: 'exact',
    component: FeedView
  },
  {
    path: '/your_authors',
    isLoginRequire: false,
    title: 'Anyplay Audiobooks & Series - Unlimited Listening Across the World',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    mode: 'exact',
    component: FeedView
  },
  // {
  //   path: '/ongoing',
  //   isLoginRequire: false,
  //   title: 'Anyplay Audiobooks & Series - Unlimited Listening Across the World',
  //   isUsedInMenu: false,
  //   mode: 'exact',
  //   component: FeedView
  // },
  {
    path: '/sign_up',
    isLoginRequire: false,
    title: 'Sign Up',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    mode: 'exact',
    component: SignUpV2View
  },
  {
    path: '/sign_in',
    isLoginRequire: false,
    title: 'Sign In',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    mode: 'exact',
    component: SignInView
  },
  {
    path: '/apple_auth',
    isLoginRequire: false,
    title: 'Apple Auth',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    mode: 'exact',
    component: AppleAuth
  },
  {
    path: '/payment',
    isLoginRequire: false,
    title: 'Payment',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    mode: 'exact',
    component: PaymentView
  },
  {
    path: '/payment/success',
    isLoginRequire: false,
    title: 'Payment',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    mode: 'exact',
    component: PaymentView
  },
  {
    path: '/account',
    isLoginRequire: true,
    title: 'Account',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    mode: 'exact',
    component: AccountView
  },
  {
    path: '/account/overview',
    isLoginRequire: true,
    title: 'profile_edit_title',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    mode: 'exact',
    component: AccountView
  },
  {
    path: '/account/edit',
    isLoginRequire: true,
    title: 'profile_account_change_name',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    mode: 'exact',
    component: AccountView
  },
  {
    path: '/account/change-password',
    isLoginRequire: true,
    title: 'profile_account_change_password',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    mode: 'exact',
    component: AccountView
  },
  {
    path: '/account/interests',
    isLoginRequire: true,
    title: 'Customize interests',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    mode: 'exact',
    component: AccountView
  },
  {
    path: '/account/socials',
    isLoginRequire: true,
    title: 'profile_login_Information',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    mode: 'exact',
    component: AccountView
  },
  {
    path: '/account/subscriptions',
    isLoginRequire: true,
    title: 'profile_subscription_management',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    mode: 'exact',
    component: AccountView
  },
  {
    path: '/account/listening_time',
    isLoginRequire: true,
    title: 'profile_consumption_title',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    mode: 'exact',
    component: AccountView
  },
  {
    path: '/account/languages',
    isLoginRequire: true,
    title: 'profile_settings_languages',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    mode: 'exact',
    component: AccountView
  },
  {
    path: '/not_found',
    isLoginRequire: false,
    mode: 'exact',
    title: 'Not found',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    component: NotFoundView
  },
  {
    path: '/reset_password',
    isLoginRequire: false,
    mode: 'exact',
    title: 'Reset Password',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    component: ResetPasswordView
  },
  {
    path: '/reset_password/:id',
    isLoginRequire: false,
    mode: 'strict',
    title: 'Reset Password',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    component: ResetPasswordView
  },
  {
    path: '/prices',
    isLoginRequire: false,
    mode: 'strict',
    title: 'cmn_anyplay_premium',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    component: PricesView
  },
  {
    path: '/campaign/:id',
    isLoginRequire: false,
    mode: 'strict',
    title: 'Anyplay Audiobooks & Series - Unlimited Listening Across the World',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    component: CampaignView
  },
  {
    path: '/offer/:id',
    isLoginRequire: false,
    mode: 'strict',
    title: 'Anyplay Audiobooks & Series - Unlimited Listening Across the World',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    component: OfferView
  },
  {
    path: '/fail',
    isLoginRequire: false,
    mode: 'strict',
    title: 'Anyplay Audiobooks & Series - Unlimited Listening Across the World',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    component: OfferFailView
  },
  {
    path: '/winback/:id',
    isLoginRequire: false,
    mode: 'strict',
    title: 'Anyplay Audiobooks & Series - Unlimited Listening Across the World',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    component: OfferView
  },
  {
    path: '/drop_password',
    isLoginRequire: false,
    mode: 'strict',
    title: 'Anyplay Audiobooks & Series - Unlimited Listening Across the World',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    component: DropView
  },
  {
    path: '/validate/:id',
    isLoginRequire: false,
    mode: 'strict',
    title: 'Anyplay Audiobooks & Series - Unlimited Listening Across the World',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    component: ValidateView
  },
  {
    path: '/download',
    isLoginRequire: false,
    mode: 'strict',
    title: 'Anyplay Audiobooks & Series - Unlimited Listening Across the World',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    component: DownloadView
  },
  {
    path: '/landings/:id',
    isLoginRequire: false,
    mode: 'strict',
    title: 'Anyplay Audiobooks & Series - Unlimited Listening Across the World',
    isUsedInMenu: false,
    isIgnorePadding: false,
    isUseGradient: false,
    component: LandingView
  }
]
export default routes
