import axios from 'axios';
import {AuthHeaders} from "../models/auth";
import {getAuthData, setAuthData} from '../utils/auth';
import AuthService from '../service/auth';
import {IFindawayError} from "../models/findaway";
import podcastindexHeaders from "../utils/podcastindexAuth";

const setAxiosHeader = (config:any) => {
  //let previewHeader: AuthHeaders | null = getAuthPreview()
  let authHeader: AuthHeaders | null = getAuthData();
  // console.log('setAxiosHeader -> config', config)
  // if(config.headers['access-token']) {
  //   if(config.headers['access-token'] !=='') {
  //     setAuthData(config.headers)
  //   }
  // } else {
  //   if(previewHeader && !authHeader) {
  //     config.headers['access-token'] = previewHeader['access-token'];
  //     config.headers['token-type'] = previewHeader['token-type'];
  //     config.headers['client'] = previewHeader.client;
  //     config.headers['expiry'] = previewHeader.expiry;
  //     config.headers['uid'] = previewHeader.uid;
  //   }
    if (authHeader && authHeader["access-token"] !== '') {
      config.headers['access-token'] = authHeader['access-token'];
      config.headers['token-type'] = authHeader['token-type'];
      config.headers['client'] = authHeader.client;
      config.headers['expiry'] = authHeader.expiry;
      config.headers['uid'] = authHeader.uid;
    }
  // }

  return config
}
let config:any = {headers:{}}
config = setAxiosHeader(config)
export {config}
export const authService = axios.create({
  baseURL: `${process.env.REACT_APP_API_V1}`,
})
export const authCheckService = axios.create({
  baseURL: `${process.env.REACT_APP_API_V1}`, headers: {
    "Authorization": "Basic YW55cGxheV9hZG1pbjp+LF45X2pna0pCRmtaY19f",
    "Content-Type": "application/json; charset=utf-8"
  }
})
export const anyplayV1 = axios.create({
  baseURL: `${process.env.REACT_APP_API_V1}`,...config,
})
export const anyplayV2 = axios.create({
  baseURL: `${process.env.REACT_APP_API_V2}`,...config,
})
export const anyplayV3 = axios.create({
  baseURL: `${process.env.REACT_APP_API_V3}`,...config,
})
export const anyplayV4 = axios.create({
  baseURL: `${process.env.REACT_APP_API_V4}`,...config,
})
export const anyplayPublicV1 = axios.create({
  baseURL: `${process.env.REACT_APP_API_PUBLIC_V1}`,...config,
})
export const anyplayPublicV2 = axios.create({
  baseURL: `${process.env.REACT_APP_API_PUBLIC_V2}`,...config,
})
export const anyplayPublicV3 = axios.create({
  baseURL: `${process.env.REACT_APP_API_PUBLIC_V3}`,...config,
})

export const podcastIndexV1 = axios.create({
  baseURL: `${'https://api.podcastindex.org/api/1.0/'}`,headers: {
    ...podcastindexHeaders
  }
})
const updateToken = (config: any) => {
  let headers = AuthService.getHeaders(config.headers)
  if(typeof headers['access-token'] !== 'undefined' && headers['access-token'] !=='') {
    if(headers['uid'] !== `${process.env.REACT_APP_PREVIEW_USER}`) {
      setAuthData(headers)
    }
    // else {
    //   setAuthPreview(headers)
    // }
  }
}
axios.interceptors.response.use(
  config => {
    if(config.status === 302) {
      updateToken(config)
    }
    return config;
  },
  error => onError(error)
)
anyplayV2.interceptors.request.use(
  config => {
    return setAxiosHeader(config);
  }
);
anyplayV2.interceptors.response.use(
  config => {
    updateToken(config)
    return config;
  },
  error => onError(error)
);
anyplayV3.interceptors.request.use(
  config => {
    return setAxiosHeader(config);
  }
);
anyplayV3.interceptors.response.use(
  config => {
    updateToken(config)
    return config;
  },
  error => onError(error)
);
authService.interceptors.response.use(
  config => {
    if(config.status === 302) {
      updateToken(config)
    }
    return config;
  },
  error => onError(error)
)
anyplayPublicV1.interceptors.response.use(
  config => {
    return config;
  },
  error => onError(error)
)

export const findawayService = axios.create({
  baseURL: `https://api.findawayworld.com/v4/`
})

export const stripeServiceV1 = axios.create({
  baseURL: `https://api.stripe.com/v1/`,
  headers: {
    'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
    Authorization: 'Bearer sk_test_W3adA74mbm2MCsTzbXW1wrtG00mBAr0HHB'
  }
})

export const rcServiceV1 = axios.create({
  baseURL: `https://api.revenuecat.com/v1/`,
  headers: {
    /// 'X-Platform': 'stripe',
   'content-type': "application/json",
    'authorization': 'Bearer obZXJUbDjmFuIIFtxQbcgthtuaZvpdvD'
  }
})
export const listennotesServiceV2 = axios.create({
  baseURL: `https://listen-api.listennotes.com/api/v2/`,
  headers: {
   'X-ListenAPI-Key': `${process.env.REACT_APP_LISTENNOTES_PK}`
  }
})
findawayService.interceptors.response.use(
  config => {
    return config;
  },
  error => onErrorFA(error)
)

export default function setup(history: any) {
  axios.defaults.baseURL = `${process.env.REACT_APP_API_V1}`;
  axios.interceptors.request.use(
    config => {
      return setAxiosHeader(config);
    }
  );
  axios.interceptors.response.use(
    config => {
      updateToken(config)
      return config;
    },
    error => onError(error, history)
  );
return axios
}
const onErrorFA = (error:any) => {
  try {
    if (typeof error.response !== 'undefined') {
      const customError:IFindawayError = error.response.data
      throw (customError);
    } else {
      throw new Error('Service unavailable');
    }
  } catch (e) {
    return Promise.reject(e);
  }
}

const onError = (error:any, history?: any) => {
  try {
    if (typeof error.response === 'undefined') {
      if (error.message === 'Network Error') {
        let customError = 'Service unavailable';
        throw new TypeError(customError);
      }
      // MEMO: in case of bad token
      // removeAuthData();
      // let customError = 'Invalid auth data';
      // history.push('/login')
      throw new TypeError('Service unavailable');
    } else {
      const {
        response: { status }
      } = error;
      let customError: any;
      switch (status) {
        case 400:
          customError = error.response.data.errors[0].details;
          break;
        case 401:
          customError = error.response.data.errors[0].details
          window.location.href = '/sign_in'
          // history.push('/login')
          break;
          case 403:
          customError = error.response.data.errors[0].detail
          //history.push('/not_found')
          break;
        case 404:
          customError = error.response.data.errors[0].details;
          window.location.href = '/not_found'
          //history.push('/not_found')
          break;
        case 422:
          customError = error.response.data.errors[0].details;
          break;
        case 451:
          customError = error.response.data.errors[0].status.title;
          break;
        default:
          customError = error.response.data.errors[0].details;
          break;
      }
      throw new Error(customError);
    }
  } catch (e) {
    return Promise.reject(e);
  }
}
