import {makeStyles} from "@material-ui/core/styles";
import {Button, Container, Grid, InputLabel, TextField, Typography} from "@material-ui/core";
import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {setIsPassStg} from "../../utils/localstorage/passStg";

const useStyles = makeStyles((theme)=>({

  drawerHeaderLocal: {
     display: 'flex',
     alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    height: '100vh',
    backgroundColor: 'black'
  },
}))
const StagingPassword:React.FC<{onValidPassword(): void}> = ({onValidPassword}) => {
  const classes = useStyles();
  const { register, errors, handleSubmit,setError  } = useForm()
  const [password, setPassword] = useState<string>('')
  const onChangeVal = (event: any) => {
    setPassword(event.target.value)
  }

  const { t } = useTranslation();
  const submitForm = () => {
    if(password ==='*j3zCxVDs#E3K+s7') {
      setIsPassStg(true)
      onValidPassword()
    } else {
      setError('password',{message:'Wrong password',type:'required'})
    }
  }
  return (
    <div className={classes.drawerHeaderLocal}>
      <Container
        maxWidth={false}
        component="form"
        onSubmit={handleSubmit(submitForm)}>
      <Grid container justifyContent={"center"}>
        <Grid item xs={12}>
          <Typography
            variant={"h6"}
            align={'center'}
            style={{padding: '20px 0'}}
          >
            <Typography variant={'h3'}>What is the password, Lebowski?</Typography>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} container spacing={3} >

          <Grid item xs={12} >
            <InputLabel
              shrink
              id="password"
              error={!!errors.password}>
              {t('auth_password')}
            </InputLabel>
            <TextField
              inputRef={register({
                required: true
              })}
              error={!!errors?.password}
              id="password"
              name="password"
              type={'password'}
              fullWidth
              onChange={onChangeVal}
              value={password}
            />
          </Grid>
          <Grid item xs={12} >
            <Button
              style={{width: '100%'}}
              variant={'contained'}
              type={'submit'}
              color="secondary">
              {t('Open')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      </Container>
    </div>
  )
}
export default StagingPassword
