import React, {useContext, useEffect} from "react";
import {ProfileContext} from "../../../context/profile/profileContext";
import {IMenuRoute} from "../../../models/route";
import {useMediaQuery, useTheme} from "@material-ui/core";

const usePlayerRoutes = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  const {profile} = useContext(ProfileContext)
  const [playerRoutes, setPlayerRoutes] = React.useState<IMenuRoute[]>([])

  const getPlayerRoutes = (isProfileNull: boolean):IMenuRoute[] => {
    let routes = [
      { path: '/', title: 'cmn_browse', disabled: false, group: 'home'},
      // { path: '/podcasts', title: 'Browse Podcasts', disabled: false, group: 'home'},
      // { path: '/podcasts', title: 'Browse Podcasts'},
      { path: '/search', title: 'cmn_search', disabled: false, group: 'home'},
      { path: '/for_you', title: 'cmn_for_you', disabled: isProfileNull , group: 'home'},
    ]

    if(matches) {
      const profileRoute: IMenuRoute = { path: '/account/overview', title: 'cmn_for_you', disabled: isProfileNull , group: 'home'}
      routes = [...routes, profileRoute]
     // routes.push(profileRoute)
    }
    // @ts-ignore
    return routes
  }

  useEffect(()=>{
    const playerRoutes:IMenuRoute[] = getPlayerRoutes( profile === null)
    setPlayerRoutes(playerRoutes)
    // eslint-disable-next-line
  },[profile])

  return {
    playerRoutes,
    getPlayerRoutes
  }
}
export default usePlayerRoutes
