import {createContext} from "react";
export interface IScrollState {
  scroll: number,
  setScroll:(scroll:number) => void
}
export const initState: IScrollState = {
  scroll: 0,
  setScroll:()=>{}
}
export const ScrollContext = createContext(initState)
