import React from "react";
import {Slide, useMediaQuery, useScrollTrigger, useTheme} from "@material-ui/core";

const HideOnScroll:React.FC<any> = ({children}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const trigger = useScrollTrigger();

  return (
    <Slide appear={true} direction="down" in={matches ? !trigger : true}>
      {children}
    </Slide>
  );
}
export default HideOnScroll
