import React, {useContext} from "react";
import {useEffect} from "react";
import {getProfileRC} from "../../utils/localstorage/profile";
import StripeService from "../../service/stripe";
import RCService from "../../service/revenuecat";
import {ProfileContext} from "../../context/profile/profileContext";
import {usePricesData} from "../../utils/hooks/usePricesData";
import {RouteComponentProps, withRouter} from "react-router-dom";

const PriceCatcher: React.FC<any &RouteComponentProps> = ({history}) =>{
  const {profile,rcData, userTerritory, setStripe, setRC, setIsUserHasPremium} = useContext(ProfileContext)

  const {getStripeData} = usePricesData()

  const getStripe = async () => {
    try {
      let data = await StripeService.getProfileStripe()
      setStripe(data)
    } catch (e) {
      console.log('getStripe -> e', e)
    }
  }
  const getRC = async () => {
    try {
      let data = await RCService.getSubscription()
      setRC(data)
    } catch (e) {
      console.log('getRC -> e', e)
    }
  }

  useEffect(() => {
    if(userTerritory) {
      getStripeData()
    }
    // eslint-disable-next-line
  },[userTerritory])
  useEffect(() => {
    if(profile && history.location.pathname !== '/payment/success') {
      getStripe()
    }
    if(profile) {
      const _tData = getProfileRC()
      if(!_tData) {
        getRC()
      } else {
        setRC(_tData)
      }
    }
    // if(profile) {
    //   if (profile.attributes.free_playback) {
    //     setAdminPremium()
    //   }
    // }
    // eslint-disable-next-line
  },[profile])
  useEffect(() => {
    if(rcData) {
      setIsUserHasPremium({rcData})
    }
    // eslint-disable-next-line
  },[rcData])

  return (
    <></>
  )
}
export default withRouter(PriceCatcher)
