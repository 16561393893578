import {createContext} from "react";
import {IPlayerState} from "./IPlayerState";
import {DEFAULT_PLAYER_SETTINGS} from "../../utils/localstorage/playerSettings";

export const initState: IPlayerState = {
  isManualPlay: false,
  isInterrupted: false,
  chapters: [],
  playerSettingsLocal: {...DEFAULT_PLAYER_SETTINGS},
  providerId: '',
  isBookmark: false,
  isPreview: false,
  isPaused: true,
  isAutoPlay: false,
  bookmarksGlobal: [],
  isNothingToPlay: true,
  content: {bookmarks: null, localBookmark: null, chapters: [],contentId: '', contentType: 'audiobook'},
  isBookMarkOpen: false,
  license: null,
  setChaptersGlobal: () => {},
  setPlayerSettingsLocal: ()=> {},
  setBookmarksGlobal: () => {},
  setProviderId: () => {},
  setContent: () => {},
  setIsBookmark: () => {},
  setIsAutoPlay: ()=>{},
  setIsPaused: () => {},
  setIsPreview: () => {},
  setIsBookMarkOpen: () => {},
  setLicense: () => {},
  setIsInterrupted: () => {},
  setIsManualPlay: () => {},
}

export const PlayerContext = createContext(initState)
