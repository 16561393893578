import { createTheme }from '@material-ui/core/styles';

const anyplayTheme = createTheme({
  typography: {
    fontFamily: [
      'Graphik',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: {
      light: '#737373',
      main: '#000',
      dark: '#000',
      contrastText: '#fff',
    },
    secondary: {
      light: '#3b44fc',
      main: '#0a16fc',
      dark: '#070fb0',
      contrastText: '#fff',
    },
  },
});
export default anyplayTheme
