import React, {useContext, useEffect} from "react";
import {IMenuRoute} from "../../models/route";
import {ProfileContext} from "../../context/profile/profileContext";
import {Box, List} from "@material-ui/core";
import RootMenu from "./RootMenu";
import usePlayerRoutes from "../../utils/hooks/tabs/usePlayerRoutes";

export const useMenuItems = () => {
  const {profile, isBillingIssue} = useContext(ProfileContext)
  const {playerRoutes} = usePlayerRoutes()
  const [activeTab, setActiveTab] = React.useState<string>('')
  const [activeRoutes, setActiveRoutes] = React.useState<IMenuRoute[]>([])
  const [allRoutes, setAllRoutes] = React.useState<IMenuRoute[]>([])


  const getPlayerRoutes = (isProfileNull: boolean):IMenuRoute[] => {
    return [
      { path: '/', title: 'cmn_browse', disabled: false, group: 'home'},
      // { path: '/podcasts', title: 'Browse Podcasts', disabled: false, group: 'home'},
      // { path: '/podcasts', title: 'Browse Podcasts'},
      { path: '/search', title: 'cmn_search', disabled: false, group: 'home'},
      { path: '/for_you', title: 'cmn_for_you', disabled: isProfileNull , group: 'home'},
    ]
  }

  const accountRoutes:IMenuRoute[] = [
    { path: '/account/overview', title: 'profile_edit_title', disabled: false, group: 'account'},
    { path: '/account/edit', title: 'profile_account_change_name', disabled: false, group: 'account'},
    { path: '/account/change-password', title: 'profile_account_change_email_and_password', disabled: false, group: 'account'},
    { path: '/account/languages', title: 'profile_settings_languages', disabled: false, group: 'account'},
    { path: '/account/interests', title: 'Customize interests', disabled: false, group: 'account'},
    { path: '/account/socials', title: 'profile_login_Information', disabled: false, group: 'account'},
    { path: '/account/subscriptions', title: 'profile_subscription_management', disabled: false, group: 'account',isNotificationActive:isBillingIssue},
    { path: '/account/listening_time', title: 'profile_consumption_title', disabled: false, group: 'account'},
  ]

  const homeRoutes: IMenuRoute[] = [
    { path: '/account/overview', title: 'profile_edit_title', disabled: false, group: 'home'},
    { path: '/', title: 'Web player', disabled: false, group: 'home'},
  ]

   useEffect(()=>{
     setAllRoutes([...accountRoutes, ...playerRoutes])
     // eslint-disable-next-line
   },[profile])
  const RenderSubMenu = () => (
    <Box display={{ xs: 'none', md: 'block' }} style={{position:'sticky', top: "3.3rem",}}>
      <List>
        {
          activeRoutes.map((route: IMenuRoute) => (
            <RootMenu
              route={route}
              activeTab={activeTab}
              key={route.path}/>
          ))
        }
      </List>
    </Box>
  )
  return {
    allRoutes,
    accountRoutes,
    playerRoutes,
    homeRoutes,
    setActiveTab,
    setActiveRoutes,
    RenderSubMenu
  }
}
