import React, {useContext, useEffect} from "react";
import {
  Container,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import BottomBanner from "../ads/BottomBanner";
import NavbarBottom from "../navbar/NavbarBottom";
import {ProfileContext} from "../../context/profile/profileContext";
import {ScrollContext} from "../../context/scroll/scrollContext";
import {PlayerContext} from "../../context/player/playerContext";
import FooterWrapper from "../navbar/FooterWrapper";
import AnyplayPlayerV4 from "../utils/AnyplayPlayerV4";
import ShowConditionally from "../utils/ShowConditionally";
import routes from "../../routes";

const useStyles = makeStyles((theme) => ({
  containerWrapperFull: {
    minHeight: 'unset',
    maxHeight: 'unset',
  },
  ignorePadding: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0',
      paddingRight: '0',
    }
  }
}))

const AnyplayApp:React.FC<{history:any}> = ({children,history})=> {
  const { profile } = useContext(ProfileContext)
  const {isNothingToPlay} = useContext(PlayerContext)
  const {setScroll} = useContext(ScrollContext)

  const [isFullHeight, setIsFullHeight] = React.useState<boolean>(false)
  const [isBottomNavbarRender, setIsBottomNavbarRender] = React.useState<boolean>(false)
  const [isBottomBannerRender, setIsBottomBannerRender] = React.useState<boolean>(false)
  const [isIgnorePadding, setIsIgnorePadding] = React.useState<boolean>(true)

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();

  const gridFocus = (e:any)=> {
    setScroll(e.target.scrollTop)
  }

  const detectBottomBannerRender = (pathname:string): boolean=> {
    return (
      pathname !== '/sign_up'
      && pathname !== '/sign_in'
      && pathname !== '/prices'
      && pathname !== '/payment/success'
      && pathname !== '/download'
      && pathname.substring(0, 15) !== '/reset_password'
      && pathname !== '/campaign/christmas2020'
      && history.location.pathname.substring(0,6) !== '/offer'
      && history.location.pathname.substring(0,8) !== '/winback'
    )
  }
  const detectBottomNavbarRender = (pathname:string): boolean=> {
    return (
      pathname !== '/payment'
      && pathname !== '/payment/success'
      && pathname !== '/sign_up'
      && pathname !== '/sign_in'
      && pathname !== '/prices'
      && pathname !== '/payment'
      && pathname.substring(0,15) !== '/reset_password'
      && pathname !== '/drop_password'
      && history.location.pathname.substring(0,6) !== '/offer'
      && history.location.pathname.substring(0,8) !== '/winback'
    )
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    if(history.location.pathname === '/sign_in'
      || history.location.pathname === '/sign_up'
      || history.location.pathname === '/reset_password'
      || history.location.pathname === '/'
      || history.location.pathname.substring(0,8) === '/account'
    ) {
      setIsFullHeight(true)
    } else {
      if(isFullHeight) {
        setIsFullHeight(false)
      }
    }
    // eslint-disable-next-line
  },[history.location.pathname])
  useEffect(() => {
    if(matches) {
      if(profile) {
        console.log(' -> matches', matches)
        const isRender = detectBottomNavbarRender(history.location.pathname)
        setIsBottomNavbarRender(isRender)
      }
    }
    else {
      setIsBottomNavbarRender(false)
    }
    // eslint-disable-next-line
  },[history.location.pathname, profile, matches])
  useEffect(() => {
    if(profile) {
      setIsBottomBannerRender(false)
    } else {
      const isRenderBanner = detectBottomBannerRender(history.location.pathname)
      setIsBottomBannerRender(isRenderBanner)
    }
    // eslint-disable-next-line
  },[history.location.pathname,profile])

  useEffect(() => {
      let route = routes.find(route => route.path.replace(':id', history.location.pathname.split('/')[2]) === history.location.pathname)
      // if(typeof route !== 'undefined') {
      //   if(route.isIgnorePadding) {
          setIsIgnorePadding(typeof route !== 'undefined' ? route.isIgnorePadding : false)
      //   } else{
      //     setIsIgnorePadding(false)
      //   }
      // } else {
      //   setIsIgnorePadding(false)
      // }
  },[history.location.pathname])

  return (
    <Container maxWidth={false} id={'scrollableDiv'} className={isIgnorePadding ? classes.ignorePadding : ''} onScroll={gridFocus}>
      <Grid container >
        <Grid
          item xs={12}
          className={[isFullHeight ? classes.containerWrapperFull : '' ].join(' ')}>
          {children}
        </Grid>

        <ShowConditionally isShow={!isNothingToPlay}>
          <FooterWrapper isStacked={isBottomNavbarRender}>
            <AnyplayPlayerV4/>
          </FooterWrapper>
        </ShowConditionally>

        <ShowConditionally isShow={isBottomNavbarRender}>
          <FooterWrapper>
            <NavbarBottom/>
          </FooterWrapper>
        </ShowConditionally>

        <ShowConditionally isShow={isBottomBannerRender}>
          <FooterWrapper>
            <BottomBanner/>
          </FooterWrapper>
        </ShowConditionally>
      </Grid>
    </Container>
  )
}
export default AnyplayApp
