import {HIDE_ALERT, SHOW_ALERT} from '../types'

const handlers = {
  [SHOW_ALERT]: (state: any, {payload}: any) => ({...payload, visible: true}),
  [HIDE_ALERT]: (state: any) => {
    return ({...state, visible: false});
  },
  DEFAULT: (state: any) => state
}

export const alertReducer = (state: any, action: { type: string | number; }) => {
  // @ts-ignore
  const handle = handlers[action.type] || handlers.DEFAULT
  return handle(state, action)
}
