import {getLocalStorageData, removeLocalStorageData, setLocalStorageData} from "./_root";
import {ITerritoryData} from "../../models/territory";

const getTerritoryData = (): ITerritoryData | null=> {
  return getLocalStorageData('anyplayTerritory')
};

const setTerritoryData = (data: ITerritoryData): void => {
  setLocalStorageData('anyplayTerritory', data)
};

const removeTerritoryData = (): void => {
  removeLocalStorageData('anyplayTerritory')
};

export {getTerritoryData, setTerritoryData, removeTerritoryData};
