import {
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Menu
} from "@material-ui/core";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import Fade from "@material-ui/core/Fade";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
import React, {useContext} from "react";
import {PlayerContext} from "../../../context/player/playerContext";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(()=>({
  customMenuItem: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
}))

const PlayerPlaylist:React.FC<{activeChapterIndex: number, onPlayChapter(index:number):void}>
  = ({activeChapterIndex, onPlayChapter}) => {
  const {isPaused, content} = useContext(PlayerContext)

  const classes = useStyles();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<EventTarget & HTMLButtonElement | null>(null);
  const [anchorElType, setAnchorElType] = React.useState<string | null>(null);

  const handleClick = (event:React.MouseEvent<HTMLButtonElement, MouseEvent>, type: 'playlist' | 'rate') => {
    setAnchorEl(event.currentTarget);
    setAnchorElType(type)
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
    setAnchorElType(null)
  }

  const onPlay = (index:number) => {
    onPlayChapter(index)
    handleCloseMenu()
  }
  return (
    <>
      <IconButton onClick={(event)=>handleClick(event,'playlist')}>
        <FormatListNumberedIcon/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorElType === 'playlist')}
        onClose={handleCloseMenu}
        TransitionComponent={Fade}

      >
        <List style={{ minWidth:'300px'}} className={'test'}>
          <ListItem>
            <ListItemAvatar>
              <>
                { content.contentType === 'audiobook' ? t('player_jump_to_chapter') : 'Jump to episode' } / {content.chapters.length}
              </>
            </ListItemAvatar>
            <ListItemSecondaryAction>
              Time
            </ListItemSecondaryAction>
          </ListItem>
          {content.chapters.map((chapter, index)=> (
            <ListItem

              className={[classes.customMenuItem, 'relative'].join(' ')}
              key={index+'_playerPlaylist'}
              onClick={()=>onPlay(index)}>

              <ListItemAvatar >
                <>
                  {content.contentType === 'podcast' ?
                    <img src={chapter.cover} alt=""
                         style={{width: '25px', position: 'absolute', top: '10px', zIndex: -1}}/>
                    : null}
                  {index === activeChapterIndex ?
                    isPaused ? <PlayCircleFilledIcon/> : <PauseCircleFilledIcon/>
                    : null}
                </>
              </ListItemAvatar>
              <ListItemText> {chapter.name}</ListItemText>
              <ListItemSecondaryAction>{chapter.singer} </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Menu>
    </>
  )
}
export default PlayerPlaylist
