export const fr = {
  "profile_edit_title": "Compte",
  "profile_edit_first_name": "Prénom",
  "profile_edit_last_name": "Nom de famille",
  "profile_edit_email": "E-mail",
  "profile_edit_membership_plan": "Plan d'adhésion",
  "profile_settings_languages": "Langues",
  "profile_settings_sign_out": "Déconnexion",
  "cmn_playlists": "Listes de lecture",
  "profile_account_change_email": "Changer l'e-mail",
  "profile_account_change_password": "Changer le mot de passe",
  "profile_account_change_name": "Changer le nom et la biographie du profil",
  "cmn_anyplay_premium": "Anyplay Plus",
  "profile_edit_save": "Sauvegarder",
  "profile_password_current": "Mot de passe actuel",
  "profile_password_new": "Nouveau mot de passe",
  "profile_password_confirmation": "Confirmer le nouveau mot de passe",
  "profile_email_new": "Nouvelle adresse courriel",
  "profile_password_send_link": "Envoyer un lien",
  "cmn_cancel": "Annuler",
  "profile_password_forgot": "Mot de passe oublié?",
  "profile_subscription_management": "Gérer les abonnements",
  "promo_sign_in": "Connexion",
  "promo_title_2": "Ecoutez autant que vous voulez",
  "auth_sign_up_for_books": "Inscrivez-vous pour des livres audio illimités",
  "auth_or": "OU",
  "auth_login_to_your_account": "Connectez-vous à votre compte",
  "auth_sign_up_password": "Choisissez votre mot de passe",
  "auth_create_account": "Créer un compte",
  "add_title_playlist_create": "Créer",
  "add_title_playlist_create_new": "Créer une nouvelle playlist",
  "add_title_playlist_enter_title": "Entrez un titre",
  "manage_playlist_remove": "Supprimer la playlist",
  "manage_playlist_make_public": "Rendre la playlist publique",
  "subscribe_offer_description": "Accès illimité à 200 000 livres audio avec de nouveaux lancements chaque jour.",
  "subscribe_try_it_free": "Essayez-le gratuitement",
  "title_detail_narrator": "Narrateur",
  "title_detail_language": "Langue",
  "title_detail_playtime": "Durée",
  "title_detail_year": "Année",
  "title_detail_publisher": "Éditeur",
  "title_detail_others_have_read": "D'autres ont lu",
  "player_jump_to_chapter": "Aller au chapitre",
  "player_add_to_playlist": "Ajouter à la playlist",
  "subscribe_go_premium": "Voulez-vous en savoir plus?",
  "subscribe_go_premium_message": "Déverrouillez tous les livres avec Anyplay Plus.",
  "player_preview": "Aperçu",
  "browse_ongoing_tab": "Livres en cours",
  "download_audiobook_delete": "Effacer",
  "search_discover_fiction": "Fiction",
  "search_discover_non_fiction": "Non-fiction",
  "search_authors": "Auteurs",
  "search_playlists": "Listes de lecture",
  "search_title_or_author": "Rechercher le titre ou l'auteur",
  "feed_new_for_you": "Nouveau pour vous",
  "cmn_for_you": "Votre bibliothèque\n",
  "cmn_browse": "Parcourir",
  "cmn_search": "Rechercher",
  "audiobooks_status_finished": "Livres finis",
  "player_play": "Reproduire",
  "subscribe_start": "Démarrer",
  "profile_add_language_title": "Ajouter une langue",
  "subscribe_cancel_anytime": "Annulez à tout moment\n",
  "subscribe_v3_full_access": "Accédez à plus de 200 000 livres",
  "subscribe_v3_read_as_much": "Lisez autant que vous le souhaitez",
  "subscribe_v3_stream_or_download": "Diffusez ou téléchargez hors ligne",
  "subscribe_v3_new_books_weekly": "Nouveaux livres ajoutés chaque semaine",
  "subscribe_v3_low_fee": "Frais mensuels bas",
  "subscribe_v3_no_ads": "Pas de pubs",
  "subscribe_v3_trusted_by": "Approuvé par les rat de bibliothèque du monde entier",
  "player_save_for_later": "Garder pour plus tard",
  "subscribe_v3_subscribe_now": "Abonnez-vous maintenant",
  "search_no_results": "Aucun résultat",
  "manage_playlist_make_private": "Rendre la playlist privée",
  "cmn_anyplay_free": "Anyplay gratuit",
  "browse_audiobooks_tab": "Livres audio",
  "title_detail_unavailable_territory": "Nous sommes désolés, ce livre a récemment été retiré par l'éditeur de votre territoire",
  "promo_continue_with_google": "Continuez avec Google\n",
  "promo_sign_up_with_email": "Inscrivez-vous gratuitement par e-mail",
  "promo_continue_with_facebook": "Continuez avec Facebook",
  "auth_whats_your_email": "Quel est votre e-mail?\n",
  "auth_you_need_to_confirm_email": "Vous devrez confirmer cet e-mail plus tard.",
  "auth_email_already_registered": "Cet e-mail est déjà connecté à un compte",
  "auth_do_you_want_to_login_instead": "Voulez-vous vous connecter à la place?",
  "auth_whats_your_name": "Quel est ton nom?",
  "auth_name_appears_on_your_profile": "Cela apparaît sur votre profil Anyplay.",
  "auth_name_placeholder": "Camille Martin",
  "auth_password": "Mot de passe",
  "auth_register": "S'inscrire",
  "subscribe_v4_books_all_genres": "200.000+ livres dans tous les genres",
  "subscribe_v4_start_free_trial": "Commencer l'essai gratuit\n",
  "action_share_book": "Partager le livre\n",
  "subscribe_download_go_premium": "Déverrouillez cette fonctionnalité\n",
  "auth_short_password_error_message": "Le mot de passe est trop court. Le minimum est de 6 caractères.",
  "auth_signup": "S'inscrire",
  "auth_email_fill_error_message": "Veuillez remplir l'e-mail",
  "player_track": "Piste",
  "search_more_audiobooks": "Voir tous les livres",
  "search_more_authors": "Voir tous les auteurs",
  "search_more_playlists": "Voir toutes les playlists",
  "playlist_share": "Partager",
  "player_saved_for_later": "Gardé pour plus tard",
  "title_detail_rating": "Notation",
  "cmn_edit": "Éditer",
  "review_rating_title": "Comment avez-vous aimé ce livre ?",
  "review_comment_title": "Décrivez-le avec vos propres mots",
  "review_title": "Notes et commentaires",
  "playlist_edit_title": "Modifier la liste de lecture",
  "title_more_from_author": "Plus de cet auteur",
  "title_mark_finished": "Marquer le livre comme terminé",
  "title_rate_and_comment": "Notez et commentez",
  "title_download": "Télécharger",
  "profile_settings_help_center": "Centre d'aide",
  "language_preferred_order": "Ordre de langue préféré",
  "profile_settings_terms_of_service": "Conditions d'utilisation",
  "profile_login_Information": "Informations de connexion",
  "profile_account_change_avatar": "Changer la photo de profil",
  "profile_account_reset_password": "réinitialiser le mot de passe",
  "profile_account_reset_password_send": "Un lien de réinitialisation du mot de passe a été envoyé à",
  "profile_account_passords_not_match_alert": "Le nouveau mot de passe et le mot de passe de confirmation ne correspondent pas",
  "profile_account_display_name": "Afficher le nom",
  "profile_account_biography": "Biographie",
  "profile_account_display_name_description": "(si vous partagez des listes de lecture, ce sera votre nom public)",
  "profile_account_biography_description": "(texte court pour décrire votre profil d'utilisateur public)",
  "profile_add_email_login": "Ajouter une connexion par e-mail",
  "profile_add_email_login_to_unlink": "Pour dissocier votre compte, veuillez d'abord ajouter une connexion par e-mail.\nCela garantira que vous ne perdez pas vos données Anyplay.",
  "profile_signin_with_apple": "Connectez-vous avec Apple",
  "profile_signin_with_facebook": "Connectez-vous avec Facebook",
  "ongoing_placeholder_title": "Vous n'avez commencé aucun livre",
  "ongoing_placeholder_subtitle": "Appuyez sur jouer sur n'importe quel livre et il apparaîtra ici",
  "paywall_read_more_with_unlimited_access": "En savoir plus avec un accès illimité",
  "paywall_start_your_free_trial": "Commencez votre essai gratuit de 7 jours",
  "period_unit_month": "mois",
  "period_unit_year": "année",
  "period_unit_months": "mois",
  "subscription_alert_button": "7 jours gratuits",
  "subscription_alert_skip_trial": "Passer l'essai",
  "auth_next": "Suivant",
  "profile_consumption_total_label": "Total",
  "profile_consumption_this_month_label": "Ce mois-ci",
  "profile_consumption_title": "Temps d'écoute",
  "playlists_see_all": "Voir tout",
  "subscribe_billing_issue_android": "Mettre à jour les informations de paiement dans Google Play Store",
  "subscribe_billing_issue_ios": "Mettre à jour les informations de paiement dans l'App Store",
  "subscribe_billing_issue_header": "Problème de facturation détecté",
  "finished_placeholder_title": "Vous n'avez terminé aucun livre",
  "saved_placeholder_title": "Vous n'avez enregistré aucun livre",
  "profile_edit_interface_language": "Définir la langue de l'interface",
  "subscribe_save_discount_web": "Économisez",
  "auth_terms_web": "En créant un compte, vous acceptez les <1>Conditions d'utilisation d'Anyplay</1>. Pour savoir comment Anyplay collecte, utilise et protège vos données personnelles, lisez la <3>Politique de confidentialité d'Anyplay</3>.",
  "cmn_anyplay_free_description": "Lire tous les podcasts et livres audio limités",
  "cmn_select": "Sélectionner",
  "prices_read_without_limits": "Lisez sans limites",
  "prices_choose_your_plus": "Choisissez votre Plus",
  "prices_other_plans": "Autres plans",
  "download_app_use_our_player": "ou utilisez notre <1>lecteur web</1>",
  "promo_continue_with_apple": "Continuez avec Apple",
  "profile_password_reset_message_web": "Nous enverrons un e-mail à {{email}} avec un lien afin de réinitialiser votre mot de passe. Si vous ne pouvez pas accéder à ce compte de messagerie, veuillez contacter le service client.",
  "profile_signin_with_google": "Connectez-vous avec Google",
  "auth_sign_in_remember_me": "Se souvenir de moi",
  "profile_settings_version_web": "Version:",
  "auth_sign_in_no_account": "N'avez vous pas de compte ?",
  "auth_signup_already_user": "Avez-vous déjà un compte ?\n",
  "banner_preview_title": "Ceci est un aperçu d'Anyplay",
  "profile_consumption_total_web": "Vous avez lu {{books}} livres et écouté {{hours}} heures depuis que vous avez commencé à utiliser Anyplay",
  "profile_consumption_this_month_web": "Vous avez commencé {{books}} livres et écouté {{hours}} heures ce mois-ci",
  "title_mark_ongoing": "Retour aux livres commencés"
}
