import React from "react";
import {Dialog, DialogContent, Grid, Typography, useMediaQuery, useTheme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import PreviewEndWithLoginActionRows from "./PreviewEndWithLoginActionRows";
import PreviewEndWithLoginActionCols from "./PreviewEndWithLoginActionCols";
const useStyles = makeStyles((theme)=>({
  dialogWrapper: {
    // width: '100%',
    // margin: '0',

  },
  modalBg: {
    borderRadius: '20px',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '312px'
    },
    padding: '0',
    background: '#292929'
  },
  customPadding:{
    padding: '12px 0px'
  },
  headerPadding:{
    padding: '7px'
  }
}))
const PreviewEndWithLogin:React.FC<{isOpen:boolean, onCloseModal: any}>
  = ({isOpen,onCloseModal}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog
      maxWidth={'xs'}
      fullWidth={matches}
      className={classes.dialogWrapper}
      aria-labelledby="preview-dialog-title"
      open={isOpen}>
      {/*//*/}
      <DialogContent dividers className={classes.modalBg}>
        <Grid container >
          <Grid item xs={12} className={classes.headerPadding}>
            <Typography variant={'h6'} align={'center'}>
              {t('subscribe_go_premium')}
            </Typography>
            <Typography variant={'subtitle2'} align={'center'}>
              {t('subscribe_go_premium_message')}
            </Typography>
          </Grid>

          {
            t('subscription_alert_button').length > 14 ?
            <PreviewEndWithLoginActionRows
              textBtnPay={t('subscription_alert_button')}
              textBtnClose={t('subscription_alert_skip_trial')}
              onCloseModal={onCloseModal}
            />
              :
              <PreviewEndWithLoginActionCols
                textBtnPay={t('subscription_alert_button')}
                textBtnClose={t('subscription_alert_skip_trial')}
                onCloseModal={onCloseModal}
              />
          }
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
export default PreviewEndWithLogin
