import {useContext} from "react";
import {PriceContext} from "../../context/price/priceContext";

export const usePrices = () => {
  const {prices,setStripePriceFull} = useContext(PriceContext)
  const setFullPrice = (priceId: string) => {
    let tmp = prices.filter(price=> price.id === priceId)[0]
    setStripePriceFull(tmp)
  }
  const setFullPriceByProduct = (productId: string) => {
    let tmp = prices.filter(price=> price.product === productId)[0]
    setStripePriceFull(tmp)
  }

  return {
    setFullPrice,
    setFullPriceByProduct
  }
}
