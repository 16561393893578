import {Duration} from "luxon";
/**
 * Formatter for player and licenses playback
 * @duration un formatted duration in milliseconds
 * @isMinutesOnly  flag to choose between HH[h] mm[m] ss[s] or m formats
 * @returns - duration in a proper format
 */
const getDurationString = (data:{duration:string | number | undefined, isMinutesOnly:boolean}):string => {
  let {duration,isMinutesOnly} = data
  let durationStr = ''
  if(!Number.isNaN(duration)) {
    if(typeof duration !== 'undefined') {
      if(typeof duration === 'string') {
        duration = parseInt(duration)
      }
      // MEMO: more, than hour
      if(duration > 3600000) {
        //durationStr = moment().startOf('days').milliseconds(duration).format(isMinutesOnly ? 'm': 'HH[h] mm[m] ss[s]')
        durationStr = Duration.fromMillis(duration).toFormat(isMinutesOnly ? 'm': "hh'h' mm'm' ss's'")
      } else {
        // durationStr = moment().startOf('day').milliseconds(duration).format(isMinutesOnly ? 'm': 'mm[m] ss[s]')
        durationStr = Duration.fromMillis(duration).toFormat(isMinutesOnly ? 'm': "mm'm' ss's'")
      }
    } else {
      durationStr = '---'
    }
  } else {
    durationStr = '---'
  }
  return durationStr
}
export default getDurationString
