import InfoIcon from "@material-ui/icons/Info";
import React from "react";

const IconCDN:React.FC<{onSetIsOpenPlayerAudioInfo(isOpenAudioInfo: boolean):void}>
  = ({onSetIsOpenPlayerAudioInfo}) => {
  return (
    <InfoIcon onClick={()=>onSetIsOpenPlayerAudioInfo(true)} style={{margin:'0 10px'}}/>
  )
}
export default IconCDN
