import React, {useReducer} from "react";
import {ReactJkMusicPlayerAudioListProps} from "react-jinke-music-player";
import {playerReducer} from "./playerReducer";
import {initState, PlayerContext} from "./playerContext";
import {
  SET_CHAPTERS,
  SET_PROVIDER_ID,
  SET_IS_BOOKMARK,
  SET_BOOKMARKS,
  SET_IS_PAUSED,
  SET_IS_AUTOPLAY,
  SET_IS_PREVIEW,
  SET_CONTENT,
  SET_IS_NOTHING_TO_PLAY,
  SET_IS_BOOKMARK_OPEN,
  SET_LICENSE,
  SET_PLAYER_SETTINGS,
  SET_IS_INTERRUPTED, SET_IS_MANUAL_PLAY
} from "../types";
import {setPlayerChapters} from "../../utils/localstorage/playerChapters";
//import {setPlayerBookmarks} from "../../utils/localstorage/playerBookmarks";
import {IAudiobookBookmark, IAudiobookLicense, IStatLicenses} from "../../models/audiobook";
import {IPlaybackContent} from "./IPlayerState";
import {setPlayerContent} from "../../utils/localstorage/playerContent";
import {IPlayerSettings} from "../../models/player";
import {setPlayerSettings} from "../../utils/localstorage/playerSettings";

export const PlayerState:React.FC = ({children}) => {
  const [state,dispatch] = useReducer(playerReducer, initState);
  const setChaptersGlobal = (payload:ReactJkMusicPlayerAudioListProps[]) => {
    setPlayerChapters(payload)
    dispatch({type:SET_CHAPTERS, payload})
  }
  const setBookmarksGlobal = (payload:IAudiobookBookmark) => {
    dispatch({type:SET_BOOKMARKS, payload})
  }
  const setProviderId = (payload:string) => {
    dispatch({type:SET_PROVIDER_ID, payload})
  }
  const setContent = (payload:IPlaybackContent) => {
    setPlayerContent(payload)
    dispatch({type:SET_CONTENT, payload})
    dispatch({type:SET_IS_NOTHING_TO_PLAY, payload: payload.chapters.length === 0})
  }
  const setIsBookmark = (payload:boolean) => {
    dispatch({type:SET_IS_BOOKMARK, payload})
  }
  const setIsPaused = (payload:boolean) => {
    dispatch({type:SET_IS_PAUSED, payload})
  }
  const setIsAutoPlay = (payload:boolean) => {
    dispatch({type:SET_IS_AUTOPLAY, payload})
  }
  const setIsPreview = (payload:boolean) => {
    dispatch({type:SET_IS_PREVIEW, payload})
  }
  const setIsBookMarkOpen = (payload:boolean) => {
    dispatch({type:SET_IS_BOOKMARK_OPEN, payload})
  }
  const setIsInterrupted = (payload:boolean) => {
    dispatch({type:SET_IS_INTERRUPTED, payload})
  }
  const setIsManualPlay = (payload:boolean) => {
    dispatch({type:SET_IS_MANUAL_PLAY, payload})
  }
  const setLicense = (payload:IStatLicenses | IAudiobookLicense | null) => {
    dispatch({type:SET_LICENSE, payload})
  }
  const setPlayerSettingsLocal = (payload:IPlayerSettings) => {
    dispatch({type:SET_PLAYER_SETTINGS, payload})
    setPlayerSettings(payload)
  }

  return (
    <PlayerContext.Provider value={{
      isManualPlay: state.isManualPlay,
      isInterrupted: state.isInterrupted,
      chapters: state.chapters,
      playerSettingsLocal: state.playerSettingsLocal,
      providerId: state.providerId,
      isBookmark: state.isBookmark,
      isPaused: state.isPaused,
      isAutoPlay: state.isAutoPlay,
      isPreview: state.isPreview,
      bookmarksGlobal: state.bookmarksGlobal,
      content: state.content,
      isNothingToPlay: state.isNothingToPlay,
      isBookMarkOpen: state.isBookMarkOpen,
      license: state.license,
      setChaptersGlobal,
      setIsBookmark,
      setBookmarksGlobal,
      setContent,
      setIsPaused,
      setIsAutoPlay,
      setIsPreview,
      setIsBookMarkOpen,
      setLicense,
      setProviderId,
      setPlayerSettingsLocal,
      setIsInterrupted,
      setIsManualPlay
    }}>
      {children}
    </PlayerContext.Provider>
  )
}
