import {getLocalStorageData, removeLocalStorageData, setLocalStorageData} from "./_root";

const getIsPassStg = (): boolean | null => {
  return getLocalStorageData('anyplayIsPassStg')
}
const setIsPassStg = (data:boolean): void => {
  setLocalStorageData('anyplayIsPassStg', data)
};

const removeIsPassStg = (): void => {
  removeLocalStorageData('anyplayIsPassStg')
};

export { getIsPassStg, setIsPassStg, removeIsPassStg }
