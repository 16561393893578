import React, {useContext, useEffect, useState} from "react";
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";

import {
  Accordion,
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  AccordionSummary,
  Typography,
  AccordionDetails,
  FormControl,
  InputAdornment,
  FilledInput
} from "@material-ui/core";
import StripeService from "../../service/stripe";
import {loadStripe, StripeCardElement} from "@stripe/stripe-js";
import {ProfileContext} from "../../context/profile/profileContext";
import {IProfileStripe} from "../../models/stripe";
import {useIsLoading} from "../../utils/hooks/useIsLoading";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {RouteComponentProps, useHistory, withRouter} from "react-router-dom";
import {AlertContext} from "../../context/alert/alertContext";
import CheckIcon from "@material-ui/icons/Check";
import BlockIcon from '@material-ui/icons/Block';
import {ReactComponent as IconAccordion} from "../../assets/accordion_questionnaire.svg";
import {PriceContext} from "../../context/price/priceContext";
import PublicPaymentsService from "../../service/public/payments";
import ShowConditionally from "../utils/ShowConditionally";

// const CARD_OPTIONS = {
//   iconStyle: 'solid',
//   style: {
//     base: {
//       iconColor: '#c4f0ff',
//       color: '#fff',
//       fontWeight: '500',
//       fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
//       fontSize: '16px',
//       fontSmoothing: 'antialiased',
//       ':-webkit-autofill': {color: '#fce883'},
//       '::placeholder': {color: '#87bbfd'},
//     },
//     invalid: {
//       iconColor: '#ffc7ee',
//       color: '#ffc7ee',
//     },
//   },
// };
const useStyles = makeStyles((theme)=>({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  accordionDetails: {
    display: 'block',
    background: '#3d3d3d',
    borderRadius:' 5px',
    marginBottom: '10px'
  },
  accordionDetailsLast: {
    marginBottom: '40px'
  },
  accordionDetailsLabel: {
    color: 'black !important'
  },
  input: {
    background: 'white',
    color: 'black !important',
    padding: '10px'
  },
  accordionContainer: {
    background: '#232323',
  },
  accordionSummary: {
    minHeight: '0 !important',
    padding: '0 !important'
  },
  accordionHeader: {
    color: '#6694fc !important',
    background: '#232323',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  accordionText : {
    fontSize: '0.8rem'
  },
  customButtonText: {
    background: 'gray',
    padding: '10px',
    borderRadius: '20px',
    textAlign: 'center',
    color: 'white !important',
    fontWeight: 600,
    textTransform: 'uppercase',
    '&:hover': {
      color: 'white !important',
      background: '#0a16fc'
    },
    '&:disabled': {
      cursor: 'not-allowed',
      background: 'gray',
      '&:hover': {
        cursor: 'not-allowed',
      },
    }

  },
}))
const ads = [
  { title: 'Verify your account' },
  { title: 'Secure that you have uninterrupted listening after your free trial period' },
]
const PlansPay:React.FC<{priceId: string, onCancelPay: any, giftCode?: string}&RouteComponentProps>
= ({priceId, onCancelPay, giftCode}) => {
  const {push} = useHistory()
  const classes = useStyles();
  loadStripe(`${process.env.REACT_APP_STRIPE_PK}`);

  const {isTrial} = useContext(PriceContext)
  const { t } = useTranslation();
  const {stripeData, setStripe, isUserHasPremium} = useContext(ProfileContext)
  const {isLoading, loadingHandler} = useIsLoading()
  const {isLoading:isLoadingCoupon, loadingHandler:loadingHandlerCoupon} = useIsLoading()
  const alert = useContext(AlertContext)
  const stripe = useStripe();
  const [codeValidation, setCodeValidation] = useState<'' | 'valid' | 'invalid'>('')
  const [coupon, setCoupon] = useState<string>('')
  const elements = useElements();

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    let card = elements.getElement(CardElement) as StripeCardElement

    // @ts-ignore
    if(!card._complete) {
      return
    }
      loadingHandler(true)
      try {
        const subs = await StripeService.setupIntent()
          if(subs) {
            stripe
              .confirmCardSetup(subs.client_secret, {
                payment_method: {
                  card: card,
                },
              })
              .then(async function(result) {
                let data:{payment_method_id:string, price_id: string, trial:boolean,coupon?: string}
                  = {price_id: priceId, trial: isTrial, payment_method_id: result.setupIntent?.payment_method as string }
                if(coupon !== '') {
                  data.coupon = coupon
                }
                const subs = await StripeService.createSubscribe({...data})
                let stripeDataTmp:IProfileStripe = {...stripeData as IProfileStripe }
                stripeDataTmp.attributes.subscription_id = subs.id
                setStripe(stripeDataTmp)
                push('/')
                loadingHandler(false)
                onCancelPay()
                alert.show(t('Welcome to Anyplay! You are now a premium user!','success'))
              }).catch(function(e){
              console.log(' -> catch', e)
            });
          }
      } catch (e) {
        loadingHandler(false)
        alert.show('Your card has insufficient funds', 'danger')
      }
  };
  const startCheckCoupon = async () => {
    loadingHandlerCoupon(true)
    try {
      const data = await PublicPaymentsService.checkPromoCode(coupon)
      if(data.data.length !== 0) {
        let couponObj = data.data.filter(promo=>promo.code === coupon)[0]
        if(couponObj) {
          setCoupon(couponObj.coupon.id)
          setCodeValidation('valid')
        } else {
          setCodeValidation('invalid')
        }
      } else {
        setCodeValidation('invalid')
      }
      loadingHandlerCoupon(false)
    } catch (e) {
      setCodeValidation('invalid')
      loadingHandlerCoupon(false)
    }
  }
  useEffect(() => {
    if(coupon !== '') {
      let timeOutId: any = null
      if (isLoadingCoupon) {
        return undefined;
      }
      if(!isLoadingCoupon) {
        timeOutId = setTimeout(() => {
          startCheckCoupon()
        },500)
      }
      return () => clearTimeout(timeOutId)
    } else {
      setCodeValidation('')
    }
    // eslint-disable-next-line
  }, [coupon]);
  useEffect(() => {
    if(giftCode) {
      setCoupon(giftCode)
    }
  },[giftCode])
  return (
    <>
    <form onSubmit={handleSubmit}>

      <Grid container alignItems={'stretch'} spacing={0}>
        <Grid item xs={12}>
          <CardElement />
        </Grid>
        <Grid item>
          <Accordion
            square
            className={classes.accordionContainer}>
            <AccordionSummary
              aria-expanded={'true'}
              className={classes.accordionSummary}
              aria-controls="panel1d-content"
              id="panel1d-header">
              <Typography
                variant={'subtitle2'}
                className={[classes.accordionHeader, 'flexCenter'].join(' ')}>
                <IconAccordion style={{marginRight: '5px'}}/>
                {t('Why we need your details')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <Typography className={classes.accordionText}>
                {t('Your card details are used to')}
              </Typography>
                {ads.map((ad,index) => (
                  <Typography
                    className={['flexCenter', classes.accordionText].join(' ')}
                    key={index}>
                      <CheckIcon style={{color:'#12C77E', paddingRight:'5px'}}/>
                      {t(ad.title)}
                  </Typography>
                ))}
                <br/>
              <Typography className={classes.accordionText}>
                {t('You can cancel your plan anytime and no charge on your card will be made during the trial period')}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
        {
          typeof giftCode === 'undefined' ?
            <Grid item xs={12}>
              <Accordion
                className={classes.accordionContainer}
                square>
                <AccordionSummary
                  className={classes.accordionSummary}
                  aria-expanded={'true'}
                  aria-controls="panel2d-content"
                  id="panel2d-header">
                  <Typography variant={'subtitle2'} className={classes.accordionHeader}>
                    {t('Do you have a gift code')}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={[classes.accordionDetails, classes.accordionDetailsLast].join(' ')}>
                  <Typography className={classes.accordionText}>
                    {t('Redeem your code here')}
                  </Typography>
                  <FormControl fullWidth>
                    <FilledInput
                      id="standard-adornment-amount"
                      autoComplete={'off'}
                      style={{background: 'white'}}
                      onChange={(event)=>setCoupon(event.target.value)}
                      inputProps={{
                        className: classes.input,
                      }}
                      endAdornment={
                        <InputAdornment position="start" className={ classes.input}>
                          {codeValidation === 'valid' ? <CheckIcon style={{color: '#12C77E'}}/> :
                            codeValidation === 'invalid' ? <BlockIcon style={{color: '#db4949'}}/> : null}
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </AccordionDetails>
              </Accordion>
            </Grid>
            : null
        }
        <br/>
        <ShowConditionally isShow={!isUserHasPremium}>
          <Grid item xs={12}>
            <Button
              type="submit"
              color={"secondary"}
              className={[classes.customButtonText,'w100'].join(' ')}
              variant={'contained'}
              disabled={!stripe || codeValidation === 'invalid' || isLoadingCoupon}>
              {isTrial ? t('Try it free') : t('Subscribe')}
            </Button>
          </Grid>
        </ShowConditionally>
      </Grid>
    </form>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
export default withRouter(PlansPay)
