import symbol from "@brixtol/currency-symbols";
const getNavigatorLanguage = () => (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language  || 'en';
const currencyFormatter = (data:{price: number,currency: string,minimumFractionDigits?:number }) => {
  const {price,currency, minimumFractionDigits} = data
  const locale = getNavigatorLanguage()
  let priceWithCurrency = new Intl.NumberFormat(locale,
    {
      style: 'currency',
      currencyDisplay: 'symbol',
      currency: currency,
      maximumFractionDigits: 2,
      minimumFractionDigits: typeof minimumFractionDigits === 'undefined' ? 0 : minimumFractionDigits
    }).format(price);

  priceWithCurrency = priceWithCurrency.replace(currency.toLocaleUpperCase(), symbol(currency))

  return priceWithCurrency
}
export {currencyFormatter,getNavigatorLanguage}
