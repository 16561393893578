import {SET_GRADIENT} from '../types'
import {IGradientState} from "./gradientContext";

const handlers = {
  [SET_GRADIENT]: (state:IGradientState, payload:{payload: string, type: 'SET_GRADIENT'}) => ({...state, gradientTo: payload.payload}),
  DEFAULT: (state: any) => state
}

export const gradientReducer = (state: any, action: { type: string, payload?: any  }) => {
  // @ts-ignore
  const handle = handlers[action.type] || handlers.DEFAULT
  return handle(state, action)
}
