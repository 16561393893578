import React, {useContext, useEffect} from "react"
import {Backdrop, Button, CircularProgress, Divider, Grid, Typography} from "@material-ui/core";
// @ts-ignore
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import {ReactComponent as FBIcon} from "../../assets/facebook.svg";
import {ReactComponent as GoogleIcon} from "../../assets/google.svg";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {AuthHeaders, AuthInfo, IFacebook, IGoogleAuth} from "../../models/auth";
import {userMinHeight, userMinWidth} from "../../utils/dimentions";
import getCroppedImg from "../../utils/cropImage";
import URLtoFromData from "../../utils/urlToFromData";
import AuthService from "../../service/auth";
import {setAuthData} from "../../utils/auth";
import {setProfileData} from "../../utils/localstorage/profile";
import UserService from "../../service/user";
import {AlertContext} from "../../context/alert/alertContext";
import {ProfileContext} from "../../context/profile/profileContext";
import {useIsLoading} from "../../utils/hooks/useIsLoading";
import SignUpMethodsEmail from "./SignUpMethodsEmail";
import SignUpMethodsPassword from "./SignUpMethodsPassword";
import ReactPixel from "../../plugins/some";
import ModalOnAuth from "../utils/ModalOnAuth";
import {NavLink, RouteComponentProps, useLocation, withRouter} from "react-router-dom";
import {useGTMWrapper} from "../../utils/hooks/useGTMWrapper";
import SignUpMethodsName from "./SignUpMethodsName";
import AppleLogin from "react-apple-login";
import {ReactComponent as AppleIcon} from "../../assets/apple-logo.svg";
import {DateTime} from "luxon";
import SubscribeService from "../../service/subscribe";
// import AppleSignin from 'react-apple-signin-auth';
// import axios from "axios";
import { GoogleLogin } from '@react-oauth/google';

const useStyles = makeStyles((theme)=>({
  subHeader: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem',
    },
  },
  customPadding: {
    padding: '0 12px !important'
  },
  customPaddingLast: {
    padding: '0 12px 12px 12px !important'
  },
  iconBtn: {
    width: '100%',
    fontWeight: 700,
    color: '#fff',
    cursor: 'pointer',
    display: 'inline-block',
    position: 'relative',
    textDecoration: 'none',
    textTransform: 'uppercase',
    transition: 'background-color .3s,border-color .3s',
    backgroundColor: '#4c69ba',
    border: 'none',
    padding: '10px',
    borderRadius: '20px',
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.9
    }
  },
  googleBtn: {
    fontWeight: 700,
    border: 'none',
    backgroundColor: '#FFF',
    color: 'black',
    textTransform: 'uppercase',
    padding: '10px',
    borderRadius: '20px',
    position: 'relative',
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.9
    }
  },
  textSeparator: {
    margin: '0px auto',
    padding: '0 10px',
    position: 'absolute',
    top: '0',
    background: 'black',
    left: '45%'
  },
  bannerButtonText: {
    background: '#5A5A5E',
    borderRadius: '20px',
    textAlign: 'center',
    color: 'white',
    fontSize: '0.875rem',
    textTransform: 'uppercase',
    '&:hover': {
      background: '#525256'
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  link: {
    color: 'white',
    textDecoration: 'underline'
  },
}))
const SignUpMethods:React.FC<any&RouteComponentProps> = ({history,location}) => {
  const { t } = useTranslation();
  const classes = useStyles()
  let { search } = useLocation();
  const alert = useContext(AlertContext)
  const {completeRegistration} = useGTMWrapper()
  const {isLoading, loadingHandler} = useIsLoading()
  const {profile,setProfile} = useContext(ProfileContext)
  const [userData, setUserData] = React.useState<{email: string, password: string, name: string}>
  ({email: '', password: '', name: ''})
  const [isEmailMethodSelected, setIsEmailMethodSelected] = React.useState<boolean>(false)
  const [activeStep, setActiveStep] = React.useState<'email' | 'password' | 'name'>('email')
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [isSub, setIsSub] = React.useState<boolean>(true)

  const responseFacebook = async (response:IFacebook) => {
    loadingHandler(true)
    let lol = `https://graph.facebook.com/${response.id}/picture?type=large&width=${userMinWidth}&height=${userMinHeight}`
    let avatar = await getCroppedImg(lol,
      {height:userMinHeight,width:userMinWidth,x:0,y:0})

    let avatarData = await URLtoFromData(avatar, 'users[avatar]')
    try {
      let respData = await AuthService.authViaFacebook({ token: response.accessToken })
      if(respData.data) {
        completeRegistration(respData.data.attributes.provider)
        setAuthData(respData.headers as AuthHeaders);
        setProfileData(respData.data as AuthInfo);
        setProfile(respData.data)
        let userData = await UserService.updateUserAvatar({profileAvatar: avatarData.avatar})
        setProfile(userData)
        setProfileData(userData as AuthInfo);
        loadingHandler(false)
      }
    } catch (e) {
      loadingHandler(false)
      console.log('responseFacebook -> e', e)
    }
  }
  const responseGoogle = async (response: any) => {
    if(response.credential != null) {
      let data: IGoogleAuth = {
        token: response.credential,
        // avatar_url: profileObj.imageUrl,
        // email: profileObj.email,
        first_name: null,
        last_name: null,
        email: null,
        origin_store: 'web',
        provider: 'google',
      }
      try {
        let respData = await AuthService.authViaGoogle(data)
        if(respData.data) {
          completeRegistration(respData.data.attributes.provider)
          setAuthData(respData.headers as AuthHeaders);
          setProfileData(respData.data as AuthInfo);
          setProfile(respData.data)
          let userData = await UserService.updateUserAvatar({profileAvatar: avatarData.avatar})
          setProfile(userData)
          setProfileData(userData as AuthInfo);
        }
      } catch (e) {
        console.log('responseGoogle -> e', e)
      }
    }
  }
  const submitEmail = async () => {
      try {
        loadingHandler(true)
        let respData = await AuthService.signUp(userData)
        if(respData.data) {
          completeRegistration(respData.data.attributes.provider)
          setAuthData(respData.headers as AuthHeaders);
          setProfileData(respData.data as AuthInfo);
          ReactPixel.default.track('Complete Registration',{'registrationMethod': respData.data?.attributes.provider,
            status: 'unknown',
            registration_date: DateTime.now().toISODate(),
            name: respData.data?.attributes.first_name + ' ' + respData.data?.attributes.last_name
          })
          loadingHandler(false)
          await onSubs()
          setProfile(respData.data)
        }
      } catch (e){
        setIsOpen(false)
        loadingHandler(false)
        alert.show(e.message,'error')
      }
  }
  const redirectToLogin = () => {
    history.push({
      pathname: '/sign_in',
      state: {
        email: userData.email
      }
    })
  }
  const submitFormCheckUser = async () => {
    loadingHandler(true)
    try {
      let respData = await AuthService.checkAuth(userData.email)
      loadingHandler(false)
      if(respData.user_exists) {
        setIsOpen(true)
      } else {
        setActiveStep('password')
      }
    } catch (e){
      loadingHandler(false)
    }
  }
  const onSubs = async () =>{
    if(isSub) {
      try {
        const data = await SubscribeService.subscribe()
        console.log('onSubs -> data', data)
      } catch (e) {
        console.log('onSubs -> e', e)
      }
    }
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const { checked, type } = target;
    event.persist();
    if( type === 'checkbox') {
      setIsSub(checked)
    }
  };
  // const mockApple = async() => {
  //   try {
  //     const u = new Uint32Array(1);
  //     const kek = window.crypto.getRandomValues(u);
  //
  //     const data = await axios.get('https://appleid.apple.com/auth/authorize',
  //       {
  //         params: {
  //           client_id: 'stg.signin.web',
  //           'response_type': 'code',
  //           'response_mode': 'form_post',
  //           'redirect_uri': 'https://account.anyplay-stg.se/sign_up',
  //           'state': kek[0],
  //           'scope': 'email',
  //         }
  //       })
  //     console.log('mockApple -> data', data)
  //   } catch (e) {
  //
  //   }
  // }
  const authWithApple = async (code:string) => {
    try {
      const respData = await AuthService.authViaApple(code)
      if(respData) {
        setAuthData(respData.headers as AuthHeaders);
        setProfileData(respData.data as AuthInfo);
        setProfile(respData.data)
        loadingHandler(false)
      }
    } catch (e){
      console.log('authWithApple -> e', e)
    }
  }
  useEffect(()=> {
    if (location.state) {
      setIsEmailMethodSelected(true)
      setUserData({
        email: location.state.userData.email,
        password: location.state.userData.password,
        name: location.state.userData.name })
    }
    // eslint-disable-next-line
  },[])

  useEffect(() => {
    const query = new URLSearchParams(search)
    const code = query.get('code')
    if(code) {
      authWithApple(code)
    }
    // eslint-disable-next-line
  },[])
  useEffect(() => {
    if(profile) {
      history.push('/payment')
    }
    // eslint-disable-next-line
  },[profile])
  return (
    <>
      {
        !isEmailMethodSelected ?
        <>
          <Grid item xs={12}>
            <Typography
              className={classes.subHeader}
              align={'center'}>
              {t('auth_sign_up_for_books')}
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.customPadding}>
            <FacebookLogin
              appId={`${process.env.REACT_APP_FACEBOOK_PK}`}
              autoLoad={false}
              version={'2.5'}
              render={(renderProps:any) => (
                <button
                  onClick={(event)=>{event.preventDefault(); renderProps.onClick()}}
                  disabled={renderProps.disabled}
                  className={['w100', classes.iconBtn].join(' ')}
                >
                  <FBIcon style={{position: 'absolute', left: '15px', top: '8px', borderRadius: '50%'}}/>
                  {t('promo_continue_with_facebook')}
                </button>
              )}
              disableMobileRedirect={true}
              fields="name,email,picture"
              cssClass={classes.iconBtn}
              callback={responseFacebook}
            />
          </Grid>
          <Grid item xs={12}>
            <GoogleLogin
              onSuccess={responseGoogle}
              onError={ () => { console.log('Login Failed') }}
              size={"large"}
              width={375}
              shape={"circle"}
            />
            {/*<GoogleButton onCredentialResponse={responseGoogle} buttonText={'continue_with'}/>*/}
          </Grid>

          <Grid item xs={12} className={classes.customPaddingLast}>
            <AppleLogin
              scope={'email'}
              responseMode={'form_post'}
              clientId={`${process.env.REACT_APP_APPLE_ID}`}
              redirectURI={`${process.env.REACT_APP_APPLE_REDIRECT}`}
              render={renderProps => (
                <button
                  onClick={(event)=>{event.preventDefault(); renderProps.onClick()}}
                  disabled={renderProps.disabled}
                  className={['w100', classes.googleBtn].join(' ')}
                >
                  <AppleIcon style={{position: 'absolute', left: '15px', top: '8px'}}/>
                  {t('promo_continue_with_apple')}
                </button>
              )}
            />
          </Grid>
          <Grid item xs={12} style={{position:'relative'}}>
            <Typography align={'center'} className={classes.textSeparator}>{t('auth_or')}</Typography>
            <Divider light />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              className={classes.bannerButtonText}
              onClick={()=>setIsEmailMethodSelected(true)}>
              {t('promo_sign_up_with_email')}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography align={'center'} style={{fontSize: '0.9rem'}}>
              {t('auth_signup_already_user')} {' '}
              <NavLink to={'/sign_in'} color={'white'} className={'noTd'} >
                <span className={classes.link}>
                  {t('promo_sign_in')}
                </span>
              </NavLink>
            </Typography>
          </Grid>
        </>

        : activeStep === 'email' ?
          <SignUpMethodsEmail
            email={userData.email}
            onSubmit={()=> submitFormCheckUser()}
            onRollbackEmail={()=>setIsEmailMethodSelected(false)}
            onSetEmail={({email})=>setUserData({...userData, email})}/>
          : activeStep === 'password' ?
          <SignUpMethodsPassword
            password={userData.password}
            onSubmit={()=>setActiveStep('name')}
            onRollbackPassword={()=>setActiveStep('email')}
            onSetPassword={({password})=>setUserData({...userData, password})}/>
        : <SignUpMethodsName
              isSub={isSub}
              name={userData.name}
              onSubmit={()=>submitEmail()}
              onRollbackName={()=>setActiveStep('password')}
              onSubs={handleChange}
              onSetName={({name})=>setUserData({...userData, name})}
            />
      }
      <Backdrop open={isLoading} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ModalOnAuth
        open={isOpen}
        modalHeader={'auth_email_already_registered'}
        modalContent={'auth_do_you_want_to_login_instead'}
        modalSubmitBtnText={'promo_sign_in'}
        onManualSubmit={redirectToLogin}
        onManualClose={()=>setIsOpen(false)}
      />
    </>
  )
}

export default withRouter(SignUpMethods)
