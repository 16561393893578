import { AuthInfo} from "../../models/auth";
import {getLocalStorageData, removeLocalStorageData, setLocalStorageData} from "./_root";
import {IProfileStripe} from "../../models/stripe";
import {IRCSubs} from "../../models/revenuecat";

const getProfileData = (): AuthInfo | null => {
  return getLocalStorageData('anyplayProfile');
};
const getProfileStripe = (): IProfileStripe | null => {
  return getLocalStorageData('anyplayStripe');
};
const getProfileRC = (): IRCSubs | null => {
  return getLocalStorageData('anyplayRC');
};
const getProfileIsCancelled = (): boolean | null => {
  return getLocalStorageData('anyplayIsCancelled');
};

const setProfileData = (authData: AuthInfo, type?: 'sessionStorage'): void => {
  setLocalStorageData('anyplayProfile', authData, type );
};
const setProfileStripe = (authData: IProfileStripe): void => {
  setLocalStorageData('anyplayStripe', authData);
};
const setProfileRC = (authData: IRCSubs): void => {
  setLocalStorageData('anyplayRC', authData);
};
const setProfileIsCancelled = (isCancelled: boolean): void => {
  setLocalStorageData('anyplayIsCancelled', isCancelled);
};

const removeProfileData = (): void => {
  removeLocalStorageData('anyplayProfile');
};
const removeProfileStripe = (): void => {
  removeLocalStorageData('anyplayStripe');
};
const removeProfileRC = (): void => {
  removeLocalStorageData('anyplayRC');
};
const removeProfileIsCancelled = (): void => {
  removeLocalStorageData('anyplayIsCancelled');
};

export {
  getProfileData, getProfileStripe, getProfileRC, getProfileIsCancelled,
  setProfileData, setProfileStripe, setProfileRC, setProfileIsCancelled,
  removeProfileData, removeProfileStripe, removeProfileRC, removeProfileIsCancelled
};
