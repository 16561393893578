import React from "react";
import {browserName, isMobile} from "react-device-detect";
import {useEffect} from "react";
import {IUserDevicePost} from "../../models/user";
import UserDevicesService from "../../service/userDevices";

const useUserDevice = () => {
  const [userDevice, setUserDevice] = React.useState<IUserDevicePost>({name:'', os:''})

  const postUserDevice = async () => {
    try {
      await UserDevicesService.postDevice({...userDevice})
    } catch (e) {

    }
  }

  useEffect(() => {
    setUserDevice({name:browserName, os: 'Web' + (isMobile ? ' (mobile) ' : '')})
  },[])

  return {
    userDevice,
    postUserDevice
  }
}
export default useUserDevice
