import React, {useContext, useEffect} from "react"
import Purchase from "../ads/Purchase";
import {useMediaQuery, useTheme} from "@material-ui/core";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {ProfileContext} from "../../context/profile/profileContext";

const OpenPurchase: React.FC<any &RouteComponentProps> = ({history}) =>{
  const {profile, isUserHasPremium} = useContext(ProfileContext)

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  const [isOpenPay, setIsOpenPay] = React.useState<boolean>(false)

  useEffect(() => {
    // show subs after sign up
    if(history.location.state) {
      if(typeof history.location.state.from !== 'undefined' && profile) {
        if(!isUserHasPremium) {
          setIsOpenPay(true)
        }
      }
    }
    // eslint-disable-next-line
  },[history.location.pathname,profile])

  return (
    <Purchase
      isForMobile={matches}
      isOpenPay={isOpenPay}
      onClosePay={(isOpen) => setIsOpenPay(isOpen)}
    />
  )
}
export default withRouter(OpenPurchase)
