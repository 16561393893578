import {Typography, useMediaQuery, useTheme} from "@material-ui/core";
import React, {useContext} from "react";
import {PlayerContext} from "../../../context/player/playerContext";
import {makeStyles} from "@material-ui/core/styles";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from '@mui/icons-material/Pause';
import H5AudioPlayer from "react-h5-audio-player";
import ShowConditionally from "../ShowConditionally";

const useStyles = makeStyles((theme)=>({
  headerContainer: {
    gridTemplateColumns: ' 70px minmax(100px, max-content)',
    gridTemplateAreas: '"img text"',
    left: 0,
    display: 'flex',
    position: 'absolute',
    width: '42%',
    [theme.breakpoints.up('sm')]: {
      display: 'grid',
      paddingTop: '10px'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    }
  },
  headerImg: {
    width: '50px',
    height: '50px',
    overflow: 'hidden',
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
    boxShadow: '0 0 10px rgba(0,34,77,.05)',
    backgroundPosition: 'center',
    gridTemplateAreas: '"img"',
    [theme.breakpoints.down('xs')]: {
      width: '30px',
      height: '30px',
    },
  },
  headerTextWrapper: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '10px',
      display: 'flex',
      alignItems: 'center',
      color: 'gray !important'
    },
    [theme.breakpoints.up('lg')]: {
      width: '100%',
    },
    width: '85%',
    gridTemplateAreas: '"text"'
  },
  headerText: {
    fontSize: '0.8rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: 'gray !important'
  },
  customColor: {
    color: 'gray !important'
  },
  headerTextChapter: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
  },
}))

const CustomAudioTitle:React.FC<{activeChapterIndex:number, pl: {current:H5AudioPlayer| null }, onOpenMaximised():void}>
  = ({activeChapterIndex, pl, onOpenMaximised}) => {
  const { content, setIsManualPlay,setIsInterrupted } = useContext(PlayerContext)

  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <div className={classes.headerContainer}>
      <div
        className={classes.headerImg}
        style={{backgroundImage:content.chapters[activeChapterIndex] ? "url(" +content.chapters[activeChapterIndex].cover+")" : ''}}
      />
      <div className={classes.headerTextWrapper} onClick={onOpenMaximised}>
        <Typography className={classes.headerText}>
          <span style={{color: 'white'}}>
            {content.chapters[activeChapterIndex] ? content.chapters[activeChapterIndex].title : ''} {content.chapters[activeChapterIndex] ? '-' : ''} {' '}
          </span>
          <span className={[classes.customColor, classes.headerText].join(' ')}>
            {content.chapters[activeChapterIndex] ? content.chapters[activeChapterIndex].author : ''}
          </span>
        </Typography>
        <Typography className={[classes.customColor, classes.headerText, classes.headerTextChapter].join(' ')}>
          { content.chapters[activeChapterIndex] ? content.chapters[activeChapterIndex].name : ''}
        </Typography>
      </div>
      <ShowConditionally isShow={matches}>
        <div>
          {
            pl.current !== null
              ? pl.current.audio ?
                pl.current.audio.current ?
                  pl.current.audio.current.paused ?
                    <PlayArrowIcon onClick={()=>setIsManualPlay(true)}/> :
                    <PauseIcon onClick={()=>setIsInterrupted(true)}/>
                  : null
                : null
              : null
          }
        </div>
      </ShowConditionally>
    </div>
  )
}
export default CustomAudioTitle
