import React, {useReducer} from "react";
import {initState, ProfileContext} from "./profileContext";
import {profileReducer} from "./profileReducer";
import {
  SET_IS_BILLING_ISSUE, SET_IS_BILLING_ISSUE_GRACE,
  SET_IS_CANCEL, SET_IS_FREE_PLAYBACK,
  SET_IS_LOADING_PREMIUM_DONE,
  SET_IS_PREMIUM, SET_IS_TRIAL_USED, SET_PREMIUM_DETAILS,
  SET_PROFILE,
  SET_RC,
  SET_STRIPE,
  SET_TERRITORY
} from "../types";
import {getProfileIsCancelled, removeProfileRC, setProfileRC, setProfileStripe} from "../../utils/localstorage/profile";
import {IProfileStripe} from "../../models/stripe";
import {IRCSubs} from "../../models/revenuecat";
import {removePreviewDone} from "../../utils/localstorage/preview";
import {AuthInfo} from "../../models/auth";
import {IPremium} from "../../models/premium";
import {DateTime} from "luxon";

export const ProfileState:React.FC = ({children}) => {

  const [state,dispatch] = useReducer(profileReducer, initState);

  const setProfile = (payload:AuthInfo | null) => {
    if(payload && payload.attributes.is_trial_used) {
      dispatch({type:SET_IS_TRIAL_USED, payload: true})
    } else {
      dispatch({type:SET_IS_TRIAL_USED, payload: false})
    }
    dispatch({type:SET_PROFILE, payload})
  }
  const setUserTerritory = (payload:string) => {
    dispatch({type:SET_TERRITORY, payload})
  }
  const setStripe = (payload:IProfileStripe) => {
    setProfileStripe(payload)
    dispatch({type:SET_STRIPE, payload})
  }
  const setRC = (payload:IRCSubs | null) => {
    if(payload) {
      setProfileRC(payload)
      dispatch({type:SET_RC, payload})
    } else {
      removeProfileRC()
    }
  }
  const setIsCancelled = (payload:boolean) => {
    dispatch({type:SET_IS_CANCEL, payload})
  }
  const setIsUserHasPremium = (data: {rcData:IRCSubs} | null) => {
    if(data) {
      dispatch({type: SET_IS_LOADING_PREMIUM_DONE, payload: true})
      const {rcData} = data

      if('Subscription' in rcData.subscriber.entitlements) {
        const subsId = rcData.subscriber.entitlements.Subscription.product_identifier
       if(DateTime.fromISO(rcData.subscriber.entitlements.Subscription.expires_date) > DateTime.now()) {
          removePreviewDone()
          dispatch({type: SET_IS_PREMIUM, payload: true})
            // @ts-ignore
          if(rcData.subscriber.subscriptions[subsId].unsubscribe_detected_at) {
            dispatch({type:SET_IS_CANCEL, payload: true})
          }
        } else {
          dispatch({type: SET_IS_PREMIUM, payload: false})
        }
        const tmpIsCancelled = getProfileIsCancelled()
        if(tmpIsCancelled) {
          dispatch({type:SET_IS_CANCEL, payload: true})
        }
      }
    } else {
      dispatch({type:SET_IS_PREMIUM, payload: false})
    }
  }
  const setFreePlayBack = () => {
    dispatch({type:SET_IS_FREE_PLAYBACK, payload: true})
  }
  const setPremiumDetails = (payload:IPremium) => {
    dispatch({type:SET_PREMIUM_DETAILS, payload})
  }
  const setIsBillingIssue = (payload:boolean) => {
    dispatch({type:SET_IS_BILLING_ISSUE, payload})
  }
  const setIsBillingIssueOutGrace = (payload:boolean) => {
    dispatch({type:SET_IS_BILLING_ISSUE_GRACE, payload})
  }
  return (
    <ProfileContext.Provider value={
      {
        profile: state.profile,
        userTerritory: state.userTerritory,
        stripeData: state.stripeData,
        rcData: state.rcData,
        premiumDetails: state.premiumDetails,
        isUserHasPremium: state.isUserHasPremium,
        isFreePlayback: state.isFreePlayback,
        isCancelled: state.isCancelled,
        isTrialUsed: state.isTrialUsed,
        isLoadingPremiumDone: state.isLoadingPremiumDone,
        isBillingIssue: state.isBillingIssue,
        isBillingIssueOutGrace: state.isBillingIssueOutGrace,
        setProfile,
        setUserTerritory,
        setStripe,
        setRC,
        setIsUserHasPremium,
        setFreePlayBack,
        setIsCancelled,
        setPremiumDetails,
        setIsBillingIssue,
        setIsBillingIssueOutGrace
      }
    }>
      {children}
    </ProfileContext.Provider>
  )
}
