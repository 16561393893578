export const ru = {
  "profile_edit_title": "Аккаунт",
  "profile_edit_first_name": "Имя",
  "profile_edit_last_name": "Фамилия",
  "profile_edit_email": "Почта",
  "profile_edit_membership_plan": "Тариф",
  "profile_settings_languages": "Языки",
  "profile_settings_sign_out": "Выйти",
  "cmn_playlists": "Плейлисты",
  "profile_account_change_email": "Изменить почту",
  "profile_account_change_password": "Изменить пароль",
  "profile_account_change_name": "Изменить имя профиля и биографию",
  "cmn_anyplay_premium": "Anyplay Plus",
  "profile_edit_save": "Сохранить",
  "profile_password_current": "Текущий пароль",
  "profile_password_new": "Новый пароль",
  "profile_password_confirmation": "Подтвердите новый пароль",
  "profile_email_new": "Новый адрес почты",
  "profile_password_send_link": "Отправить ссылку",
  "cmn_cancel": "Отмена",
  "profile_password_forgot": "Забыли пароль?",
  "profile_subscription_management": "Управление подписками",
  "promo_sign_in": "Войти",
  "promo_title_2": "Слушайте сколько угодно",
  "auth_sign_up_for_books": "Подпишитесь на неограниченные книги",
  "auth_or": "ИЛИ",
  "auth_login_to_your_account": "Войти в аккаунт",
  "auth_sign_up_password": "Выберите пароль",
  "auth_create_account": "Создать аккаунт",
  "add_title_playlist_create": "Создать",
  "add_title_playlist_create_new": "Создать плейлист",
  "add_title_playlist_enter_title": "Введите заголовок",
  "manage_playlist_remove": "Удалить плейлист",
  "manage_playlist_make_public": "Сделать плейлист публичным",
  "subscribe_offer_description": "Неограниченный доступ к более чем 200 000 книг, новые каждый день.",
  "subscribe_try_it_free": "Попробовать бесплатно",
  "title_detail_narrator": "Диктор",
  "title_detail_language": "Язык",
  "title_detail_playtime": "Длительность",
  "title_detail_year": "Год",
  "title_detail_publisher": "Издатель",
  "title_detail_others_have_read": "Другие также читают",
  "player_jump_to_chapter": "Перейти к главе",
  "player_add_to_playlist": "В плейлист",
  "subscribe_go_premium": "Хотите услышать больше?",
  "subscribe_go_premium_message": "Разблокировать все книги с Anyplay Plus.",
  "player_preview": "Фрагмент",
  "browse_ongoing_tab": "Слушаешь сейчас",
  "download_audiobook_delete": "Удалить",
  "search_discover_fiction": "Художественная",
  "search_discover_non_fiction": "Документальная",
  "search_authors": "Авторы",
  "search_playlists": "Плейлисты",
  "search_title_or_author": "Искать книгу или автора",
  "feed_new_for_you": "Новое для вас",
  "cmn_for_you": "Для вас",
  "cmn_browse": "Обзор",
  "cmn_search": "Поиск",
  "audiobooks_status_finished": "Прочитанные книги",
  "player_play": "Слушать книгу",
  "subscribe_start": "Старт",
  "profile_add_language_title": "Добавить язык",
  "subscribe_cancel_anytime": "Отпишитесь когда угодно",
  "subscribe_v3_full_access": "Разблокируйте более 200 000 книг",
  "subscribe_v3_read_as_much": "Читайте сколько угодно",
  "subscribe_v3_stream_or_download": "Слушайте онлайн или скачивайте",
  "subscribe_v3_new_books_weekly": "Еженедельные пополнения библиотеки",
  "subscribe_v3_low_fee": "Низкая стоимость",
  "subscribe_v3_no_ads": "Без рекламы",
  "subscribe_v3_trusted_by": "Доверяют любители книг\nсо всего мира",
  "player_save_for_later": "В избранное",
  "subscribe_v3_subscribe_now": "Подписаться сейчас",
  "search_no_results": "Ничего не найдено",
  "manage_playlist_make_private": "Сделать приватным",
  "cmn_anyplay_free": "Anyplay Free",
  "browse_audiobooks_tab": "Аудиокниги",
  "title_detail_unavailable_territory": "К сожалению книга была убрана издательством",
  "promo_continue_with_google": "Продолжить с Google",
  "promo_sign_up_with_email": "Зарегистрироваться с почтой",
  "promo_continue_with_facebook": "Продолжить с Facebook",
  "auth_whats_your_email": "Ваш адрес почты",
  "auth_you_need_to_confirm_email": "Позже нужно будет подтвердить этот адрес.",
  "auth_email_already_registered": "Эта почта уже привязана к аккаунту",
  "auth_do_you_want_to_login_instead": "Хотите вместо этого войти?",
  "auth_whats_your_name": "Ваше имя",
  "auth_name_appears_on_your_profile": "Имя будет видно в вашем профиле Anyplay.",
  "auth_name_placeholder": "Иван Иванов",
  "auth_password": "Пароль",
  "auth_register": "Зарегистрироваться",
  "subscribe_v4_books_all_genres": "200 000+ книг любых жанров",
  "subscribe_v4_start_free_trial": "Начать пробный период",
  "action_share_book": "Поделиться книгой",
  "subscribe_download_go_premium": "Разблокировать функцию",
  "auth_short_password_error_message": "Пароль слишком короткий\nМинимум 6 символов",
  "auth_signup": "Регистрация",
  "auth_email_fill_error_message": "Пожалуйста, заполните электронную почту",
  "player_track": "Трек",
  "search_more_audiobooks": "Показать все книги",
  "search_more_authors": "Показать всех авторов",
  "search_more_playlists": "Показать все плейлисты",
  "playlist_share": "Поделиться",
  "player_saved_for_later": "Сохранено",
  "title_detail_rating": "Рейтинг",
  "cmn_edit": "Редактировать",
  "review_rating_title": "Как вам понравилась эта книга?",
  "review_comment_title": "Опишите своими словами",
  "review_title": "Рейтинги и комментарии",
  "playlist_edit_title": "Редактировать плейлист",
  "title_more_from_author": "Больше книг этого автора",
  "title_mark_finished": "Отметить книгу прочитанной",
  "title_rate_and_comment": "Оценить и комментировать",
  "title_download": "Скачать",
  "profile_settings_help_center": "Справочный центр",
  "language_preferred_order": "Предпочтительный порядок языков",
  "profile_settings_terms_of_service": "Условия использования",
  "profile_login_Information": "Информация для входа",
  "profile_account_change_avatar": "Сменить фото профиля",
  "profile_account_reset_password": "Сброс пароля",
  "profile_account_reset_password_send": "Ссылка для сброса пароля была отправлена на адрес ",
  "profile_account_passords_not_match_alert": "Новый пароль и пароль подтверждения не совпадают",
  "profile_account_display_name": "Отображаемое имя",
  "profile_account_biography": "Биография",
  "profile_account_display_name_description": "(если вы делитесь плейлистами, это будет ваше публичное имя)",
  "profile_account_biography_description": "(короткий текст для описания вашего профиля)",
  "profile_add_email_login": "Добавить авторизацию по почте",
  "profile_add_email_login_to_unlink": "Чтобы удалить связь с учетной записью, сначала добавьте адрес электронной почты для входа.\nЭто гарантирует, что вы не потеряете свои данные Anyplay.",
  "profile_signin_with_apple": "Войти через Apple",
  "profile_signin_with_facebook": "Войти через Facebook",
  "ongoing_placeholder_title": "Вы еще не начали ни одной книги",
  "ongoing_placeholder_subtitle": "Начните любую книгу, и она появится здесь",
  "paywall_read_more_with_unlimited_access": "Узнать больше с неограниченным доступом",
  "paywall_start_your_free_trial": "Начните 7-дневную бесплатную пробную версию",
  "period_unit_month": "месяц",
  "period_unit_year": "год",
  "period_unit_months": "месяца",
  "subscription_alert_button": "7 дней бесплатно",
  "subscription_alert_skip_trial": "Пропустить",
  "auth_next": "Далее",
  "profile_consumption_total_label": "Всего",
  "profile_consumption_this_month_label": "В этом месяце",
  "profile_consumption_title": "Прослушанное время",
  "playlists_see_all": "Все",
  "subscribe_billing_issue_android": "Обновите платежную информацию в Google Play",
  "subscribe_billing_issue_ios": "Обновите платежную информацию в App Store",
  "subscribe_billing_issue_header": "Ошибка проведения оплаты",
  "finished_placeholder_title": "Вы ещё не закончили слушать ни одной книги",
  "saved_placeholder_title": "Вы не сохранили ни одной книги",
  "profile_edit_interface_language": "Язык интерфейса",
  "subscribe_save_discount_web": "-",
  "auth_terms_web": "Регистрируя аккаунт вы соглашаетесь с <1>Условиями использования</1>. Узнайте как Anyplay хранит и обрабатывает персональные данные в <3>Политике безопасности</3>.",
  "cmn_anyplay_free_description": "Без ограничений доступны только подкасты",
  "cmn_select": "Выбрать",
  "prices_read_without_limits": "Слушайте без ограничений",
  "prices_choose_your_plus": "Выберите свой Plus",
  "prices_other_plans": "Доступные планы",
  "download_app_use_our_player": "или слушайте <1>в браузере</1>",
  "promo_continue_with_apple": "Продолжить с Apple",
  "profile_password_reset_message_web": "Мы отправим вам ссылку на почту {{email}} чтобы сбросить пароль. Если вы не можете получить доступ к этой почте обратитесь в техподдержку.",
  "profile_signin_with_google": "Войти через Google",
  "auth_sign_in_remember_me": "Запомнить меня",
  "profile_settings_version_web": "Версия:",
  "auth_sign_in_no_account": "Нет учётной записи?",
  "auth_signup_already_user": "Уже зарегистрированы?",
  "banner_preview_title": "Это пробная версия Anyplay",
  "profile_consumption_total_web": "Вы прочитали {{books}} книг и прослушали {{hours}} часов с тех пор, как начали использовать Anyplay",
  "profile_consumption_this_month_web": "Вы начали {{books}} книг и прослушали {{hours}} часов в этом месяце",
  "title_mark_ongoing": "Отметить как не прочитанное",
  "subscribe_billing_issue_web": "Обновите данные карты с помощью <1>Stripe</1> (нажатие откроет портал пользователя Stripe).",
  "subscribe_billing_issue_suspend ": "К сожалению, ваша оплата не дошла до нас, поэтому мы ограничили доступ к сервису. После успешной оплаты все возможности будут восстановлены.",
  "profile_unlink": "Отвязать",
  "profile_account_change_email_and_password": "Смена пароля и почты",
  "profile_active_sessions": "Активные сеансы",
  "profile_log_me_out": "Завершить все сеансы",
  "profile_binding_services": "Привязка внешних сервисов",
  "profile_binding_connected": "Вы уже привязали следующие учетные записи",
  "paywall_get_free_audiobooks": "Слушайте бесплатно в течении 7 дней",
  "profile_binding_services_description": "Привяжите учетные записи своих социальных сетей к Anyplay.\nПосле этого вы сможете входить на сервис в один клик."
}
