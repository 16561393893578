import React, {useContext, useEffect, useRef} from "react";
import '../../playerV2.css'
import ShowConditionally from "./ShowConditionally";
import Maximised from "./AnyplayPlayer/Maximised";
import {ProfileContext} from "../../context/profile/profileContext";
import {useMediaQuery, useTheme} from "@material-ui/core";
import AudioPlayer, {RHAP_UI} from "react-h5-audio-player";
import SpeedRateMenu from "./AnyplayPlayer/SpeedRateMenu";
import PlayerPlaylist from "./AnyplayPlayer/Playlist";
import IconCDN from "./AnyplayPlayer/IconCDN";
import CustomAudioTitle from "./AnyplayPlayer/CustomAudioTitle";
import CustomCurrentTrack from "./AnyplayPlayer/CustomCurrentTrack";
import {makeStyles} from "@material-ui/core/styles";
import {getPlayerSettings} from "../../utils/localstorage/playerSettings";
import {PlayerContext} from "../../context/player/playerContext";
import H5AudioPlayer from "react-h5-audio-player";
import useConsumptions from "../../utils/hooks/useConsumptions";
import {IAudiobookBookmarkPost} from "../../models/audiobook";
import {browserName, isMobile} from "react-device-detect";
import AudiobookBookmarkService from "../../service/audiobookBookmark";
import {setPlayerContent} from "../../utils/localstorage/playerContent";
import ServicePodcastsEpisode from "../../service/podcastsEpisode";
import AnyplayPlayerAudioInfo from "./AnyplayPlayerAudioInfo";
import {ICDNInfo} from "../../models/playback";
import usePreviewEnd from "./AnyplayPlayer/usePreviewEnd";
import {RouteComponentProps, withRouter} from "react-router-dom";
import cable from "../../plugins/actionCable";
import {IPlaybackStartSocket} from "../../models/player";
import {getAuthData} from "../../utils/auth";
import {Forward10IconNode, PlayArrowIconNode, Replay10IconNode} from "./AnyplayPlayer/Icons";

const useStyles = makeStyles(()=>({
  maximisedPlayer: {
    maxHeight: '35%',
    minHeight: '35%',
    height: '35%',
    boxShadow: 'none',
    overflow: 'hidden'
  },
  maximisedWrapper: {
    zIndex: 1299,
    boxShadow: '0 0 3px #403f3f',
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    color: '#fff',
    backgroundColor: 'rgba(0,0,0)',
  },
}))

const AnyplayPlayerV4:React.FC<any&RouteComponentProps> = ({history}) => {
  const {profile, isUserHasPremium} = useContext(ProfileContext)
  const {isPaused, content, isInterrupted, isManualPlay, playerSettingsLocal,
    setIsPaused, setIsInterrupted, setIsManualPlay,setPlayerSettingsLocal} = useContext(PlayerContext)

  const {handleNonPremiumUser,RenderPreviewEndWithLogin} = usePreviewEnd()
  const {trackPlayback, updatePlayback} = useConsumptions()
  const player: {current:H5AudioPlayer| null } = useRef(null)
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  const [clientId, setClientId] = React.useState<string | null>()
  const [userClientId, setUserClientId] = React.useState<string | null>()

  const [streamInfo, setStreamInfo] = React.useState<ICDNInfo[]>([])
  const [activeChapter, setActiveChapter] = React.useState<any>('')
  const [activeChapterIndex, setActiveChapterIndex,] = React.useState<number>(0)
  const [isMaximised, setIsMaximised] = React.useState<boolean>(false)
  const [isOpenPlayerAudioInfo, setIsOpenPlayerAudioInfo] = React.useState<boolean>(false)

  const audioVolumeChange = (event: any) => {
    setPlayerSettingsLocal({...playerSettingsLocal, volume: event.target.volume})
  }
  const openMaximised = ()=> {
    if(matches) {
      setIsMaximised(true)
    }
  }
  const playChapter = (index: number) => {
    setIsPaused(false)
    setActiveChapterIndex(index)
    setActiveChapter(content.chapters[index])
  }

  let prev: number = 0, curr: number = 0;
  const handleBookmark = (data:{currentTime:number}) => {
    const {currentTime} = data
    curr = Math.trunc(currentTime)
    if((curr + Math.trunc(parseInt(activeChapter.timestamp)/1000)) % 30 === 0 && curr !==0) {
      trackPlayback({currentTime,activeChapter})
    }
    if( (curr+ Math.trunc(parseInt(activeChapter.timestamp)/1000)) % 600 === 0 && curr !==0) {
      if(curr !== prev) {
        prev = curr
        postBookmark(currentTime)
        updatePlayback({currentTime, activeChapter})
      }
    }
    else {
      prev = 0
      curr = 0;
    }
  }

  // memo: currentTime in seconds, but activeChapter.timestamp in milliseconds
  const postBookmark = async (currentTime: number) => {
    if(currentTime !== 0) {
      let calcTimestamp =  parseInt(activeChapter.timestamp)/1000 + currentTime
      if(activeChapter && content.contentType === 'audiobook' ) {
        let bookmark: IAudiobookBookmarkPost = {
          // MEMO: should be seconds!!!
          offset: Math.round(calcTimestamp),
          device_info: 'Web' + (isMobile ? ' (mobile) ' : ' ') + browserName
        }
        try {
          await AudiobookBookmarkService.postBookmark({audiobookId: content.contentId, bookmark })
        } catch (e) {
          console.log('postBookmark -> e', e)
        }
      } else if(activeChapter && content.contentType === 'podcast' ) {

        try {
          let resp = await ServicePodcastsEpisode.postEpisodeBookmark({episode_id: content.contentId, timestamp:calcTimestamp })
          setPlayerContent({...content,bookmarks: {...resp}})
        } catch (e) {
          console.log('postBookmark -> e', e)
        }
      }
    }
  }

  const onListen = (event: any)=> {
    if(clientId === userClientId) {
      const currentTime = event.target.currentTime
      console.log('onListen -> activeChapter', activeChapter)
      if(isUserHasPremium && activeChapter.play_source !== 'preview') {
        handleBookmark({currentTime})
        //handlePremiumUser({currentTime})
      } else {
        handleNonPremiumUser({currentTime,activeChapter})
      }
    } else {
      setIsInterrupted(true)
     // setIsPaused(true)
    }
  }

  const onPause = (event:any)=> {
    const currentTime = event.target.currentTime
    postBookmark(currentTime)
    updatePlayback({currentTime, activeChapter})
   // setIsPaused(true)
  }
  const findProperChapterIndex = (isLocal?:boolean): number =>  {
    const bookmarkType = isLocal ? 'localBookmark' : 'bookmarks'
    const properChapter = content.chapters.reduce((prev, curr) => {
      if (curr.timestamp/1000 > content[bookmarkType]!.attributes.offset) {
        return prev
      }
      return Math.abs(curr.timestamp/1000 - content[bookmarkType]!.attributes.offset) < Math.abs(prev.timestamp/1000 - content[bookmarkType]!.attributes.offset) ? curr : prev
    })
    console.log('findProperChapterIndex -> properChapter', properChapter)
    return content.chapters.findIndex(audio => audio === properChapter)
  }
  const detectBookmark = () => {
    const idx = findProperChapterIndex(false) //type === 'local'

    setActiveChapter(content.chapters[idx])
    setActiveChapterIndex(idx)
    //const dif = ((type === 'local' ?  content.localBookmark!.attributes.offset : content.bookmarks!.attributes.offset) - content.chapters[idx].timestamp)
    const dif = (content.bookmarks!.attributes.offset - content.chapters[idx].timestamp/1000)
    if (player.current && dif !== null) {
      if(player.current.audio) {
        if(player.current.audio.current) {
          player.current.audio.current.currentTime = dif
          console.log('detectBookmark -> isPaused', isPaused)
          ///setIsPaused(false)
         // playerCurrentPlay()
        }
      }
    }
  }

  const handleNextChapter = (): void => {
    const idx = activeChapterIndex < content.chapters.length - 1 ? activeChapterIndex + 1 : 0
    if(activeChapterIndex === content.chapters.length-1) {
      if(!isUserHasPremium) {
        history.push('/prices')
      }
    } else {
      setIsPaused(false)
      setActiveChapterIndex(idx)
      setActiveChapter(content.chapters[idx])
    }
  }

  const playerInterrupt = () => {
    if(player.current) {
      if(player.current.audio) {
        if (player.current.audio.current) {
          player.current.audio.current.pause()
          setIsInterrupted(false)
          setIsPaused(true)
        }
      }
    }
  }
  const playerPlay = () => {
    if(player.current) {
      if(player.current.audio) {
        if (player.current.audio.current) {
          player.current.audio.current.play()
          setIsManualPlay(false)
          setIsPaused(false)
        }
      }
    }
  }

  useEffect(() => {
    if(isInterrupted) {
      playerInterrupt()
    }
    // eslint-disable-next-line
  },[isInterrupted])
  useEffect(() => {
    if(isManualPlay) {
      playerPlay()
    }
    // eslint-disable-next-line
  },[isManualPlay])
  useEffect(()=>{
    const data = getPlayerSettings()
    if(data !== null) {
      setPlayerSettingsLocal({...data})
    }
    // eslint-disable-next-line
  },[])
  useEffect(() => {
    setActiveChapterIndex(0)
    setActiveChapter(content.chapters[0])
    if(content.bookmarks !== null) {
      detectBookmark()
    }
    // eslint-disable-next-line
  },[player, content])
  useEffect(() => {
    if(player.current) {
      if(player.current.audio) {
        if(player.current.audio.current) {
          player.current.audio.current.volume = playerSettingsLocal.volume
          player.current.audio.current.playbackRate = playerSettingsLocal.speedRate
        }
      }
    }
  },[player, playerSettingsLocal])

  useEffect(()=>{
    if(typeof activeChapter.cover !== 'undefined') {
      if ("mediaSession" in navigator) {

        // @ts-ignore
        navigator.mediaSession.metadata = new window.MediaMetadata({
          title: activeChapter.title,
          artist: activeChapter.author,
          artwork: [
            { src: activeChapter.cover+ '?aspect=1:1&height=96',  sizes: '96x96',   type: 'image/png' },
            { src: activeChapter.cover+ '?aspect=1:1&height=128', sizes: '128x128', type: 'image/png' },
            { src: activeChapter.cover+ '?aspect=1:1&height=192', sizes: '192x192', type: 'image/png' },
            { src: activeChapter.cover+ '?aspect=1:1&height=256', sizes: '256x256', type: 'image/png' },
            { src: activeChapter.cover+ '?aspect=1:1&height=384', sizes: '384x384', type: 'image/png' },
            { src: activeChapter.cover+ '?aspect=1:1&height=512', sizes: '512x512', type: 'image/png' },
          ]
        });
        const actionHandlers = [
          // ['play',          () => { /* ... */ }],
          // ['pause',         () => { /* ... */ }],
          // ['stop',          () => { /* ... */ }],
          ['seekbackward',  () => {
            const skipTime = 15;
            // @ts-ignore
            player.current.audio.current.currentTime = Math.max( player.current.audio.current.currentTime - skipTime, 0);
          }],
          ['seekforward',   () => {
            const skipTime =15;
            // @ts-ignore
            player.current.audio.current.currentTime = Math.min( player.current.audio.current.currentTime + skipTime,  player.current.audio.current.duration);
          }],
          //['seekto',        (details) => { /* ... */ }],
        ];

        for (const [action, handler] of actionHandlers) {
          try {
            // @ts-ignore
            navigator.mediaSession.setActionHandler(action, handler);
          } catch (error) {
            console.log(`The media session action "${action}" is not supported yet.`);
          }
        }
      }
    }

  },[activeChapter])
  useEffect(() => {
    if(profile) {
      if(typeof profile.attributes.admin !== 'undefined') {
        const data = [
          {header:'Buffer length', text: '50 seconds'},
          {header:'Audiobook ID', text: content.contentId},
          {header:'User ID', text: profile.id},
          {header:'CDN', text: activeChapter.play_source},
          //{header:'License ID', text: licenseLocal?.id},
          {header:'Chapter', text: activeChapter.chapter_number}
        ]

        // @ts-ignore
        const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
        if(typeof connection !== 'undefined') {
          data.push( {header:'Connection', text: connection.effectiveType +' ' +connection.downlink +' mb/s ' + connection.rtt+' ms'},)
        }
        setStreamInfo(data)
      }
    }
    // eslint-disable-next-line
  },[profile,activeChapter])
  useEffect(() => {
    if(profile) {
      const authData = getAuthData()
      if(authData) {
        setClientId(authData.client)
        setUserClientId(authData.client)
      }
      cable.subscriptions.create({
          channel: 'UsersChannel'
        },
        {
          connected() {
            // @ts-ignore
            this.perform('follow_user', {id: profile.id})
          },
          received(resp:IPlaybackStartSocket) {
            if(resp.client) {
              setClientId(resp.client)
            }
          }
        })
    }
  },[profile])

  return (
    <ShowConditionally isShow={profile !== null && history.location.pathname !== '/prices' && history.location.pathname !== '/payment'}>
      <ShowConditionally isShow={isMaximised}>
        <Maximised
          onSetIsMaximised={(isMax)=>setIsMaximised(isMax)}
          onSetIsOpenPlayerAudioInfo={(isOpenAudioInfo)=>setIsOpenPlayerAudioInfo(isOpenAudioInfo)}/>
      </ShowConditionally>
      <span
        className={isMaximised ? classes.maximisedWrapper : ''}
         >
        <AudioPlayer
          layout={isMaximised || matches ? 'stacked': "stacked-reverse"}
          autoPlay={!isPaused}
          autoPlayAfterSrcChange={!isPaused}
          className={isMaximised ? classes.maximisedPlayer : ''}
          ref={player}
                  // @ts-ignore
          src={content.chapters[activeChapterIndex] ? content.chapters[activeChapterIndex].musicSrc as string : ''}
          onVolumeChange={audioVolumeChange}
          volume={playerSettingsLocal.volume}
          //onClickPrevious={handleClickPrevious}
         // onClickNext={handleClickNext}
          onListen={onListen}
          onPause={onPause}
          showSkipControls={false}
          //showJumpControls={!matches || isMaximised}
          showJumpControls={true}
          onEnded={handleNextChapter}
          progressJumpSteps={{
            forward: 10000,
            backward: 10000
          }}

          customAdditionalControls={[]}
          customProgressBarSection={
            [
              !matches && !isMaximised ? RHAP_UI.CURRENT_TIME : <></>,
              RHAP_UI.PROGRESS_BAR,
              !matches && !isMaximised ? RHAP_UI.CURRENT_LEFT_TIME : <></>,
              !matches ? <SpeedRateMenu/> : <></>,
              !matches ? isUserHasPremium ?
                <PlayerPlaylist activeChapterIndex={activeChapterIndex} onPlayChapter={(index)=>playChapter(index)}  />
                : <></> : <></>,
              !matches ? RHAP_UI.VOLUME : <></>,
              !matches && (profile !== null && typeof profile.attributes.admin !== 'undefined') ?
                <IconCDN onSetIsOpenPlayerAudioInfo={(isOpenAudioInfo)=>setIsOpenPlayerAudioInfo(isOpenAudioInfo)}/>
                : <></>,
            ]
          }
          customControlsSection={[
            isMaximised ? <></> : <CustomAudioTitle activeChapterIndex={activeChapterIndex} pl={player} onOpenMaximised={openMaximised}/> ,
            matches && isMaximised ? RHAP_UI.CURRENT_TIME : <></>,
            matches && isMaximised ? RHAP_UI.CURRENT_LEFT_TIME : <></>,
            isMaximised ? <CustomCurrentTrack activeChapterIndex={activeChapterIndex}/>  : <></>,
            matches && !isMaximised ? <></> : RHAP_UI.MAIN_CONTROLS,
          ]}
          customVolumeControls={[]}
          customIcons={{
            play: PlayArrowIconNode,
            rewind: Replay10IconNode,
            forward: Forward10IconNode
          }}
        />
      </span>
      <AnyplayPlayerAudioInfo
        isOpenPlayerAudioInfo={isOpenPlayerAudioInfo}
        onClosePlayerAudioInfo={()=>setIsOpenPlayerAudioInfo(false)}
        streamInfo={streamInfo}/>
      <RenderPreviewEndWithLogin/>
    </ShowConditionally>
  )
}
export default withRouter(AnyplayPlayerV4)
