import React, {useContext, useEffect} from "react";
import {AppBar, Dialog, Grid, IconButton, Toolbar, Typography} from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import CloseIcon from "@material-ui/icons/Close";
import PayV2 from "../fullScreen/PayV2";
import SignUpV2View from "../../view/SignUpV2";
import {ProfileContext} from "../../context/profile/profileContext";
import {makeStyles} from "@material-ui/core/styles";
import {PriceContext} from "../../context/price/priceContext";
import {usePrices} from "../../utils/hooks/usePrices";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(()=>({
  appBarModal: {
    position: 'relative',
    backgroundColor: '#232323',
    boxShadow: 'none',
    height: '35px',
    justifyContent: 'center',
    padding: '30px 0'
  },
  appBarModalTran: {
    backgroundColor: '#3b44fc'
  },
  overFlowNonMob: {
    overflow: 'hidden'
  }
}))
const Purchase:React.FC<{
  isOpenPay: boolean, isDisplayPrices?: boolean,giftCode?: string, isForMobile?: boolean,
  productId?: string, onClosePay(isOpen:boolean):void}>
  = ({isOpenPay, isForMobile,
       isDisplayPrices = true, productId, giftCode, onClosePay}) => {
  const { t } = useTranslation();
  const {profile} = useContext(ProfileContext)
  const {prices} = useContext(PriceContext)

  const {setFullPriceByProduct} = usePrices()
  const classes = useStyles()
  useEffect(() => {
    if(prices && productId) {
      setFullPriceByProduct(productId)
    }
    // eslint-disable-next-line
  },[prices, productId])

  return (
          <Dialog
            maxWidth={'xs'}
            open={isOpenPay}
            fullScreen={isForMobile}
            onClose={()=>onClosePay(false)}>
            <AppBar className={[classes.appBarModal, profile ? classes.appBarModalTran : ''].join(' ')} >
              <Toolbar disableGutters >
                {
                  profile ?
                    <>
                      <LockIcon style={{marginLeft: '10px'}}/>
                      <Typography
                        align={'center'}
                        className={'w100'}
                      >
                        {t('This transaction is secure')}
                      </Typography>
                    </>
                    :
                    <>
                      <div style={{marginLeft: '30px'}}/>
                    <Typography
                      style={{fontWeight: 600}}
                      className={'w100'}
                      align={'center'}
                      variant={'h4'}
                      >
                      anyplay
                    </Typography>
                      {/*stories well told*/}
                    </>
                }
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={()=>onClosePay(false)}
                  aria-label="close">
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Grid
              container
              style={{background: '#232323', padding: '1rem 0', height: isForMobile? '100vh': 'inherit'}}>
              {
                profile ?
                  <Grid item xs={12} style={{background: '#232323', padding: '1rem'}}>
                    <PayV2
                      giftCode={giftCode}
                      isDisplayPrices={isDisplayPrices}
                      callCancelPay={(isClosed) => onClosePay(isClosed)}
                    />
                  </Grid>
                  :
                  <Grid item xs={12}  className={isForMobile ? '' :  classes.overFlowNonMob}>
                    <SignUpV2View/>
                  </Grid>
              }
            </Grid>
          </Dialog>
  )
}

export default Purchase
