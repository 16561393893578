import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter as Router} from "react-router-dom";
import { MuiThemeProvider } from '@material-ui/core';
import anyplayTheme from "./anyplayTheme";
import { ProfileState } from './context/profile/ProfileState';
import { PriceState } from './context/price/PriceState';
import './plugins/i18n';
import {PlayerState} from "./context/player/PlayerState";
import {ScrollState} from "./context/scroll/ScrollState";
import withClearCache from "./components/utils/ClearCache";
import {AlertState} from "./context/alert/AlertState";
import {GradientState} from "./context/gradient/GradientState";
import { GoogleOAuthProvider } from '@react-oauth/google';
//import {MixPanelProvider} from "react-mixpanel-provider-component";
// import {UseGTMHookProvider} from '../src/utils/gtmTMP'
const ClearCacheComponent = withClearCache(App);
ReactDOM.render(

  <ProfileState>
    <PriceState>
      {/*<MixPanelProvider>*/}
        <PlayerState>
          <GradientState>
            <ScrollState>
              <AlertState>
              <Router>
                <MuiThemeProvider theme = { anyplayTheme }>
                  <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_PK}`}>
                    <React.StrictMode>
                      <ClearCacheComponent/>
                    </React.StrictMode>
                  </GoogleOAuthProvider>,
                </MuiThemeProvider>
              </Router>
              </AlertState>
            </ScrollState>
          </GradientState>
        </PlayerState>
      {/*</MixPanelProvider>*/}
    </PriceState>
  </ProfileState>

,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
