import React, {useContext} from "react";
import {Grid, Typography} from "@material-ui/core";
import {PlayerContext} from "../../../context/player/playerContext";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(()=>({
  customColor: {
    color: 'gray !important'
  },
  imgContainer: {
    height: '200px',
    width: '200px',
    display: 'block',
    margin: '0 auto'
  },
  audiobookDetailsText: {
    paddingTop: '10px',
    paddingRight: '10px'
  },
}))

const MaximisedAudiobookDetails = () => {
  const { content } = useContext(PlayerContext)
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12}>
        {  content.chapters[0] && content.chapters[0].cover ?
          <div className={classes.imgContainer}>
            <img
              style={{ zIndex: 2, position: 'relative'}}
              src={content.chapters[0].cover+'?aspect=1:1&height=200'}
              alt="cover_image_url"/>
          </div>
          : <div className={classes.imgContainer}/>
        }
      </Grid>
      <Grid item xs={12} className={classes.audiobookDetailsText}>
        <Typography align={'center'}>
          {content.chapters[0] ? content.chapters[0].title : ''}
        </Typography>
        <Typography align={'center'} className={[classes.customColor].join(' ')}>
          {content.chapters[0] ? content.chapters[0].author : ''}
        </Typography>
      </Grid>
    </Grid>
  )
}
export default MaximisedAudiobookDetails
