export const sv = {
  "profile_edit_title": "Konto",
  "profile_edit_first_name": "Förnamn",
  "profile_edit_last_name": "Efternamn",
  "profile_edit_email": "Epostadress",
  "profile_edit_membership_plan": "Medlemsplan",
  "profile_settings_languages": "Språk",
  "profile_settings_sign_out": "Logga ut",
  "cmn_playlists": "Spellistor",
  "profile_account_change_email": "Ändra epostadress",
  "profile_account_change_password": "Ändra lösenord",
  "profile_account_change_name": "Ändra profilnamn och bio",
  "cmn_anyplay_premium": "Anyplay Plus",
  "profile_edit_save": "Spara",
  "profile_password_current": "Nuvarande lösenord",
  "profile_password_new": "Nytt lösenord",
  "profile_password_confirmation": "Verifiera nytt lösenord",
  "profile_email_new": "Ny epostadress",
  "profile_password_send_link": "Skicka länk",
  "cmn_cancel": "Avbryt",
  "profile_password_forgot": "Glömt lösenord?",
  "profile_subscription_management": "Hantera abonnemang",
  "promo_sign_in": "Logga in",
  "promo_title_2": "Lyssna så mycket du vill",
  "auth_sign_up_for_books": "Bli medlem för full tillgång till de bästa ljudböckerna",
  "auth_or": "ELLER",
  "auth_login_to_your_account": "Logga in på ditt konto",
  "auth_sign_up_password": "Välj lösenord",
  "auth_create_account": "Skapa konto",
  "add_title_playlist_create": "Skapa",
  "add_title_playlist_create_new": "Skapa ny spellista",
  "add_title_playlist_enter_title": "Välj namn",
  "manage_playlist_remove": "Ta bort spellistan",
  "manage_playlist_make_public": "Gör spellistan publik",
  "subscribe_offer_description": "Full tillgång till 300.000 ljudböcker och nya titlar varje dag.",
  "subscribe_try_it_free": "Testa gratis",
  "title_detail_narrator": "Uppläsare",
  "title_detail_language": "Språk",
  "title_detail_playtime": "Speltid",
  "title_detail_year": "År",
  "title_detail_publisher": "Förlag",
  "title_detail_others_have_read": "Andra har läst",
  "player_jump_to_chapter": "Hoppa till kapitel",
  "player_add_to_playlist": "Lägg till spellista",
  "subscribe_go_premium": "Vill du lyssna mer?",
  "subscribe_go_premium_message": "Lås upp alla böcker med Anyplay Plus",
  "player_preview": "Provlyssna",
  "browse_ongoing_tab": "Pågående",
  "download_audiobook_delete": "Ta bort",
  "search_discover_fiction": "Fiction",
  "search_discover_non_fiction": "Non-fiction",
  "search_authors": "Författare",
  "search_playlists": "Spellistor",
  "search_title_or_author": "Sök titel eller författare",
  "feed_new_for_you": "Nytt för dig",
  "cmn_for_you": "För dig",
  "cmn_browse": "Bläddra",
  "cmn_search": "Sök",
  "audiobooks_status_finished": "Avslutade böcker",
  "player_play": "Spela",
  "subscribe_start": "Start",
  "profile_add_language_title": "Lägg till språk",
  "subscribe_cancel_anytime": "Avsluta när du vill",
  "subscribe_v3_full_access": "Lås upp 300 000 böcker",
  "subscribe_v3_read_as_much": "Lyssna så mycket du vill",
  "subscribe_v3_stream_or_download": "Strömma eller ladda ner offline",
  "subscribe_v3_new_books_weekly": "Nya böcker varje vecka",
  "subscribe_v3_low_fee": "Låg månadskostnad",
  "subscribe_v3_no_ads": "Ingen reklam",
  "player_save_for_later": "Favoritmarkera",
  "subscribe_v3_subscribe_now": "Starta prenumeration",
  "search_no_results": "Inga resultat",
  "manage_playlist_make_private": "Gör spellistan privat",
  "cmn_anyplay_free": "Anyplay Free",
  "browse_audiobooks_tab": "Ljudböcker",
  "title_detail_unavailable_territory": "Tyvärr är boken nyligen borttagen av förlaget i ditt land",
  "promo_continue_with_google": "Fortsätt med Google",
  "promo_sign_up_with_email": "Registrera med epost",
  "promo_continue_with_facebook": "Fortsätt med Facebook",
  "auth_whats_your_email": "Vad är din epostadress?",
  "auth_you_need_to_confirm_email": "Du behöver verifiera epostadressen senare.",
  "auth_email_already_registered": "Den här epostadressen är redan kopplad till ett konto",
  "auth_do_you_want_to_login_instead": "Vill du logga in istället?",
  "auth_whats_your_name": "Vad heter du?",
  "auth_name_appears_on_your_profile": "Det här visas på din Anyplay-profil.",
  "auth_name_placeholder": "Sven Svensson",
  "auth_password": "Lösenord",
  "auth_register": "Registrera",
  "subscribe_v4_books_all_genres": "200.000 böcker i alla genres",
  "subscribe_v4_start_free_trial": "Starta gratis provperiod",
  "action_share_book": "Dela boken",
  "subscribe_download_go_premium": "Lås upp den här funktionen.",
  "auth_short_password_error_message": "Lösenordet för kort.\nAnvänd minst 6 tecken.",
  "auth_signup": "Bli Medlem",
  "auth_email_fill_error_message": "Fyll i epostadressen",
  "player_track": "Spår",
  "search_more_audiobooks": "Se alla ljudböcker",
  "search_more_authors": "Se alla författare",
  "search_more_playlists": "Se alla spellistor",
  "playlist_share": "Dela",
  "player_saved_for_later": "Sparad",
  "title_detail_rating": "Betyg",
  "cmn_edit": "Ändra",
  "review_rating_title": "Vad tyckte du om boken?",
  "review_comment_title": "Beskriv med dina ord",
  "review_title": "Betyg & kommentarer",
  "playlist_edit_title": "Ändra spellista",
  "title_more_from_author": "Mer av författaren",
  "title_mark_finished": "Markera som spelad",
  "title_rate_and_comment": "Betygsätt och kommentera",
  "title_download": "Ladda ner",
  "profile_settings_help_center": "Hjälpcenter",
  "language_preferred_order": "Föredragen ordning",
  "profile_settings_terms_of_service": "Användarvillkor",
  "profile_login_Information": "Inloggningsinformation",
  "profile_account_change_avatar": "Ändra profilbild",
  "profile_account_reset_password": "Återställ lösenord",
  "profile_account_reset_password_send": "En återställningslänk har skickats till",
  "profile_account_passords_not_match_alert": "Lösenorden matchar inte",
  "profile_account_display_name": "Användarnamn",
  "profile_account_biography": "Biografi",
  "profile_add_email_login": "Lägg till epostadress",
  "profile_signin_with_apple": "Logga in med Apple",
  "profile_signin_with_facebook": "Logga in med Facebook",
  "ongoing_placeholder_title": "Du har påbörjat några böcker",
  "ongoing_placeholder_subtitle": "Börja spela en bok och den kommer att sparas här",
  "paywall_read_more_with_unlimited_access": "Läs med full tillgång",
  "paywall_start_your_free_trial": "Starta din 7-dagars gratisperiod",
  "period_unit_month": "månad",
  "period_unit_year": "år",
  "period_unit_months": "månader",
  "subscription_alert_button": "7 dagar gratis",
  "subscription_alert_skip_trial": "Hoppa över",
  "auth_next": "Fortsätt",
  "profile_consumption_total_label": "Totalt",
  "profile_consumption_this_month_label": "Denna månad",
  "profile_consumption_title": "Lyssningshistorik",
  "playlists_see_all": "Se alla",
  "subscribe_billing_issue_android": "Uppdatera betalinformation i Google Play Store",
  "subscribe_billing_issue_ios": "Uppdatera betalinformation i App Store",
  "subscribe_billing_issue_header": "Problem med betalning",
  "finished_placeholder_title": "Du har inte avslutat några böcker",
  "saved_placeholder_title": "Du har inga sparade böcker",
  "profile_edit_interface_language": "Språk på användargränssnittet",
  "subscribe_save_discount_web": "SPARA",
  "auth_terms_web": "Genom att skapa ett konto så godkänner du Anyplays <1>användarvillkor</1>. För att läsa mer om hur Anyplay insamlar, användar och skyddar din personliga data, läs Anyplays <3>Integritetspolicy</3>.",
  "cmn_select": "Välj",
  "prices_read_without_limits": "Lyssna utan begränsningar",
  "prices_choose_your_plus": "Välj din Plus",
  "prices_other_plans": "Andra erbjudanden",
  "promo_continue_with_apple": "Fortsätt med Apple",
  "profile_password_reset_message_web": "Vi skickar ett email till {{email}} med en länk för att återställa ditt lösenord. Om du inte har tillgång till ditt epostkonto, kontakta vår kundtjänst.",
  "profile_signin_with_google": "Logga in med Google",
  "auth_sign_in_remember_me": "Kom ihåg mig",
  "profile_settings_version_web": "Version:",
  "auth_sign_in_no_account": "Inget konto?",
  "auth_signup_already_user": "Har du ett konto?",
  "banner_preview_title": "Förhandsvisning av Anyplay",
  "profile_consumption_total_web": "Du har läst {{books}} böcker och lyssnat på {{hours}} timmar sedan du började använda Anyplay",
  "profile_consumption_this_month_web": "Du har startat {{books}} böcker och lyssnat på {{hours}} timmar denna månad",
  "profile_unlink": "Koppla ifrån",
  "profile_account_change_email_and_password": "Ändra lösenord och epostadress",
  "profile_active_sessions": "Aktiva enheter",
  "profile_log_me_out": "Logga ut överallt",
  "profile_binding_connected": "Sociala nätverk kopplade till ditt konto",
  "profile_binding_services_description": "Länka dina sociala nätverk till ditt Anyplaykonto.\nEfteråt kan du logga in enkelt utan lösenord.",
  "paywall_get_free_audiobooks": "Testa ljudböcker gratis i 7 dagar"
}
