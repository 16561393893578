import React from 'react';
import { Grid } from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import SignUpMethodsHeader from "../signUp/SignUpMethodsHeader";
const useStyles = makeStyles((theme)=>({
  wrapper: {
    margin: '0 auto',
    maxWidth: '400px',
    // marginTop: '10px',
    padding: 0,
    position: 'absolute',
    zIndex: 1101,
    top: 0,
    left: 0,
    right: 0,
    background: 'black',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%'
    }
  },
}))

const LimitNavbar:React.FC<{onRollbackCall?():void}> = ({children, onRollbackCall}) => {
  const classes = useStyles()
  return (
    <Grid
      container
      justifyContent={"center"}
      spacing={3}
      alignItems="center"
      className={classes.wrapper}>
      <SignUpMethodsHeader onRollback={onRollbackCall}/>
      {children}
    </Grid>
  )
}
export default LimitNavbar
