import {getLocalStorageData, removeLocalStorageData, setLocalStorageData} from "./_root";
import {IPlayerSettings} from "../../models/player";

const getPlayerSettings = (): IPlayerSettings | null => {
  return getLocalStorageData('anyplayPlayerSettings')
}

const setPlayerSettings = (data:IPlayerSettings): void => {
  setLocalStorageData('anyplayPlayerSettings', data)
};

const removePlayerSettings = (): void => {
  removeLocalStorageData('anyplayPlayerSettings')
};

export const DEFAULT_PLAYER_SETTINGS = {speedRate: 1, volume: 0.3}
export { getPlayerSettings, setPlayerSettings, removePlayerSettings};
