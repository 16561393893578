import {
  SET_IS_BILLING_ISSUE,
  SET_IS_CANCEL, SET_IS_FREE_PLAYBACK,
  SET_IS_LOADING_PREMIUM_DONE,
  SET_IS_PREMIUM, SET_IS_TRIAL_USED, SET_PREMIUM_DETAILS,
  SET_PROFILE,
  SET_RC,
  SET_STRIPE,
  SET_TERRITORY,
  SET_IS_BILLING_ISSUE_GRACE
} from "../types";
import {IProfileState} from "./IProfileState";
import {AuthInfo} from "../../models/auth";
import {IPremium} from "../../models/premium";

const handlers = {
  [SET_PROFILE]: (state:IProfileState, payload:{payload: AuthInfo, type: 'SET_PROFILE'}) => ({...state, profile: payload.payload}),
  [SET_TERRITORY]: (state:IProfileState, payload:{payload: any, type: 'SET_TERRITORY'}) => ({...state, userTerritory: payload.payload}),
  [SET_STRIPE]: (state:IProfileState, payload:{payload: any, type: 'SET_STRIPE'}) => ({...state, stripeData: payload.payload}),
  [SET_RC]: (state:IProfileState, payload:{payload: any, type: 'SET_RC'}) => ({...state, rcData: payload.payload}),
  [SET_PREMIUM_DETAILS]: (state:IProfileState, payload:{payload: IPremium, type: 'SET_PREMIUM_DETAILS'}) => ({...state, premiumDetails: payload.payload}),
  [SET_IS_PREMIUM]: (state:IProfileState, payload:{payload: boolean, type: 'SET_IS_PREMIUM'}) => ({...state, isUserHasPremium: payload.payload}),
  [SET_IS_FREE_PLAYBACK]: (state:IProfileState, payload:{payload: boolean, type: 'SET_IS_FREE_PLAYBACK'}) => ({...state, isFreePlayback: payload.payload}),
  [SET_IS_CANCEL]: (state:IProfileState, payload:{payload: boolean, type: 'SET_IS_CANCEL'}) => ({...state, isCancelled: payload.payload}),
  [SET_IS_TRIAL_USED]: (state:IProfileState, payload:{payload: boolean, type: 'SET_IS_TRIAL_USED'}) => ({...state, isTrialUsed: payload.payload}),
  [SET_IS_LOADING_PREMIUM_DONE]: (state:IProfileState, payload:{payload: boolean, type: 'SET_IS_LOADING_PREMIUM_DONE'}) => ({...state, isLoadingPremiumDone: payload.payload}),
  [SET_IS_BILLING_ISSUE]: (state:IProfileState, payload:{payload: boolean, type: 'SET_IS_BILLING_ISSUE'}) => ({...state, isBillingIssue: payload.payload}),
  [SET_IS_BILLING_ISSUE_GRACE]: (state:IProfileState, payload:{payload: boolean, type: 'SET_IS_BILLING_ISSUE_GRACE'}) => ({...state, isBillingIssueOutGrace: payload.payload}),
  DEFAULT: (state:any) => state
}

export const profileReducer = (state: IProfileState, action: { type: any, payload?: any }) => {
  // @ts-ignore
  const handle = handlers[action.type] || handlers.DEFAULT
  return handle(state, action)
}
