const imgMaxSize = 0.4

const userMinHeight = 300
const userMinWidth = 300

const audiobookMobile = 80
const audiobookDesktop = 214

const carouselAudiobooks = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    slidesToSlide: 3 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
    slidesToSlide: 1 // optional, default to 1.
  },
  largeMobile: {
    breakpoint: { max: 464, min: 390 },
    items: 4,
    slidesToSlide: 1 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 389, min: 321 },
    items: 3.5,
    slidesToSlide: 1 // optional, default to 1.
  },
  smMobile: {
    breakpoint: { max: 321, min: 0 },
    items: 3,
    slidesToSlide: 1 // optional, default to 1.
  }
};
const carouselAudiobooksMin = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 1 // optional, default to 1.
  },
  largeMobile: {
    breakpoint: { max: 464, min: 390 },
    items: 3,
    slidesToSlide: 1 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 389, min: 321 },
    items: 3.5,
    slidesToSlide: 1 // optional, default to 1.
  },
  smMobile: {
    breakpoint: { max: 321, min: 0 },
    items: 3,
    slidesToSlide: 1 // optional, default to 1.
  }
};
const carouselPrice = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1024
    },
    items: 4,
    partialVisibilityGutter: 40
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 3.5,
    slidesToSlide: 1,
    partialVisibilityGutter: 30
  },
  smtablet: {
    breakpoint: { max: 768, min: 464 },
    items: 2.5,
    slidesToSlide: 1,
    partialVisibilityGutter: 30
  },
  largeMobile: {
    breakpoint: { max: 464, min: 390 },
    items: 3.5,
    slidesToSlide: 1,
    partialVisibilityGutter: 30
  },
  mobile: {
    breakpoint: { max: 389, min: 321 },
    items: 3,
    slidesToSlide: 1,
    partialVisibilityGutter: 30
  },
  smMobile: {
    breakpoint: { max: 321, min: 0 },
    items: 2.5,
    slidesToSlide: 1,
    partialVisibilityGutter: 30
  }
};
const carouselPlaylists = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    slidesToSlide: 3 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 1 // optional, default to 1.
  },
  largeMobile: {
    breakpoint: { max: 464, min: 321 },
    items: 2,
    slidesToSlide: 1 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 321, min: 0 },
    items: 2,
    slidesToSlide: 1 // optional, default to 1.
  }
};
const carouselPlaylistGroups = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    slidesToSlide: 3 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 1 // optional, default to 1.
  },
  largeMobile: {
    breakpoint: { max: 464, min: 321 },
    items: 2,
    slidesToSlide: 1 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 321, min: 0 },
    items: 2,
    slidesToSlide: 1 // optional, default to 1.
  }
};

const carouselUser = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    slidesToSlide: 3 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
    slidesToSlide: 1 // optional, default to 1.
  },
  largeMobile: {
    breakpoint: { max: 464, min: 321 },
    items: 3,
    slidesToSlide: 1 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 321, min: 0 },
    items: 2,
    slidesToSlide: 1 // optional, default to 1.
  }
};

export {
  imgMaxSize,
  userMinHeight, userMinWidth,
  audiobookMobile, audiobookDesktop,
  carouselAudiobooks, carouselAudiobooksMin,
  carouselPlaylists, carouselPlaylistGroups, carouselUser, carouselPrice
}
