import {anyplayV2} from "../plugins/axios";
import {
  IProfileStripe, IStripeIntent, IStripeSubs
} from "../models/stripe";

class StripeService {
  public static async getProfileStripe ():Promise<IProfileStripe> {
    const profile: { data: { data: IProfileStripe } } = await anyplayV2
      .get(`profiles/payments`)
    return profile.data.data
  }

  public static async createSubscribe (data: {payment_method_id: string, price_id: string, trial:boolean,coupon?: string}):Promise<IStripeSubs>  {
    const { payment_method_id, price_id, trial, coupon } = data
    const prices: {data: any } = await anyplayV2
      .post(`profiles/payments/create_subscribe`, {
        "payment_method_id": payment_method_id,
        "price": price_id,
        trial,
        coupon
      })
    return prices.data
  }
  public static async subscribe (data: {payment_method_id: string, price_id: string, trial:boolean,coupon?: string}):Promise<IStripeSubs>  {
    const { payment_method_id, price_id, trial, coupon } = data
    const prices: {data: any } = await anyplayV2
      .post(`profiles/payments/subscribe`, {
        "payment_method_id": payment_method_id,
        "price": price_id,
        trial,
        coupon
      })
    return prices.data
  }
  // TBT
  public static async setupIntent ():Promise<IStripeIntent>  {
    const prices: {data: any } = await anyplayV2
      .post(`profiles/payments/create_intent`)
    return prices.data
  }
  public static async getSubscription ():Promise<IStripeSubs | null> {
    const profile: { data: any } = await anyplayV2
      .get(`profiles/payments/subscription`)
    return profile.data
  }
  public static async deleteSubscription():Promise<IStripeSubs> {
    const prices: {data: any } = await anyplayV2
      .delete(`profiles/payments/unsubscribe`)
    return prices.data
  }
}

export default StripeService
