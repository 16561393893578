import {useContext, useEffect} from "react";
import {getBasePrice, getLocalCurrency, orderingPrice} from "../priceFix";
import {PriceContext} from "../../context/price/priceContext";
import PublicPaymentsService from "../../service/public/payments";

export const usePricesData = () => {
  const {products, fullPrice, prices,
    setStripePrices, setStripePriceBase, setStripePriceFull, setStripeProducts, setTrialDays, setIsTrial} = useContext(PriceContext)
  // const {isLoading, loadingHandler} = useIsLoading()

  const getStripeData = async  () => {
    if(products.length === 0 || prices.length === 0) {
      await getStripePrices()
      await getStripeProducts()
    }
  }
  const getStripeProducts = async  () => {
    try {
      let data = await PublicPaymentsService.getProducts()
      setStripeProducts(data)
    } catch (e) {
      // loadingHandler(false)
      console.log('getStripe -> e', e)
    }
  }
  const getStripePrices = async () => {
    try {
      const currency = getLocalCurrency()
      let data = await PublicPaymentsService.getPrice({currency})
      if(data.length === 0) {
        data = await PublicPaymentsService.getPrice({currency:'usd'})
        setStripePrices(data)
      } else {
        setStripePrices(data)
      }
      let priseObj = getBasePrice(data);
      setStripePriceBase(priseObj)

      let newData = orderingPrice(data)
      setStripePrices(newData)
    } catch (e) {
      // loadingHandler(false)
      console.log('getStripe -> e', e)
    }
  }
  const detectTrial = () => {
    if(fullPrice && products.length !== 0) {
      const product = products.find(product => product.id === fullPrice.product)
      if(product) {
        if(typeof product.metadata.trial_days !== 'undefined') {
          setIsTrial(true)
          setTrialDays(product.metadata.trial_days)
        } else {
          setIsTrial(false)
          setTrialDays(null)
        }
      }
    }
  }
  const detectTrialForPrice = () => {
    if(typeof prices[0].trialData === 'undefined') {
      const lol = prices.map(price => {

        const product = products.find(product => product.id === price.product)
        console.log(' -> product', product)
        let priceModified = {...price}
        if(typeof product !== 'undefined') {
          if( typeof product.metadata.trial_days !== 'undefined') {
            priceModified = {...priceModified, trialData: {isTrial: true, trialDays: product.metadata.trial_days}}
          } else {
            priceModified = {...priceModified, trialData: {isTrial: false, trialDays: null}}
          }
          if( typeof product.metadata.default_paywall !== 'undefined') {
            priceModified = {...priceModified, isDefaultForPaywall: true}
          } else {
            priceModified = {...priceModified, isDefaultForPaywall: false}
          }
          priceModified = {...priceModified, productName: product.name}
        }
        // else {
        //   priceModified = {...price, trialData: {isTrial: false, trialDays: null}, isDefaultForPaywall: false}
        // }
        return priceModified
      })
      if(fullPrice === null) {
        const defaultPrice = lol.filter(price=>price.isDefaultForPaywall)[0]
        if(defaultPrice) {
          setStripePriceFull(defaultPrice)
        } else {
          setStripePriceFull(lol[0])
        }

        // setStripePriceFull(data[0])
      }
      // @ts-ignore
      setStripePrices( lol )

    }
    }
  // useEffect( ()=>{
  //   if(!isLoading && prices.length === 0) {
  //     getStripeData()
  //   }
  //   // eslint-disable-next-line
  // },[isLoading])
  useEffect( ()=>{
    detectTrial()
    // eslint-disable-next-line
  },[products, fullPrice])
  useEffect( ()=>{
    if(products.length !== 0 && prices.length !== 0 && (products.length !== prices.length) ) {
      const _prices = prices.filter(({ product }) =>
        products.some(({ id }) => id === product)
      );
      setStripePrices(_prices)

    }
    if(products.length !== 0 && prices.length !== 0 && (products.length === prices.length)) {
      detectTrialForPrice()
    }
    // eslint-disable-next-line
  },[products, prices])
  return {
    getStripePrices,
    getStripeData
  }
}
