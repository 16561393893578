import React from "react";
import {AppBar, Grid, Toolbar} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  appBarWrap: {
    [theme.breakpoints.up('md')]: {
      height: '3rem'
    },
  },
  appBar: {
    width: '100%',
    top: 'auto',
    bottom: 0,
  },
  toolbarHelper: {
    position: 'relative',
    bottom: '45px'
  }
}))

const FooterWrapper:React.FC<{ isStacked?:boolean }>
  = ({children,isStacked}) => {
  const classes = useStyles();

  return (
    <>
      <Toolbar />
      <Grid item xs={12} className={classes.appBarWrap}>
        <AppBar position="fixed" color="primary" className={classes.appBar} component={'footer'}>
          <Toolbar className={isStacked ? classes.toolbarHelper : ''}>
            {children}
          </Toolbar>
        </AppBar>
      </Grid>
    </>
  )
}
export default FooterWrapper
