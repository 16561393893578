export const en = {
  "profile_edit_title": "Account",
  "profile_edit_first_name": "First name",
  "profile_edit_last_name": "Last name",
  "profile_edit_email": "Email",
  "profile_edit_membership_plan": "Membership plan",
  "profile_settings_languages": "Languages",
  "profile_settings_sign_out": "Sign out",
  "cmn_playlists": "Playlists",
  "profile_account_change_email": "Change Email",
  "profile_account_change_password": "Change password",
  "profile_account_change_name": "Change Profile Name & Bio",
  "cmn_anyplay_premium": "Anyplay Plus",
  "profile_edit_save": "Save",
  "profile_password_current": "Current Password",
  "profile_password_new": "New Password",
  "profile_password_confirmation": "Confirm New Password",
  "profile_email_new": "New Email Address",
  "profile_password_send_link": "Send link",
  "cmn_cancel": "Cancel",
  "profile_password_forgot": "Forgot password?",
  "profile_subscription_management": "Manage Subscriptions",
  "promo_sign_in": "Log in",
  "promo_title_2": "Listen as much as you want",
  "auth_sign_up_for_books": "Sign up for the best audiobooks",
  "auth_or": "OR",
  "auth_login_to_your_account": "Log in to your account",
  "auth_sign_up_password": "Choose your password",
  "auth_create_account": "Create Account",
  "add_title_playlist_create": "Create",
  "add_title_playlist_create_new": "Create new playlist",
  "add_title_playlist_enter_title": "Enter a title",
  "manage_playlist_remove": "Remove playlist",
  "manage_playlist_make_public": "Make playlist public",
  "subscribe_offer_description": "Full access to 200.000 audiobooks with new releases every day.",
  "subscribe_try_it_free": "Try it for free",
  "title_detail_narrator": "Narrator",
  "title_detail_language": "Language",
  "title_detail_playtime": "Playtime",
  "title_detail_year": "Year",
  "title_detail_publisher": "Publisher",
  "title_detail_others_have_read": "Others have read",
  "player_jump_to_chapter": "Jump to chapter",
  "player_add_to_playlist": "Add to playlist",
  "subscribe_go_premium": "Do you want to hear more?",
  "subscribe_go_premium_message": "Unlock all books with Anyplay Plus.",
  "player_preview": "Preview",
  "browse_ongoing_tab": "Ongoing books",
  "download_audiobook_delete": "Delete",
  "search_discover_fiction": "Fiction",
  "search_discover_non_fiction": "Non-fiction",
  "search_authors": "Authors",
  "search_playlists": "Playlists",
  "search_title_or_author": "Search title or author",
  "feed_new_for_you": "New for you",
  "cmn_for_you": "Your Library",
  "cmn_browse": "Browse",
  "cmn_search": "Search",
  "audiobooks_status_finished": "Finished books",
  "player_play": "Play",
  "subscribe_start": "Start",
  "profile_add_language_title": "Add a language",
  "subscribe_cancel_anytime": "Cancel anytime",
  "subscribe_v3_full_access": "Unlock 200.000+ books",
  "subscribe_v3_read_as_much": "Read as much as you want",
  "subscribe_v3_stream_or_download": "Stream or download offline",
  "subscribe_v3_new_books_weekly": "New books added weekly",
  "subscribe_v3_low_fee": "Low monthly fee",
  "subscribe_v3_no_ads": "No ads",
  "subscribe_v3_trusted_by": "Trusted by bookworms \naround the world",
  "player_save_for_later": "Save for later",
  "subscribe_v3_subscribe_now": "Subscribe now",
  "search_no_results": "No Results",
  "manage_playlist_make_private": "Make playlist private",
  "cmn_anyplay_free": "Anyplay Free",
  "browse_audiobooks_tab": "Audiobooks",
  "title_detail_unavailable_territory": "We're sorry, this book has recently been withdrawn by the publisher in your territory",
  "promo_continue_with_google": "Continue with Google",
  "promo_sign_up_with_email": "Sign up free with email",
  "promo_continue_with_facebook": "Continue with Facebook",
  "auth_whats_your_email": "What’s your email?",
  "auth_you_need_to_confirm_email": "You’ll need to confirm this email later.",
  "auth_email_already_registered": "This email is already connected to an account",
  "auth_do_you_want_to_login_instead": "Do you want to login instead?",
  "auth_whats_your_name": "What’s your name?",
  "auth_name_appears_on_your_profile": "This appears on your Anyplay profile.",
  "auth_name_placeholder": "John Smith",
  "auth_password": "Password",
  "auth_register": "Register",
  "subscribe_v4_books_all_genres": "200.000+ books in all genres",
  "subscribe_v4_start_free_trial": "Start free trial",
  "action_share_book": "Share book",
  "subscribe_download_go_premium": "Unlock this feature",
  "auth_short_password_error_message": "Password is too short\nMinimum is 6 characters",
  "auth_signup": "Sign up",
  "auth_email_fill_error_message": "Please fill in the email",
  "player_track": "Track",
  "search_more_audiobooks": "See all books",
  "search_more_authors": "See all authors",
  "search_more_playlists": "See all playlists",
  "playlist_share": "Share",
  "player_saved_for_later": "Saved for later",
  "title_detail_rating": "Rating",
  "cmn_edit": "Edit",
  "review_rating_title": "How did you like this book?",
  "review_comment_title": "Describe it with your own words",
  "review_title": "Ratings & Comments",
  "playlist_edit_title": "Edit Playlist",
  "title_more_from_author": "More from this author",
  "title_mark_finished": "Mark book as finished",
  "title_rate_and_comment": "Rate and comment",
  "title_download": "Download",
  "profile_settings_help_center": "Help Center",
  "language_preferred_order": "Preferred language order",
  "profile_settings_terms_of_service": "Terms of Service",
  "profile_login_Information": "Login information",
  "profile_account_change_avatar": "Change profile photo",
  "profile_account_reset_password": "Reset Password",
  "profile_account_reset_password_send": "A reset password link has been sent to ",
  "profile_account_passords_not_match_alert": "Passwords don't match",
  "profile_account_display_name": "Display name",
  "profile_account_biography": "Biography",
  "profile_account_display_name_description": "(if you share playlists this will be your public name)",
  "profile_account_biography_description": "(short text to describe your public user profile)",
  "profile_add_email_login": "Add email login",
  "profile_add_email_login_to_unlink": "To unlink your Account, please first add an email login.\nThis will ensure you don’t lose your Anyplay data.",
  "profile_signin_with_apple": "Sign in with Apple",
  "profile_signin_with_facebook": "Sign in with Facebook",
  "ongoing_placeholder_title": "You have not started any books",
  "ongoing_placeholder_subtitle": "Tap play on any book and it will appear here",
  "paywall_read_more_with_unlimited_access": "Read more with endless access",
  "paywall_start_your_free_trial": "Start your 7-day free trial",
  "period_unit_month": "month",
  "period_unit_year": "year",
  "period_unit_months": "months",
  "subscription_alert_button": "7 days free",
  "subscription_alert_skip_trial": "Skip trial",
  "auth_next": "Next",
  "profile_consumption_total_label": "Total",
  "profile_consumption_this_month_label": "This month",
  "profile_consumption_title": "Listening time",
  "playlists_see_all": "See all",
  "subscribe_billing_issue_android": "Update payment information in Google Play Store",
  "subscribe_billing_issue_ios": "Update payment information in App Store",
  "subscribe_billing_issue_header": "Billing issue detected",
  "finished_placeholder_title": "You have not finished any books",
  "saved_placeholder_title": "You have not saved any books",
  "profile_edit_interface_language": "Set interface language",
  "subscribe_save_discount_web": "Save",
  "auth_terms_web": "By creating an account, you agree to Anyplay’s <1>Terms and Conditions of Use</1>. To learn how Anyplay collects, uses and protects your personal data, read Anyplay’s <3>Privacy Policy</3>.",
  "cmn_anyplay_free_description": "Play all podcasts and limited audiobooks",
  "cmn_select": "Select",
  "prices_read_without_limits": "Read without limits",
  "prices_choose_your_plus": "Choose your Plus",
  "prices_other_plans": "Other plans",
  "download_app_use_our_player": "or use our <1>web player</1>",
  "promo_continue_with_apple": "Continue with Apple",
  "profile_password_reset_message_web": "We will send an email to {{email}} with a link to reset your password. If you can not access this email account, please contact Customer Support.",
  "profile_signin_with_google": "Sign in with Google",
  "auth_sign_in_remember_me": "Remember me",
  "profile_settings_version_web": "Version:",
  "auth_sign_in_no_account": "Don't have an account?",
  "auth_signup_already_user": "Already have an account?",
  "banner_preview_title": "This is a preview of Anyplay",
  "profile_consumption_total_web": "You have read {{books}} books and listened to {{hours}} hours since you started to use Anyplay",
  "profile_consumption_this_month_web": "You have started {{books}} books and listened to {{hours}} hours this month",
  "title_mark_ongoing": "Back to ongoing",
  "subscribe_billing_issue_web": "Update credit card in <1>Stripe</1> (clicking will open the Stripe Customer Dashboard).",
  "subscribe_billing_issue_suspend ": "We're missing your payment. Access has temporarily been blocked. You will be able to use the full service again after a successful payment.",
  "profile_unlink": "Unlink",
  "profile_account_change_email_and_password": "Change password and email",
  "profile_active_sessions": "Active sessions",
  "profile_log_me_out": "Log me out from everywhere",
  "profile_binding_services": "Binding external services",
  "profile_binding_connected": "Socials logins connected to Anyplay",
  "paywall_get_free_audiobooks": "Get free audiobooks for 7 days",
  "profile_binding_services_description": "Link your social media accounts to Anyplay. \nAfter that, you can log into the service in one click."
}
