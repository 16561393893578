import {createContext} from 'react'
export interface IGradientState {
  gradientTo: string,
  setGradientTo: (gradient: string) => void
}
export const initState: IGradientState = {
  gradientTo: '#bfc1c3',
  setGradientTo: ()=>{}
}
// @ts-ignore
export const GradientContext = createContext(initState)
