import React from "react";
import {createStyles, Grid, makeStyles, Typography} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) =>
  createStyles({
    bannerContainer: {
      left: 0,
      bottom: 0,
      maxHeight: '90px',
      position: 'fixed',
      padding: '0px 15px 15px 20px',
      zIndex: 100,
      background: 'linear-gradient(90deg, rgba(153,2,140,1) 19%, rgba(7,18,224,1) 97%)'
    },
    bannerText: {
      [theme.breakpoints.down('sm')]: {
        fontSize: 11,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 10,
      },
      fontWeight: 'bold'
    },
    bannerTextUpper: {
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 11,
      },
      textTransform: 'uppercase'
    },
    bannerButtonText: {
      background: '#e1dfdf',
      padding: '10px',
      borderRadius: '20px',
      textAlign: 'center',
      color: 'black !important',
      fontWeight: 600,
      textTransform: 'uppercase',
      '&:hover': {
        background: '#c3bfbf'
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 11,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 10,
      },
    },
    flexCenter: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 0 12px 0px !important'
    }
  })
)

const BottomBanner:React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container spacing={3} className={classes.bannerContainer}>
      <Grid item xs={8} sm={8} lg={10}>
        <Typography variant={'h6'} className={[classes.bannerText, classes.bannerTextUpper].join(' ')}>
          {t('banner_preview_title')}
        </Typography>
        <Typography className={classes.bannerText}>
          {t('paywall_read_more_with_unlimited_access')}{' & '}{t('subscribe_v3_read_as_much').toLowerCase()}. {t('subscribe_v3_no_ads')}.
        </Typography>
      </Grid>
      <Grid item xs={4} sm={4} lg={2}
            className={classes.flexCenter}>
        <NavLink to={'/sign_up'} className={['noTd', 'w100'].join(' ')}>
          <Typography className={classes.bannerButtonText}>
            {t('auth_register')}
          </Typography>
        </NavLink>
      </Grid>
    </Grid>
  )
}
export default BottomBanner
