import getDurationString from "../getDurationString";
import AudiobookService from "../../service/audiobook";
import React, {useContext} from "react";
import {PlayerContext} from "../../context/player/playerContext";
import {IAudiobookPostConsumption} from "../../models/audiobook";

const useConsumptions = () =>{
  const {content} = useContext(PlayerContext)

  const [licenseConsumptions, setLicenseConsumptions] = React.useState<IAudiobookPostConsumption[]>([])

  // memo: currentTime in seconds, but activeChapter.timestamp in milliseconds
  const trackPlayback = async (data:{currentTime: number,activeChapter: any}) => {
    const { currentTime,activeChapter } = data
    if(activeChapter.timestamp !== null) {
      let calcTimestamp = parseInt(activeChapter.timestamp) +currentTime*1000
      let durationStr = getDurationString({duration:calcTimestamp,isMinutesOnly:true})
      let _licenseConsumptions = [...licenseConsumptions]
      if(!_licenseConsumptions.some(e => e.index ===parseInt(durationStr))) {
        _licenseConsumptions.push({index:parseInt(durationStr),source:activeChapter.play_source === 'anyplay' ? 6 : 5 })
        setLicenseConsumptions(_licenseConsumptions)
      }
    }
  }
  // memo: currentTime in seconds, but activeChapter.timestamp in milliseconds
  const updatePlayback = async (data:{currentTime: number,activeChapter: any}) => {
    const { currentTime, activeChapter } = data
    if(!Number.isNaN(parseInt(activeChapter.timestamp))) {
      let calcTimestamp = parseInt(activeChapter.timestamp) + currentTime * 1000
      let durationStr = getDurationString({duration: calcTimestamp, isMinutesOnly: true})
      let _licenseConsumptions = [...licenseConsumptions]
      if (!_licenseConsumptions.some(e => e.index === parseInt(durationStr))) {
        _licenseConsumptions.push({
          index: parseInt(durationStr),
          source: activeChapter.play_source === 'anyplay' ? 6 : 5
        })
      }
      await AudiobookService.updatePlaybackLicenses({audiobookId:content.contentId, binary_array: _licenseConsumptions})
      setLicenseConsumptions([])
    }
  }
  return {
    trackPlayback,
    updatePlayback
  }
}
export default useConsumptions
