import React, {useContext} from "react";
import PreviewEndWithLogin from "../../ads/PreviewEndWithLogin";
import {PlayerContext} from "../../../context/player/playerContext";

const usePreviewEnd = () => {
  const { setIsInterrupted } = useContext(PlayerContext)

  const [isPreviewEndOpen, setIsPreviewEndOpen] = React.useState<boolean>(false)

  const handleNonPremiumUser = (data:{currentTime:number, activeChapter: any}) => {
    const {currentTime,activeChapter} = data
    if(parseInt(activeChapter.timestamp)/1000 +currentTime > 600) {
      setIsInterrupted(true)
      setIsPreviewEndOpen(true)
    }
  }
  const RenderPreviewEndWithLogin = () => (
    <PreviewEndWithLogin
      isOpen={isPreviewEndOpen}
      onCloseModal={()=>setIsPreviewEndOpen(false)}/>
  )

  return {
    handleNonPremiumUser,
    RenderPreviewEndWithLogin
  }
}
export default usePreviewEnd
