import {IAudiobookBookmark, IAudiobookBookmarkPost} from "../models/audiobook";
import {anyplayV3} from "../plugins/axios";

class AudiobookBookmarkService {
  public static async getBookmarks(data:{audiobookId: string}):Promise<IAudiobookBookmark[]>{
    const { audiobookId } = data
    const bookmarkData = await anyplayV3.get(`profiles/audiobooks/${audiobookId}/bookmarks`)
    return bookmarkData.data.data
  }
  public static async getBookmarkLast(data:{audiobookId: string}):Promise<IAudiobookBookmark>{
    const { audiobookId } = data
    const bookmarkData = await anyplayV3.get(`profiles/audiobooks/${audiobookId}/bookmarks/last_bookmark`)
    return bookmarkData.data.data
  }
  public static async postBookmark(data:{ audiobookId: string,bookmark:IAudiobookBookmarkPost }):Promise<IAudiobookBookmark>{
    const { audiobookId, bookmark } = data
    const bookmarkData = await anyplayV3.post(`profiles/audiobooks/${audiobookId}/bookmarks`, {
      "bookmarks":bookmark
    })
    return bookmarkData.data.data
  }
}

export default AudiobookBookmarkService
