import React, {useReducer} from "react";
import {initState, PriceContext} from "./priceContext";
import {priceReducer} from "./priceReducer";
import {SET_PRICES, SET_PRICE_BASE, SET_PRICE_FULL, SET_PRODUCTS, SET_IS_TRIAL, SET_TRIAL_DAYS} from "../types";
import {IStripePriceDetails, IStripeProductDetails} from "../../models/stripe";

export const PriceState:React.FC = ({children}) => {
  const [state,dispatch]= useReducer(priceReducer, initState);
  const setStripePrices = (payload: IStripePriceDetails[]) => {
    dispatch({type:SET_PRICES, payload})
  }
  const setStripePriceBase = (payload: number) => {
    dispatch({type:SET_PRICE_BASE, payload})
  }
  const setStripePriceFull = (payload: IStripePriceDetails) => {
    dispatch({type:SET_PRICE_FULL, payload})
  }
  const setStripeProducts = (payload: IStripeProductDetails[]) => {
    dispatch({type:SET_PRODUCTS, payload})
  }
  const setIsTrial = (payload: boolean) => {
    dispatch({type:SET_IS_TRIAL, payload})
  }
  const setTrialDays = (payload: string | null) => {
    dispatch({type:SET_TRIAL_DAYS, payload})
  }
  return (
    <PriceContext.Provider value={
      {
        prices: state.prices,
        fullPrice: state.fullPrice,
        basePrice: state.basePrice,
        products: state.products,
        isTrial: state.isTrial,
        trialDays: state.trialDays,
        setStripePrices,
        setStripePriceBase,
        setStripePriceFull,
        setStripeProducts,
        setIsTrial,
        setTrialDays
      }
    }>
      {children}
    </PriceContext.Provider>
  )
}
