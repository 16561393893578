import {useContext, useEffect} from "react";
import {GradientContext} from "../../context/gradient/gradientContext";
import routes from "../../routes";
import {useHistory} from "react-router-dom";

const usePageGradient = () => {
  const {gradientTo, setGradientTo} = useContext(GradientContext)

  const {location} = useHistory()

  const gradientNavbarString = `linear-gradient(rgb(0, 0, 0) 15%, ${gradientTo} 100%)`
  const gradientBodyString = `linear-gradient(0deg, rgba(0,0,0,1) 0%, ${gradientTo} 100%)`

  useEffect(() => {
    let route = routes.find(route => route.path.replace(':id', location.pathname.split('/')[2]) === location.pathname)
    let gradient = typeof route === 'undefined' || !route.isUseGradient ? 'black' : undefined
    if(typeof gradient !== 'undefined') {
      setGradientTo(gradient)
    }

    // eslint-disable-next-line
  },[location])

  return {
    gradientNavbarString,
    gradientBodyString
  }
}
export default usePageGradient
