import {IPlayerState} from "./IPlayerState";
import {
  SET_PROVIDER_ID,
  SET_BOOKMARKS,
  SET_CHAPTERS,
  SET_CONTENT,
  SET_IS_AUTOPLAY,
  SET_IS_BOOKMARK, SET_IS_BOOKMARK_OPEN,
  SET_IS_NOTHING_TO_PLAY,
  SET_IS_PAUSED,
  SET_IS_PREVIEW, SET_LICENSE, SET_PLAYER_SETTINGS, SET_IS_INTERRUPTED, SET_IS_MANUAL_PLAY
} from "../types";
import {IAudiobookLicense} from "../../models/audiobook";
import {IPlayerSettings} from "../../models/player";

const handlers = {
  [SET_CHAPTERS]: (state:IPlayerState, payload:{payload: any, type: 'SET_CHAPTERS'}) => ({...state, chapters: payload.payload}),
  [SET_BOOKMARKS]: (state:IPlayerState, payload:{payload: any, type: 'SET_BOOKMARKS'}) => ({...state, bookmarksGlobal: payload.payload}),
  [SET_CONTENT]: (state:IPlayerState, payload:{payload: any, type: 'SET_CONTENT'}) => ({...state, content: payload.payload}),
  [SET_PROVIDER_ID]: (state:IPlayerState, payload:{payload: string, type: 'SET_PROVIDER_ID'}) => ({...state, providerId: payload.payload}),
  [SET_IS_BOOKMARK]: (state:IPlayerState, payload:{payload: boolean, type: 'SET_IS_BOOKMARK'}) => ({...state, isBookmark: payload.payload}),
  [SET_IS_PAUSED]: (state:IPlayerState, payload:{payload: boolean, type: 'SET_IS_PAUSED'}) => ({...state, isPaused: payload.payload}),
  [SET_IS_AUTOPLAY]: (state:IPlayerState, payload:{payload: boolean, type: 'SET_IS_PAUSED'}) => ({...state, isAutoPlay: payload.payload}),
  [SET_IS_PREVIEW]: (state:IPlayerState, payload:{payload: boolean, type: 'SET_IS_PREVIEW'}) => ({...state, isPreview: payload.payload}),
  [SET_IS_NOTHING_TO_PLAY]: (state:IPlayerState, payload:{payload: boolean, type: 'SET_IS_NOTHING_TO_PLAY'}) => ({...state, isNothingToPlay: payload.payload}),
  [SET_IS_BOOKMARK_OPEN]: (state:IPlayerState, payload:{payload: boolean, type: 'SET_IS_BOOKMARK_OPEN'}) => ({...state, isBookMarkOpen: payload.payload}),
  [SET_IS_INTERRUPTED]: (state:IPlayerState, payload:{payload: boolean, type: 'SET_IS_INTERRUPTED'}) => ({...state, isInterrupted: payload.payload}),
  [SET_IS_MANUAL_PLAY]: (state:IPlayerState, payload:{payload: boolean, type: 'SET_IS_MANUAL_PLAY'}) => ({...state, isManualPlay: payload.payload}),
  [SET_LICENSE]: (state:IPlayerState, payload:{payload: IAudiobookLicense | null, type: 'SET_LICENSE'}) => ({...state, license: payload.payload}),
  [SET_PLAYER_SETTINGS]: (state:IPlayerState, payload:{payload: IPlayerSettings, type: 'SET_PLAYER_SETTINGS'}) => ({...state, playerSettingsLocal: payload.payload}),
  DEFAULT: (state:any) => state
}

export const playerReducer = (state: IPlayerState, action: { type: any, payload?: any }) => {
  // @ts-ignore
  const handle = handlers[action?.type] || handlers.DEFAULT
  return handle(state, action)
}
