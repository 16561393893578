import React, {useContext, useEffect} from 'react'

import {AlertContext} from '../context/alert/alertContext'
import {Snackbar, Slide, SlideProps} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

type TransitionProps = Omit<SlideProps, 'direction'>;

export const AlertSnack = () => {
  const [transition, setTransition] = React.useState<React.ComponentType<TransitionProps> | undefined>(undefined);

  function TransitionUp(props: TransitionProps) {
    return <Slide {...props} direction="up" />;
  }

  const handleClick = (Transition: React.ComponentType<TransitionProps>) => () => {
    setTransition(() => Transition);
  };
  useEffect(()=>{
    handleClick(TransitionUp);
  })
  const {alert, hide} = useContext(AlertContext)
  return (
    <Snackbar
      open={alert.visible}
      onClose={hide}

      TransitionComponent={transition}
      key={transition ? transition.name : ''}
      // action={
      //   <IconButton size="small" aria-label="close" color="inherit" onClick={hide}>
      //     <CloseIcon fontSize="small" />
      //   </IconButton>
      // }
    >
      <MuiAlert severity={alert.type}  onClose={hide}>{alert.text}</MuiAlert>
    </Snackbar>
  )
}
