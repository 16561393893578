import {
  Container, Divider, FormControl,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import React, {useContext, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import CheckIcon from "@material-ui/icons/Check";
import {useTranslation} from "react-i18next";
import {Elements} from "@stripe/react-stripe-js";
import PlansPay from "../account/PlansPay";
import {loadStripe} from "@stripe/stripe-js";
import {IStripePriceDetails} from "../../models/stripe";
import ru_plural from "../../plugins/plural";
import {makeStyles} from "@material-ui/core/styles";
import {currencyFormatter} from "../../utils/currencyFormatter";
import {PriceContext} from "../../context/price/priceContext";

const ads = [
  { title: '200000 books' },
  { title: 'Stream or download offline' },
  { title: 'subscribe_cancel_anytime' },
]
const useStyles = makeStyles(()=>({
  payBg: {
    background: '#232323',
    // width: '450px'
  },
  accordionDetails: {
    display: 'block',
    background: '#232323',
    borderRadius:' 5px'
  },
  adItem: {
    padding:'0px 0px 0px 10px',
    '&:hover': {
      background: '#232323'
    }
  },
  adItemExtra: {
    background: 'gray',
    borderRadius: '10px',
    '&:hover': {
      background: 'gray'
    }
  },
  customPlus: {
    position: 'absolute',
    bottom: '7px',
    marginLeft: '5px',
    fontSize: '0.7rem',
    display: 'inline-flex'
  },
  priceList: {
    textAlign: 'center',
    padding: '0 !important'
  },
  priceListItem: {
    border: '2px solid #6694fc',
    borderRadius: '20px',
    margin: '5px 0'
  },
  priceListItemText: {
    color: '#6694fc !important'
  },
  priceListItemActive: {
    border: '2px solid #134fd5',
  },
  priceListItemTextActive: {
    color: ' #134fd5 !important'
  }
}))
const PayV2:React.FC<{callCancelPay(isClosed: boolean):void,
  isDisplayPrices?: boolean, giftCode?: string}>
  = ({callCancelPay,
       isDisplayPrices = false, giftCode}) => {
  const { handleSubmit  } = useForm()
  const {fullPrice, prices, setStripePriceFull, isTrial} = useContext(PriceContext)

  const { t } = useTranslation();
  const classes = useStyles();
  const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PK}`);
  const [priceInfo, setPriceInfo] = useState<string>('')

  const handleChange = (price:IStripePriceDetails) => {
    // setValue(price);
    setStripePriceFull(price)
  };

  const submitForm = async () => {
    console.log('submitForm -> submitForm')
  }
  useEffect(() => {
    if(fullPrice) {

      let interval = fullPrice.recurring.interval_count !== 1 ?
        fullPrice.recurring.interval_count+' '
        : ''
      let phrase =
        currencyFormatter({price: fullPrice.unit_amount / 100,currency:fullPrice.currency})
        + '/' + interval +
        t(ru_plural(fullPrice.recurring.interval, fullPrice.recurring.interval_count))
      setPriceInfo(phrase)
      // setValue(fullPrice)
    }
    // eslint-disable-next-line
  },[fullPrice])

  return (
    <Container
      maxWidth={false}
      component="form"
      className={classes.payBg}
      style={{maxWidth:'450px'}}
      onSubmit={handleSubmit(submitForm)}>
      <Grid
        container
        justifyContent={'center'}
        spacing={3}
        alignItems="center"
      >
        <Grid item xs={12} style={{marginTop: '30px', padding: 0}}>
          <Typography
            variant={"h5"}
            style={{fontWeight: 600, position: 'relative'}}
            align={'center'}>
            {t('Unlock')+' Anyplay'}
              <Typography className={['plus', classes.customPlus].join(' ')}>
                {t('Plus')}
              </Typography>
          </Typography>
        </Grid>
        <Grid item xs={12} style={{padding: 0}}>
          <Typography
            variant={"h6"}
            align={'center'}>
            {t('Get access to unlimited reading')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <List>
            {
              !isDisplayPrices ?
                <ListItem disableGutters className={[classes.adItem, classes.adItemExtra].join(' ')}>
                  <ListItemIcon>
                    <CheckIcon style={{color:'#12C77E'}}/>
                  </ListItemIcon>
                  <ListItemText>
                    {t('30 Days free trial')}
                  </ListItemText>
                </ListItem>
                :
                isTrial && isDisplayPrices ?
                  <ListItem disableGutters className={[classes.adItem, classes.adItemExtra].join(' ')}>
                    <ListItemIcon>
                      <CheckIcon style={{color:'#12C77E'}}/>
                    </ListItemIcon>
                    <ListItemText>
                      {t('subscription_alert_button')}
                    </ListItemText>
                  </ListItem>
                  : null
            }
            {ads.map((ad,index) => (
              <ListItem key={index} disableGutters className={classes.adItem}>
                <ListItemIcon>
                  <CheckIcon style={{color:'#12C77E'}}/>
                </ListItemIcon>
                <ListItemText>
                  {t(ad.title)}
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={11} className={classes.priceList}>
        <FormControl component="fieldset" fullWidth>
          <RadioGroup aria-label="priceList" name="gender1" value={fullPrice}>
            {
              isDisplayPrices ?
              prices.map((price, index)=>(
                <FormControlLabel
                  key={index}
                  className={[classes.priceListItem, fullPrice===price ? classes.priceListItemActive: ''].join(' ')}
                  value={price}
                  onClick={()=>handleChange(price)}
                  control={<Radio />}
                  label={
                    <>
                      {price.trialData?.isTrial ?
                        <>
                      <Typography
                        align={'left'}
                        className={[classes.priceListItemText,
                          fullPrice===price ? classes.priceListItemTextActive: ''].join(' ')}>
                        {t('subscription_alert_button')}
                      </Typography>
                      <Typography
                        align={'left'}
                        className={[classes.priceListItemText,
                          fullPrice===price ? classes.priceListItemTextActive: ''].join(' ')}>
                        {t('then').charAt(0).toUpperCase()
                        + t('then').slice(1) + ' ' +

                          currencyFormatter({price: price.unit_amount / 100,currency:price.currency})
                          + '/' +    (price.recurring.interval_count !== 1 ?
                          price.recurring.interval_count+' '
                          : '') +
                          t(ru_plural(price.recurring.interval, price.recurring.interval_count))

                        }
                      </Typography>
                          </>
                        :
                        <Typography
                          align={'left'}
                          className={[classes.priceListItemText,
                            fullPrice===price ? classes.priceListItemTextActive: ''].join(' ')}>
                          {
                            currencyFormatter({price: price.unit_amount / 100,currency:price.currency})
                            + '/' +    (price.recurring.interval_count !== 1 ?
                              price.recurring.interval_count+' '
                              : '' )+
                              t(ru_plural(price.recurring.interval, price.recurring.interval_count))
                          }
                        </Typography>
                      }
                    </>
                 } />
              ))
                :
                <Typography>
                  You don't pay anything until the free period has ended
                </Typography>
            }
          </RadioGroup>
        </FormControl>
        </Grid>
          <Divider light/>
          <Grid item>
            <Elements stripe={stripePromise}>
              {
                fullPrice ?
                  <>
                    <Typography>
                      {t('Credit or debit card')}
                    </Typography>
                <PlansPay
                  giftCode={giftCode}
                  priceId={fullPrice.id}
                  onCancelPay={() => callCancelPay(false)}/>
                  </>
                : null
              }

            </Elements>
          </Grid>
        {fullPrice ?

        <Grid item xs={12}>
          <Typography
            variant={'subtitle2'}
            align={'center'}>
          {
            !isDisplayPrices ? (t('30 Days free trial')+', '+t('then')+' '+ priceInfo):
            isTrial && isDisplayPrices ? (t('7 Days free trial')+', '+t('then')+' '+ priceInfo) : priceInfo}. {t('Cancel anytime')}.

          </Typography>
        </Grid>
          : null
        }
      </Grid>
    </Container>
  )
}
export default PayV2
