const audiobookTitleFixer = (title:string) => {
  const articleExceptions = [', A', ', The']
  let exception = articleExceptions.find(article=> title.substring(title.length - article.length) === article)
  if(exception) {
    let changedTitle = title.slice(0, title.length - exception.length)
    return exception.split(', ')[1] + ' ' + changedTitle.slice(0, 1).toLowerCase() + changedTitle.slice(1,title.length)
  }
  return title
}
export default audiobookTitleFixer
