import React from "react";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import ShowConditionally from "./ShowConditionally";

const AnyplayNotificationIcon:React.FC<{isShow:boolean | undefined}> = ({isShow}) => {
 return (
   <ShowConditionally isShow={typeof isShow !== 'undefined' && isShow}>
     <FiberManualRecordIcon style={{ fontSize: 10 }} color={'error'}/>
   </ShowConditionally>
 )
}
export default AnyplayNotificationIcon
