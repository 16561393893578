import React from "react"
import {Grid, Typography, List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core"

const AnyplayPlayerAudioInfoRow:React.FC<{header:string, text: string}> = ({header,text}) => {
  return (
    <Grid container item xs={12}>
      <List style={{width:'100%'}}>
        <ListItem disableGutters={true} divider style={{padding:'0'}}>
          <ListItemIcon>
            <Typography>
              {header}
            </Typography>
          </ListItemIcon>
          <ListItemText primary={text} style={{textAlign:'end'}}/>
        </ListItem>
      </List>
      {/*<Grid item xs={4}>*/}

      {/*</Grid>*/}
      {/*<Grid item xs={8}>*/}
      {/*  <Typography align={'right'}>*/}
      {/*  {}*/}
      {/*  </Typography>*/}
      {/*</Grid>*/}
    </Grid>
  )
}
export default AnyplayPlayerAudioInfoRow
